import React, { Component } from "react";
import { Form, Input, Button, Select, Divider, Modal, message } from "antd";
import errorMessage from "utils/errorMessage";
import axios from "axios";
import debounce from "lodash/debounce";

const Option = Select.Option;
const FormItem = Form.Item;

import ActiveFacilityToggle from "components/common/ActiveFacilityToggle";
import ActiveSelect from "components/common/ActiveSelect";
import FormHeading from "components/common/FormHeading";

class EditUserForm extends Component {
  state = {
    user: window.EditUserData.user,
    userSites:
      // eslint-disable-next-line no-prototype-builtins
      window.EditUserData.hasOwnProperty("userSites") &&
      window.EditUserData.userSites.length !== 0
        ? window.EditUserData.userSites
        : [],
    allSites: window.EditUserData.allSites,
    roleSlug: window.EditUserData.roleSlug,
    roleDisplayName: window.EditUserData.roleDisplayName,
    activeStatus: window.EditUserData.user.is_active,
    registrationToken: window.EditUserData.registrationToken,
    resetPasswordToken: null,
    visible: false,
    newFacility: "",
    buttonIsLoading: false,
  };

  updateState = (data, callback) => this.setState(data, callback);

  handleSubmit = (values) => {
    values.facilities = this.state.userSites;
    values.is_active = Boolean(parseInt(values.is_active));
    axios.put("/cms/users/" + this.state.user.id, values).then(
      () => {
        localStorage.setItem(
          "success-notification",
          this.state.user.first_name +
            " " +
            this.state.user.last_name +
            " was edited successfully"
        );
        this.backNavigation();
      },
      (error) => errorMessage(error)
    );
  };

  toggleActiveStatusSelected = (activeStatus) =>
    this.updateState({ activeStatus: Number(activeStatus) });

  backNavigation = () => {
    if (this.state.roleSlug === "site_admin" && document.referrer) {
      window.location = document.referrer;
    } else {
      window.location = "/cms/users";
    }
  };

  showModal = () => this.updateState({ visible: true });

  handleOk = () => {
    const newSite = this.state.allSites.find(
      (x) => x.id === this.state.newFacility
    );

    this.updateState({
      visible: false,
      userSites: [...this.state.userSites, newSite],
      newFacility: "",
    });
  };

  handleCancel = () => this.updateState({ visible: false });

  handleChange = (newFacility) => this.updateState({ newFacility });

  toggleFacility = (id, toggleState) => {
    const userSites = [...this.state.userSites];

    userSites.map((site) => {
      if (site.id === id) {
        site.is_active = Boolean(Number(toggleState));
      }
      return site;
    });

    this.updateState({ userSites });
  };

  copyRegistrationLink = () => {
    const registrationLink = `${window.location.origin}/cms/register/${this.state.registrationToken}`;
    this.copyLink(
      registrationLink,
      "The registration link has been copied to the clipboard"
    );
  };

  copyResetPasswordLink = () => {
    const resetPasswordLink = `${window.location.origin}/password/reset/${this.state.resetPasswordToken}`;
    this.copyLink(
      resetPasswordLink,
      "The reset password link has been copied to the clipboard"
    );
  };

  copyLink = (link, alertText) => {
    navigator.clipboard.writeText(link);
    message.info(alertText);
  };

  handleResendRegistration = () => {
    this.updateState({ buttonIsLoading: true }, this.resendRegistrationEmail);
  };

  handleResetPassword = () => {
    this.updateState({ buttonIsLoading: true }, this.getResetPasswordToken);
  };

  resendRegistrationEmail = debounce(() => {
    axios.post("/cms/resend-invite", { email: this.state.user.email }).then(
      () =>
        this.updateState(
          { buttonIsLoading: false },
          message.info("The registration email has been send successfully.")
        ),
      (error) =>
        this.updateState({ buttonIsLoading: false }, errorMessage(error))
    );
  }, 200);

  getResetPasswordToken = debounce(() => {
    axios
      .post("/cms/password/reset-password-token", {
        email: this.state.user.email,
      })
      .then(
        (response) =>
          this.updateState(
            { buttonIsLoading: false, resetPasswordToken: response.data.token },
            this.copyResetPasswordLink
          ),
        (error) =>
          this.updateState({ buttonIsLoading: false }, errorMessage(error))
      );
  }, 200);

  render = () => {
    const siteIds = this.state.userSites.map((site) => site.id);

    const filteredSites = this.state.allSites
      .filter((site) => {
        return !siteIds.includes(site.id);
      })
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

    return (
      <Form
        onFinish={this.handleSubmit}
        className={"ahi-form"}
        hideRequiredMark={true}
        initialValues={{
          first_name: this.state.user.first_name,
          last_name: this.state.user.last_name,
          email: this.state.user.email,
          phone_number: this.state.user.phone_number,
          is_active: this.state.user.is_active ? "1" : "0",
        }}
      >
        <FormHeading headingText={`Edit ${this.state.roleDisplayName}`} />
        <Divider />
        <div className={"row"}>
          <div className={"col-xs-6"}>
            <FormItem
              label={"First Name"}
              colon={false}
              name="first_name"
              rules={[{ required: true, message: "Please input a first name" }]}
            >
              <Input placeholder="First Name" />
            </FormItem>
          </div>
          <div className="col-xs-6">
            <FormItem
              label={"Last Name"}
              colon={false}
              name="last_name"
              rules={[{ required: true, message: "Please input a last name" }]}
            >
              <Input placeholder="Last Name" />
            </FormItem>
          </div>
        </div>

        <FormItem
          label={"Email Address"}
          colon={false}
          name="email"
          rules={[{ required: true, message: "Please input an email address" }]}
        >
          <Input type="email" placeholder="Email Address" />
        </FormItem>

        <FormItem
          label={"Phone Number"}
          colon={false}
          name="phone_number"
          rules={[{ required: false }]}
        >
          <Input type="phone" placeholder="Phone Number" />
        </FormItem>

        <div className="row">
          <div className="col-xs-6">
            <FormItem
              label={"Status"}
              colon={false}
              name="is_active"
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <ActiveSelect
                onChange={this.toggleActiveStatusSelected}
                isActive={Boolean(this.state.activeStatus)}
              />
            </FormItem>
          </div>
        </div>

        {this.state.user.is_site_admin && (
          <React.Fragment>
            <Divider />
            {this.state.userSites.map((site) => (
              <ActiveFacilityToggle
                site={site}
                key={site.id}
                toggleFacility={this.toggleFacility}
              />
            ))}
            <br />
            <div className="row">
              <div
                className={"col-xs-6"}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Button type="primary" onClick={this.showModal}>
                  + Add Facility
                </Button>
              </div>
            </div>

            <Modal
              title="Select Facility"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <p>Choose the facility you would like to add.</p>

              <Select
                showSearch
                style={{ width: 200 }}
                placeholder={"Select a facility"}
                optionFilterProp={"children"}
                onChange={this.handleChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.newFacility}
              >
                {filteredSites.map((site) => (
                  <Option value={site.id} key={site.id}>
                    {site.name}
                  </Option>
                ))}
              </Select>
            </Modal>
          </React.Fragment>
        )}
        {(this.state.user.is_site_admin || this.state.registrationToken) && (
          <Divider />
        )}

        {this.state.registrationToken && (
          <div className="row">
            <div
              className={"col-xs-12"}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <FormItem>
                <Button
                  className={"form-button"}
                  onClick={this.copyRegistrationLink}
                  style={{ marginRight: "20px" }}
                >
                  Copy Registration Link
                </Button>
              </FormItem>
              <FormItem>
                <Button
                  className={"form-button"}
                  onClick={this.handleResendRegistration}
                >
                  Resend Registration Email
                </Button>
              </FormItem>
            </div>
          </div>
        )}

        {this.state.user.is_site_admin && (
          <React.Fragment>
            <div className="row">
              <div
                className="col-xs-12"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <FormItem style={{ marginBottom: 0 }}>
                  <Button
                    className={"form-button"}
                    onClick={this.handleResetPassword}
                    style={{ marginRight: "5px" }}
                  >
                    Copy Password Reset Link
                  </Button>
                </FormItem>
              </div>
            </div>
          </React.Fragment>
        )}

        <Divider />
        <div className="row">
          <div
            className={"col-xs-12"}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormItem>
              <Button
                className={"form-button"}
                onClick={this.backNavigation}
                style={{ marginRight: "5px" }}
                loading={this.state.buttonIsLoading}
              >
                Cancel
              </Button>
              <Button
                type={"primary"}
                htmlType={"submit"}
                loading={this.state.buttonIsLoading}
              >
                Save
              </Button>
            </FormItem>
          </div>
        </div>
      </Form>
    );
  };
}

export default EditUserForm;
