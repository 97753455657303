import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const Option = Select.Option;

export default function ModuleTypeSelect(props) {
  return (
    <Select
      mode="multiple"
      placeholder="Please select"
      defaultValue={props.moduleTypeFilters}
      onChange={props.onChange}
      style={{ display: "inline-block" }}
      maxTagCount={1}
    >
      {props.moduleTypes.map((mt) => {
        return (
          <Option key={mt.id} value={mt.id}>
            {mt.display_name}
          </Option>
        );
      })}
    </Select>
  );
}

ModuleTypeSelect.propTypes = {
  moduleTypeFilters: PropTypes.array,
  onChange: PropTypes.func,
  moduleTypes: PropTypes.array,
};
