import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Switch } from "antd";
const FormItem = Form.Item;

const PasswordChange = (props) => (
  <React.Fragment>
    <FormItem label="Change Password" name="switch" valuePropName="checked">
      <Switch onChange={props.toggleChangePassword} />
    </FormItem>

    <div
      className={
        "row " + (props.changePassword ? "display-passwords" : "hide-passwords")
      }
    >
      <div className="col-xs-6">
        <FormItem
          label={"New Password"}
          colon={false}
          name="password"
          rules={[
            {
              required: false,
              message: "Please input your password",
            },
            {
              validator: props.validateToNextPassword,
            },
          ]}
        >
          <Input
            type="password"
            placeholder={"Password"}
            autoComplete={"change-password"}
          />
        </FormItem>
      </div>
      <div className="col-xs-6">
        <FormItem
          label={"Confirm New Password"}
          colon={false}
          name="password_confirmation"
          rules={[
            {
              required: false,
              message: "Please confirm your password",
            },
            {
              validator: props.compareToFirstPassword,
            },
          ]}
        >
          <Input
            type="password"
            placeholder={"Password"}
            autoComplete={"confirm-change-password"}
          />
        </FormItem>
      </div>
    </div>
  </React.Fragment>
);

PasswordChange.propTypes = {
  changePassword: PropTypes.bool.isRequired,
  toggleChangePassword: PropTypes.func.isRequired,
  validateToNextPassword: PropTypes.func.isRequired,
  compareToFirstPassword: PropTypes.func.isRequired,
};

export default PasswordChange;
