import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useSessionList } from "contexts/SessionListContext";
import { useSite } from "contexts/SiteDetailsContext";
import { SiteService } from "services/SiteService";
import errorMessage from "utils/errorMessage";
import useStateHandler from "hooks/useStateHandler";
import StateHandler from "components/StateHandler";
import SessionCarouselCard from "components/SessionCarouselCard";

const DEFAULT_PAGE_SIZE = 1;

const SessionCarousel = (): ReactElement => {
  const [session, setSession] = useState<SessionListElement>();
  const [paginationData, setPaginationData] = useState<PaginationMetadata>();
  const { currentSession, siteUserID, dateFilter, setCurrentSession } =
    useSessionList();
  const [pageNumber, setPageNumber] = useState(
    currentSession ? currentSession.sessionPageNumber : 1
  );
  const { setLoading, ...stateHandlerProps } = useStateHandler();

  const {
    site: { id: siteId },
    siteAdminId,
  } = useSite();

  useEffect(() => {
    SiteService.getSessionList({
      page: pageNumber,
      pageSize: DEFAULT_PAGE_SIZE,
      siteId: siteId,
      siteUser: siteUserID,
      dateStart: dateFilter?.dateStart,
      dateEnd: dateFilter?.dateEnd,
      siteAdminId,
    }).then(
      (response) => {
        setSession(response.data.data[0]);
        setPaginationData(response.data.metadata);
        setCurrentSession({
          sessionID: response.data.data[0]?.id,
          sessionPageNumber: pageNumber,
        });
        setLoading(false);
      },
      (error) => {
        errorMessage(error);
      }
    );
  }, [
    pageNumber,
    dateFilter,
    setCurrentSession,
    siteAdminId,
    siteId,
    siteUserID,
    setLoading,
  ]);

  const onClickLeft = (): void => {
    setPageNumber(pageNumber === 1 ? paginationData.last_page : pageNumber - 1);
  };

  const onClickRight = (): void => {
    setPageNumber(pageNumber === paginationData.last_page ? 1 : pageNumber + 1);
  };

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "24px",
      }}
    >
      <Button
        onClick={onClickLeft}
        icon={<CaretLeftOutlined />}
        className="mapped-route-modal-carousel-button"
        type="text"
      />
      <StateHandler {...stateHandlerProps}>
        <SessionCarouselCard session={session} />
      </StateHandler>

      <Button
        onClick={onClickRight}
        icon={<CaretRightOutlined />}
        className="mapped-route-modal-carousel-button"
        type="text"
      />
    </div>
  );
};
export default SessionCarousel;
