import React, { Dispatch, SetStateAction } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { SortOptions } from "hooks/useTableSort";

const TitleWithSortButton = ({
  title,
  sortColumn,
  sortType,
  setSortOptions,
}: {
  title: string;
  sortColumn: string;
  sortType: string;
  setSortOptions: Dispatch<SetStateAction<SortOptions>>;
}): React.ReactElement => (
  <span className={`order-sorter ${sortType || ""}`}>
    {title}
    <div
      className="icon-container"
      onClick={() => {
        const lowerCaseTitle = title.toLowerCase();
        if (sortColumn !== lowerCaseTitle) {
          setSortOptions({
            sortColumn: lowerCaseTitle,
            sortType: "asc",
          });
        } else {
          setSortOptions({
            sortColumn: sortColumn,
            sortType: sortType === "asc" ? "desc" : "asc",
          });
        }
      }}
      data-testid="filter"
    >
      <CaretUpOutlined /> <CaretDownOutlined />
    </div>
  </span>
);

export default TitleWithSortButton;
