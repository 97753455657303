import SortButton from "components/TitleWithSortButton/TitleWithSortButton";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

type SortButton = { key: string; component: React.ReactElement };
type SortButtonArray = SortButton[];
export type SortOptions = {
  sortColumn: string;
  sortType: string;
};

type TableSortingState = {
  sortColumn: string;
  sortType: string;
  setSortOptions: Dispatch<SetStateAction<SortOptions>>;
  sortButtons: SortButtonArray;
};

export const useTableSort = ({
  defaultColumn,
  defaultSortType = "asc",
  columnHeaders,
}: {
  defaultColumn: string;
  defaultSortType?: string;
  columnHeaders: Array<string>;
}): TableSortingState => {
  const [sortOptions, setSortOptions] = useState({
    sortColumn: defaultColumn,
    sortType: defaultSortType,
  });

  const sortButtons = useMemo<SortButtonArray>(() => {
    return columnHeaders.map((headerValue: string) => {
      return {
        key: headerValue,
        component: (
          <SortButton
            key={headerValue}
            title={headerValue.toUpperCase()}
            sortColumn={sortOptions.sortColumn}
            sortType={
              sortOptions.sortColumn === headerValue
                ? sortOptions.sortType
                : undefined
            }
            setSortOptions={setSortOptions}
          />
        ),
      };
    });
  }, [columnHeaders, sortOptions]);

  return {
    sortColumn: sortOptions.sortColumn,
    sortType: sortOptions.sortType,
    setSortOptions: setSortOptions,
    sortButtons: sortButtons,
  };
};
