import ReactDOM from "react-dom";
import React from "react";
/**
 * Import React Components
 */
/*global routeName, currentUserName, territoryList, userRole, site, availableTerritories */
/*global userTerritories, moduleTypes, siteModules, user, token, modules, siteUserPhone */
/*global territories, siteUser, currenUserRole, currentRouteName */

import LoginForm from "screens/auth/LoginForm";
import ForgotPassword from "screens/auth/ForgotPassword";
import PasswordReset from "screens/auth/PasswordReset";
import UsersList from "screens/users/UsersList";
import EditUserForm from "screens/users/EditUserForm";
import AddUserForm from "screens/users/AddUserForm";
import AddTKAdminForm from "screens/users/AddTKAdminForm";
import EditProfile from "screens/users/EditProfile";
import ManageSiteUser from "screens/users/ManageSiteUser";

// Navigation Components
import SearchBar from "components/navigation/SearchBar";
import SiteNavigationLink from "components/navigation/SiteNavigationLink";
import ProfileDropdown from "components/navigation/ProfileDropdown";

// TK Admin Site Components
import SitesList from "screens/sites/SitesList";
import SiteDetails from "screens/sites/SiteDetails";
import AddSiteForm from "screens/sites/AddSiteForm";
import ManageFacility from "screens/sites/ManageFacility/ManageFacility";

// Site Admin Components
import SiteManagerDashboard from "screens/admin/SiteManagerDashboard";

// Registration Components
import RegistrationForm from "screens/auth/RegistrationForm";

// Global Components
import NotificationHandler from "components/global/NotificationHandler";
import SiteSelect from "screens/users/siteSelect";
import FeatureFlagProvider from "contexts/FeatureFlagContext";
import SiteDetailsProvider from "contexts/SiteDetailsContext";

/**
 * Specify where React should render
 */

if (document.getElementById("login-form")) {
  ReactDOM.render(<LoginForm />, document.getElementById("login-form"));
}

if (document.getElementById("forgot-password")) {
  ReactDOM.render(
    <ForgotPassword />,
    document.getElementById("forgot-password")
  );
}

if (document.getElementById("password-reset")) {
  ReactDOM.render(<PasswordReset />, document.getElementById("password-reset"));
}

if (document.getElementById("search-bar")) {
  ReactDOM.render(
    <SearchBar routeName={routeName} />,
    document.getElementById("search-bar")
  );
}

if (document.getElementById("site-admin-link")) {
  ReactDOM.render(
    <SiteNavigationLink
      site={typeof site !== "undefined" ? site : null}
      siteCount={window.siteCount ?? 0}
      currentRouteName={currentRouteName}
      currentUserRole={currenUserRole}
      dashboardUserName={
        typeof window.siteAdminUsername !== "undefined"
          ? window.siteAdminUsername
          : null
      }
    />,
    document.getElementById("site-admin-link")
  );
}

if (document.getElementById("profile-dropdown")) {
  ReactDOM.render(
    <ProfileDropdown userName={currentUserName} />,
    document.getElementById("profile-dropdown")
  );
}

if (document.getElementById("users-list")) {
  ReactDOM.render(<UsersList />, document.getElementById("users-list"));
}

if (document.getElementById("edit-user-form")) {
  ReactDOM.render(<EditUserForm />, document.getElementById("edit-user-form"));
}

if (document.getElementById("choose-facility")) {
  ReactDOM.render(<SiteSelect />, document.getElementById("choose-facility"));
}

if (document.getElementById("add-user-form")) {
  ReactDOM.render(
    <AddUserForm
      territoryList={territoryList}
      userRole={userRole}
      site={site}
    />,
    document.getElementById("add-user-form")
  );
}

if (document.getElementById("add-tk-admin-form")) {
  ReactDOM.render(
    <AddTKAdminForm
      territoryList={territoryList}
      userRole={userRole}
      site={site}
    />,
    document.getElementById("add-tk-admin-form")
  );
}

if (document.getElementById("sites-list")) {
  ReactDOM.render(
    <SitesList
      availableTerritories={availableTerritories}
      userTerritories={userTerritories}
      moduleTypes={moduleTypes}
    />,
    document.getElementById("sites-list")
  );
}

if (document.getElementById("add-site-form")) {
  ReactDOM.render(
    <AddSiteForm availableTerritories={availableTerritories} />,
    document.getElementById("add-site-form")
  );
}

if (document.getElementById("site-details")) {
  ReactDOM.render(
    <SiteDetails site={site} siteModules={siteModules} />,
    document.getElementById("site-details")
  );
}

if (document.getElementById("manage-site")) {
  ReactDOM.render(
    <FeatureFlagProvider>
      <SiteDetailsProvider
        initialValue={{ site, siteAdminId: window.siteAdminId }}
      >
        <ManageFacility availableTerritories={availableTerritories} />
      </SiteDetailsProvider>
    </FeatureFlagProvider>,
    document.getElementById("manage-site")
  );
}

if (document.getElementById("site-admin-dashboard")) {
  ReactDOM.render(
    <FeatureFlagProvider>
      <SiteDetailsProvider
        site={site}
        initialValue={{ site, siteAdminId: window.siteAdminId }}
      >
        <SiteManagerDashboard
          siteModules={siteModules}
          siteAdminId={window.siteAdminId}
        />
      </SiteDetailsProvider>
    </FeatureFlagProvider>,
    document.getElementById("site-admin-dashboard")
  );
}

if (document.getElementById("registration-form")) {
  ReactDOM.render(
    <RegistrationForm user={user} token={token} territories={territories} />,
    document.getElementById("registration-form")
  );
}

if (document.getElementById("edit-profile")) {
  ReactDOM.render(<EditProfile />, document.getElementById("edit-profile"));
}

if (document.getElementById("notification-handler")) {
  ReactDOM.render(
    <NotificationHandler />,
    document.getElementById("notification-handler")
  );
}

if (document.getElementById("manage-site-user")) {
  ReactDOM.render(
    <ManageSiteUser
      siteUser={siteUser}
      userPhone={siteUserPhone}
      modules={modules}
    />,
    document.getElementById("manage-site-user")
  );
}
