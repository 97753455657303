import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { DateFilterOption } from "types/common/filterOptions";

type CurrentSession = {
  sessionPageNumber: number;
  sessionID: string;
};

type SessionListContextValue = {
  currentSession?: CurrentSession;
  siteUserID?: number;
  dateFilter?: DateFilterOption;
  setCurrentSession?: Dispatch<SetStateAction<CurrentSession>>;
  setSiteUserID?: Dispatch<SetStateAction<number>>;
  setDateFilter?: Dispatch<SetStateAction<DateFilterOption>>;
};

const SessionListContext = createContext<SessionListContextValue>({});

const initialValue: SessionListContextValue = {};

const SessionListProvider = ({
  children,
  initialValues = initialValue,
}: {
  children: ReactNode;
  initialValues?: SessionListContextValue;
}): ReactElement => {
  const [currentSession, setCurrentSession] = useState(
    initialValues?.currentSession
  );
  const [siteUserID, setSiteUserID] = useState(initialValues?.siteUserID);
  const [dateFilter, setDateFilter] = useState<DateFilterOption>(
    initialValues?.dateFilter
  );

  const value = useMemo<SessionListContextValue>(
    () => ({
      currentSession,
      siteUserID,
      dateFilter,
      setCurrentSession,
      setSiteUserID,
      setDateFilter,
    }),
    [currentSession, siteUserID, dateFilter]
  );

  return (
    <SessionListContext.Provider value={value}>
      {children}
    </SessionListContext.Provider>
  );
};

export default SessionListProvider;

export const useSessionList = (): SessionListContextValue => {
  const sessionListContext = useContext(SessionListContext);
  return sessionListContext;
};
