import React, { Component } from "react";
import turkeyLogo from "img/logo_turnkey.svg";
import { UserOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import axios from "axios";
import errorMessage from "utils/errorMessage";
const FormItem = Form.Item;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    const oldFormData = window.OldFormData;

    this.state = {
      oldFormData: oldFormData,
    };
  }

  handleSubmit = (values) => {
    axios.post("/cms/password/email", values).then(
      () => {
        localStorage.setItem(
          "success-notification",
          "A reset password link was emailed to " + values.email
        );
        window.location = "/login";
      },
      (error) => errorMessage(error)
    );
  };

  render() {
    return (
      <div className={"turnkey-authentication"}>
        <div className={"row center-xs"}>
          <div className={"col-xs-6"}>
            <a href={"/login"}>
              <img
                src={turkeyLogo}
                alt={"Turnkey Logo"}
                className="turnkey-logo"
              />
            </a>
          </div>
        </div>
        <Form
          onFinish={this.handleSubmit}
          method={"POST"}
          action={"/cms/password/email"}
          className="login-form"
          initialValues={{ email: this.state.oldFormData.old_email ?? "" }}
        >
          <FormItem>
            <FormItem
              name="email"
              rules={[{ required: true, message: "Please input your email" }]}
            >
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
              />
            </FormItem>

            {this.state.oldFormData.email_errors ? (
              <p className="warning">{this.state.oldFormData.email_errors}</p>
            ) : (
              ""
            )}
          </FormItem>

          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button full-width-button"
          >
            Send Password Reset Link
          </Button>
        </Form>
      </div>
    );
  }
}

export default ForgotPassword;
