import React, { CSSProperties, ReactNode } from "react";
import { Spin, SpinProps } from "antd";

const StateHandler = ({
  loading,
  error,
  children,
  spinnerProps,
  errorProps,
}: {
  loading: boolean;
  error: string;
  spinnerProps?: SpinProps;
  errorProps?: CSSProperties;
  children: ReactNode;
}): JSX.Element => {
  if (loading) return <LoadingState spinnerProps={spinnerProps} />;
  if (error) return <ErrorState error={error} errorProps={errorProps} />;
  return <>{children}</>;
};

const LoadingState = ({ spinnerProps }: { spinnerProps: SpinProps }) => {
  return (
    <div className="state-handler-container" data-testid="loading-state">
      <Spin {...spinnerProps} />
    </div>
  );
};

const ErrorState = ({
  error,
  errorProps,
}: {
  error: string;
  errorProps: CSSProperties;
}) => {
  return (
    <div className="state-handler-container" data-testid="error-state">
      <span style={{ ...errorProps }}>{error}</span>
    </div>
  );
};

export default StateHandler;
