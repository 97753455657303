import "./bootstrap";
import "flexboxgrid";
import "sass/app.scss";

async function prepare() {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_USE_MOCK_SERVER === "true"
  ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require("mocks/browser");
    return worker.start();
  }

  return Promise.resolve();
}

prepare().then(() => {
  require("./component-imports");
});
