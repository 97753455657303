import React, { Component } from "react";
import turnkeyLogo from "img/logo_turnkey.svg";
import { UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, Space } from "antd";
import axios from "axios";
import errorMessage from "utils/errorMessage";

const FormItem = Form.Item;

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    const resetData = window.ResetData;

    this.state = {
      emailErrors: resetData.email_errors,
      email: resetData.email,
      resetToken: resetData.token,
    };
  }

  formRef = React.createRef();

  handleSubmit = (values) => {
    axios
      .post("/password/reset", { ...values, token: this.state.resetToken })
      .then(
        () => {
          localStorage.setItem(
            "success-notification",
            "Your password was successfully reset"
          );
          window.location = "/home";
        },
        (error) => errorMessage(error)
      );
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you entered are inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && this.state.confirmDirty) {
      form.validateFields(["password_confirmation"]);
    }
    callback();
  };

  render() {
    return (
      <div className={"turnkey-authentication"}>
        <div className={"row center-md"}>
          <div className={"col-md-6"}>
            <a href={"/login"}>
              <img
                src={turnkeyLogo}
                alt={"Turnkey Logo"}
                className="turnkey-logo"
              />
            </a>
          </div>
        </div>

        <Form
          ref={this.formRef}
          name="password_reset"
          onFinish={this.handleSubmit}
          initialValues={{ email: this.state.email }}
        >
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <FormItem
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
                defaultValue={this.state.email}
                disabled={true}
              />

              {this.state.emailErrors && (
                <p className="warning">{this.state.emailErrors}</p>
              )}
            </FormItem>

            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
                {
                  validator: this.validateToNextPassword,
                },
              ]}
            >
              <Input type="password" placeholder={"Password"} />
            </FormItem>

            <FormItem
              name="password_confirmation"
              rules={[
                {
                  required: true,
                  message: "Please input your password confirmation",
                },
                {
                  validator: this.compareToFirstPassword,
                  message: "Please confirm your password",
                },
              ]}
            >
              <Input
                type="password"
                onBlur={this.handleConfirmBlur}
                placeholder={"Confirm Password"}
              />
            </FormItem>

            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button full-width-button"
            >
              Reset Password
            </Button>
          </Space>
        </Form>
      </div>
    );
  }
}

export default PasswordReset;
