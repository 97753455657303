import React from "react";

export default function FormHeading(props) {
  return (
    <div style={{ display: "flex" }}>
      <span
        style={{
          fontWeight: "900",
          fontSize: "16px",
          lineHeight: "16px",
          color: "#2A3F59",
        }}
      >
        {props.headingText}
      </span>
    </div>
  );
}
