import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const Option = Select.Option;

export default function UserRoleSelect(props) {
  return (
    <Select value={props.currentRoleSlug} onChange={props.onChange}>
      <Option key={"all_users"}>All Users</Option>
      {props.roles &&
        props.roles.map((role) => (
          <Option key={role.slug}>{role.display_name}</Option>
        ))}
    </Select>
  );
}

UserRoleSelect.propTypes = {
  currentRoleSlug: PropTypes.string,
  onChange: PropTypes.func,
  roles: PropTypes.array,
};
