import React, {
  Dispatch,
  SetStateAction,
  createContext,
  ReactElement,
  ReactNode,
  useState,
  useMemo,
  useContext,
} from "react";

import { FEATURE_FLAGS } from "utils/appFlags";

export type FeatureFlag = keyof typeof FEATURE_FLAGS | string;
type Flags = Record<FeatureFlag, boolean>;

type FeatureFlagContextValue = {
  flags: Flags;
  setFlags: Dispatch<SetStateAction<Flags>>;
  hasFlag(flag: FeatureFlag): boolean;
};

const FeatureFlagContext = createContext<FeatureFlagContextValue>({
  flags: {},
  setFlags: () => {
    return;
  },
  hasFlag: () => false,
});

let envFlags: Flags = {};

// Load default flags from env
try {
  envFlags = Object.fromEntries(
    process.env.REACT_APP_FEATURE_FLAGS?.split(",")?.map((flag) => {
      const [flagName, value]: (string | undefined)[] = flag.split(":");
      return [
        flagName ?? "",
        value?.toLocaleLowerCase() === "true" || value === "1",
      ];
    }) ?? []
  );
} catch (e) {
  console.log("Env not defined, feature flags unset.", e.message);
}

const FeatureFlagProvider = ({
  children,
  initialFlags = envFlags,
}: {
  children: ReactNode;
  initialFlags: Flags;
}): ReactElement => {
  const [flags, setFlags] = useState<Flags>(initialFlags);

  const value = useMemo<FeatureFlagContextValue>(
    () => ({
      flags,
      setFlags,
      hasFlag: (flag: FeatureFlag) => flags[flag],
    }),
    [flags]
  );

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;

export const useFeatureFlags = (): FeatureFlagContextValue => {
  const flagContext = useContext(FeatureFlagContext);
  if (!flagContext) {
    throw Error("Feature flag provider not found");
  }
  return flagContext;
};

export const FeatureCheck = ({
  featureFlag,
  fallback = null,
  children,
}: {
  featureFlag: FeatureFlag;
  fallback?: ReactNode | null;
  children: ReactNode | null;
}): ReactNode | null => {
  const { hasFlag } = useFeatureFlags();
  return hasFlag(featureFlag) ? <>{children}</> : fallback;
};
