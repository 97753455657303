import React from "react";
import ActiveSwitch from "./ActiveSwitch";

export default function ModuleFeatureSwitch(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: !props.lastItem ? "26px" : "0",
      }}
    >
      <div>{`Allow ${props.feature.display_name}`}</div>
      <div style={{ textAlign: "right", paddingRight: "15px" }}>
        <ActiveSwitch
          onChange={props.updateModuleFeature(props.feature)}
          isActive={Boolean(props.feature.is_active)}
        />
      </div>
    </div>
  );
}
