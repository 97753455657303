import React from "react";
import { Select } from "antd";

const Option = Select.Option;

const ActiveSelect = ({
  onChange,
  isActive,
  bordered,
  activeText,
}: {
  onChange: () => void;
  isActive: boolean;
  bordered: boolean;
  activeText: string;
}): React.ReactElement => (
  <Select
    onChange={onChange}
    className={isActive ? "active" : "inactive"}
    value={isActive ? "1" : "0"}
    style={{ width: "70%" }}
    bordered={bordered ?? true}
  >
    <Option value={"1"}>{activeText || "Active"}</Option>
    <Option value={"0"}>Deactivated</Option>
  </Select>
);

export default ActiveSelect;
