import React, {
  useState,
  useMemo,
  useEffect,
  ReactElement,
  useCallback,
} from "react";
import { Table } from "antd";
import errorMessage from "utils/errorMessage";
import { FilterOutlined } from "@ant-design/icons";
import DateSelectorModal from "components/DateSelectorModal/DateSelectorModal";
import MappedRouteModal from "components/MappedRouteModal/MappedRouteModal";
import { PaginationProps } from "antd/lib/pagination/Pagination";
import { CalendarOutlined } from "@ant-design/icons";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { ColumnsType } from "antd/lib/table/interface";
import { useSite } from "contexts/SiteDetailsContext";
import { AxiosError } from "axios";
import SessionListProvider, {
  useSessionList,
} from "contexts/SessionListContext";
import { Moment } from "moment";
import SessionDetailsProvider from "contexts/SessionDetailsContext";
import { SiteService } from "services/SiteService";
import moment from "moment";
import SitePensProvider, { useSitePens } from "contexts/SitePensContext";

const DEFAULT_PAGE_SIZE = 12;

const SessionList = (): ReactElement => (
  <SessionListProvider>
    <SitePensProvider>
      <SessionTabContent />
    </SitePensProvider>
  </SessionListProvider>
);

const disabledDates = (dates: moment.Moment): boolean => {
  const startDate = moment().subtract(3, "weeks");
  const endDate = moment().endOf("day");
  // Can only select dates between current day and 3 weeks ago
  return dates && (dates.isAfter(endDate) || dates.isBefore(startDate));
};

const SessionTabContent = (): ReactElement => {
  const [sessions, setSessions] = useState<SessionListElement[]>();
  const [sessionListMetadata, setSessionListMetadata] =
    useState<PaginationMetadata>();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dateSelectorVisible, setDateSelectorVisible] = useState(false);
  const [userFilterOptions, setUserFilterOptions] = useState<FilterOption[]>(
    []
  );
  const {
    site: { id: siteId },
    siteAdminId,
  } = useSite();

  const {
    siteUserID,
    dateFilter,
    currentSession,
    setCurrentSession,
    setSiteUserID,
    setDateFilter,
  } = useSessionList();

  const { setPens } = useSitePens();

  // used to calculate page number to be used when making API call for specific session in Carousel component
  const calculatePageNumber = useCallback(
    (index: number): number => sessionListMetadata?.from + index,
    [sessionListMetadata]
  );

  const handleClickViewMappedRoute = useCallback(
    (index: number, record: SessionListElement) => () => {
      setCurrentSession({
        sessionPageNumber: calculatePageNumber(index) ?? 1,
        sessionID: record.id,
      });
    },
    [calculatePageNumber, setCurrentSession]
  );

  const handleSubmitDates = (
    dateStartModal: Moment,
    dateEndModal: Moment
  ): void => {
    setDateFilter({
      dateStart: dateStartModal,
      dateEnd: dateEndModal,
    });
  };

  const showDateModal = (): void => {
    setDateSelectorVisible(true);
  };

  const handleError = (error: AxiosError) => {
    errorMessage(error);
    setLoading(false);
  };

  useEffect(() => {
    SiteService.getSessionList({
      page: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      siteId: siteId,
      siteUser: siteUserID,
      dateStart: dateFilter?.dateStart,
      dateEnd: dateFilter?.dateEnd,
      siteAdminId: siteAdminId,
    }).then((response) => {
      setSessions(response.data.data);
      setSessionListMetadata(response.data.metadata);
      setCurrentPage(response.data.metadata.current_page);
      setLoading(false);
    }, handleError);
  }, [currentPage, siteId, siteUserID, dateFilter, siteAdminId]);

  useEffect(() => {
    SiteService.getSiteUserFilterOptions(siteId, siteAdminId).then(
      (response) => {
        setUserFilterOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.site_user_name,
          }))
        );
      },
      handleError
    );
  }, [siteId, siteAdminId]);

  useEffect(() => {
    SiteService.getPenDetails(siteId, siteAdminId).then((response) => {
      setPens(response.data.data);
    }, handleError);
  }, [siteId, siteAdminId, setPens]);

  const columns: ColumnsType<SessionListElement> = useMemo(
    () => [
      {
        title: "User",
        dataIndex: "site_user_name",
        key: "user",
        filterIcon: (
          <FilterOutlined
            style={{
              color: siteUserID ? "#00C9B0" : "#aaa",
            }}
          />
        ),
        filterDropdown: ({ confirm }) => (
          <FilterDropdown
            onSetSelectedFilter={(_selectedFilter) => {
              setSiteUserID(_selectedFilter ? Number(_selectedFilter) : null);
              confirm();
            }}
            selectedFilter={siteUserID}
            options={userFilterOptions}
          />
        ),
      },
      {
        title: (
          <div>
            Date
            <div onClick={showDateModal} className="icon-calendar">
              <CalendarOutlined
                style={{
                  color:
                    dateFilter?.dateStart !== undefined &&
                    dateFilter?.dateEnd !== undefined
                      ? "#00C9B0"
                      : "inherit",
                }}
              />
            </div>
          </div>
        ),
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Total Time",
        dataIndex: "total_time",
        key: "time",
      },
      {
        title: "Route",
        dataIndex: "session",
        key: "session",
        render: (value: boolean, record: SessionListElement, index: number) => (
          <span
            onClick={handleClickViewMappedRoute(index, record)}
            style={{
              color: "#00C9B0",
              cursor: "pointer",
            }}
          >
            View Mapped Route
          </span>
        ),
      },
    ],
    [
      dateFilter?.dateEnd,
      dateFilter?.dateStart,
      handleClickViewMappedRoute,
      setSiteUserID,
      siteUserID,
      userFilterOptions,
    ]
  );

  const pagination: PaginationProps = {
    onChange: setCurrentPage,
    current: currentPage,
    total: sessionListMetadata?.total,
    pageSize: DEFAULT_PAGE_SIZE,
    hideOnSinglePage: true,
    showSizeChanger: false,
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={sessions}
        loading={loading}
        className={"custom-table"}
        rowKey={(record) => record.id}
        pagination={pagination}
        data-testid={"session-list-table"}
      />
      {currentSession && (
        <SessionDetailsProvider>
          <MappedRouteModal
            modalVisible={!!currentSession}
            closeModal={() => setCurrentSession(undefined)}
          />
        </SessionDetailsProvider>
      )}
      {dateSelectorVisible && (
        <DateSelectorModal
          submit={handleSubmitDates}
          modalVisible={dateSelectorVisible}
          showModal={setDateSelectorVisible}
          dateStart={dateFilter?.dateStart}
          dateEnd={dateFilter?.dateEnd}
          disabledDates={disabledDates}
        />
      )}
    </>
  );
};

export default SessionList;
