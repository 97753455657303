import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from "react";

export type SiteDetailsValue = {
  site: Site;
  siteAdminId?: number;
};

const SiteDetailsContext = createContext<SiteDetailsValue>({
  site: undefined,
  siteAdminId: undefined,
});

const SiteDetailsProvider = ({
  children,
  initialValue,
}: {
  children: ReactNode;
  initialValue: SiteDetailsValue;
}): ReactElement => {
  return (
    <SiteDetailsContext.Provider value={initialValue}>
      {children}
    </SiteDetailsContext.Provider>
  );
};

export default SiteDetailsProvider;

class SiteUndefinedException extends Error {
  constructor() {
    super("Site undefined");
  }
}

export const useSite = (): SiteDetailsValue => {
  const { site, siteAdminId } = useContext(SiteDetailsContext);
  if (!site) {
    throw new SiteUndefinedException();
  }
  return { site, siteAdminId };
};
