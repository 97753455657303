import React, { ReactElement, useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { AxiosError } from "axios";
import findIndex from "lodash/findIndex";

import ActiveSelect from "../ActiveSelect";
import ModuleFeatureSwitch from "../ModuleFeatureSwitch";
import useStateHandler from "hooks/useStateHandler";
import { SiteService } from "services/SiteService";
import errorMessage from "utils/errorMessage";
import StateHandler from "components/StateHandler";
const FormItem = Form.Item;

const ModuleSettingsModal = ({
  site,
  moduleId,
  modalTitle,
  isOpen,
  moduleActivation,
  onConfirm,
  onCancel,
}: {
  site: { id: number };
  moduleId: number;
  modalTitle: string;
  isOpen: boolean;
  moduleActivation: boolean;
  onConfirm: (module: SiteModule) => void;
  onCancel: () => void;
}): ReactElement => {
  const { setLoading, ...stateHandlerProps } = useStateHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [module, setModule] = useState<SiteModule>();

  const handleError = (error: AxiosError) => {
    errorMessage(error);
    setIsSubmitting(false);
  };
  const updateModuleFeature = (feature: Feature) => () => {
    const index = findIndex(module.features, (f) => f.slug === feature.slug);
    const newFeatures: Array<Feature> = [
      ...module.features.slice(0, index),
      { ...feature, is_active: !feature.is_active },
      ...module.features.slice(index + 1),
    ];
    setModule({
      ...module,
      features: newFeatures,
    });
  };

  const updateModuleActive = (isActive: boolean) => () =>
    setModule({
      ...module,
      is_active: isActive,
    });

  const updateModule = () => {
    setIsSubmitting(true);
    SiteService.updateSiteModule({
      siteId: site.id,
      module,
    }).then(
      (response) => {
        setIsSubmitting(false);
        onConfirm(response.data.data);
      },
      (error) => errorMessage(error)
    );
  };

  useEffect(() => {
    SiteService.getSiteModule({
      siteId: site.id,
      moduleId,
    }).then((response) => {
      setModule(response.data.data);
      setLoading(false);
    }, handleError);
  }, [moduleId, setLoading, site.id]);

  return (
    <Modal
      data-testid="module-settings-modal"
      title={
        <span style={{ fontWeight: 900, fontSize: "16px", lineHeight: "16px" }}>
          {modalTitle}
        </span>
      }
      open={isOpen}
      onOk={updateModule}
      onCancel={onCancel}
      width={348}
      wrapClassName={"module-settings-modal"}
      confirmLoading={isSubmitting}
    >
      <StateHandler {...stateHandlerProps}>
        <Form
          onFinish={updateModule}
          className="ahi-form"
          requiredMark={false}
          style={{ border: "none", padding: 0, margin: 0 }}
        >
          {moduleActivation && module && (
            <div style={{ width: "70%", marginBottom: 20 }}>
              <FormItem key={module.id} label={"STATUS"} colon={false}>
                <ActiveSelect
                  onChange={updateModuleActive(!module.is_active)}
                  isActive={Boolean(module.is_active)}
                  activeText={"Activated"}
                  bordered={true}
                />
              </FormItem>
            </div>
          )}

          {module?.features && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {module.features.map((feature, index) => (
                <ModuleFeatureSwitch
                  key={feature.id}
                  feature={{ ...feature }}
                  lastItem={module.features.length === index + 1}
                  updateModuleFeature={updateModuleFeature}
                />
              ))}
              <div style={{ marginTop: 28, color: "#FE3824" }}>
                <div>Attention!</div>
                <span>
                  These toggles will affect all users&apos; current settings at
                  this facility. Users can be set individually after.
                </span>
              </div>
            </div>
          )}
        </Form>
      </StateHandler>
    </Modal>
  );
};

export default ModuleSettingsModal;
