import React, { Component } from "react";

import { Form, Input, Select, Divider, Button } from "antd";
import errorMessage from "utils/errorMessage";
import axios from "axios";
import debounce from "lodash/debounce";

import FormHeading from "components/common/FormHeading";

const FormItem = Form.Item;
const Option = Select.Option;

class AddTKAdminForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonIsLoading: false,
    };
  }

  formRef = React.createRef();

  debouncedFormSubmission = debounce((values) => {
    const site = this.props.site;

    axios
      .post("/cms/users/create", {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        territories: values.territories,
        role: this.props.userRole,
        site: site ? site.id : null,
      })
      .then(
        () => {
          localStorage.setItem(
            "success-notification",
            values.first_name +
              " " +
              values.last_name +
              " was created successfully"
          );
          this.handleUserRouting();
        },
        (error) => {
          errorMessage(error);
        }
      )
      .finally(() => this.setState({ buttonIsLoading: false }));
  }, 200);

  handleSubmit = () => {
    this.setState({ buttonIsLoading: true });

    const form = this.formRef.current;

    form
      .validateFields()
      .then((values) => this.debouncedFormSubmission(values))
      .finally(() => this.setState({ buttonIsLoading: false }));
  };

  handleUserRouting = () => {
    const { userRole, site } = this.props;
    if (userRole === "turn_key_admin") {
      window.location = `/cms/users`;
    } else {
      if (site) {
        window.location = `/cms/facilities/${site.id}`;
      } else {
        window.location = "/cms/facilities";
      }
    }
  };

  render = () => {
    return (
      <Form ref={this.formRef} className="ahi-form" hideRequiredMark={true}>
        <FormHeading headingText={"Add New Turnkey Admin"} />
        <Divider />

        <div className="row">
          <div className="col-xs-6">
            <FormItem
              label={"First Name"}
              colon={false}
              name="first_name"
              rules={[{ required: true, message: "Please input a first name" }]}
            >
              <Input type="text" placeholder="First Name" />
            </FormItem>
          </div>

          <div className="col-xs-6">
            <FormItem
              label={"Last Name"}
              colon={false}
              name="last_name"
              rules={[{ required: true, message: "Please input a last name" }]}
            >
              <Input type="text" placeholder="Last Name" />
            </FormItem>
          </div>
        </div>

        <FormItem
          label={"Email Address"}
          colon={false}
          name="email"
          rules={[{ required: true, message: "Please input an email address" }]}
        >
          <Input type="email" placeholder="Email Address" />
        </FormItem>

        {this.props.userRole === "turn_key_admin" &&
        this.props.territoryList.length > 0 ? (
          <FormItem
            label={"Facility Territories"}
            colon={false}
            name="territories"
            rules={[{ required: false, message: "Territories" }]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add Territories"
            >
              {this.props.territoryList.map((territory) => (
                <Option key={territory.id}>{territory.name}</Option>
              ))}
            </Select>
          </FormItem>
        ) : (
          ""
        )}

        <p className="email-invitation-text">
          An email invitation will be sent upon saving
        </p>

        <Divider />

        <FormItem className={"ahi-form-bottom-button-wrap"}>
          <Button className="cancel-button" onClick={this.handleUserRouting}>
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            type="primary"
            htmlType="submit"
            loading={this.state.buttonIsLoading}
          >
            Save
          </Button>
        </FormItem>
      </Form>
    );
  };
}

export default AddTKAdminForm;
