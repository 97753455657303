import React, { CSSProperties, ReactElement } from "react";

const LabeledStat = ({
  value,
  label,
  containerStyle = {},
  valueStyle = {},
  labelStyle = {},
}: {
  value?: string | JSX.Element;
  label: string;
  containerStyle?: CSSProperties;
  valueStyle?: CSSProperties;
  labelStyle?: CSSProperties;
}): ReactElement => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      ...containerStyle,
    }}
  >
    <span className="ellipsized" style={valueStyle}>
      {value}
    </span>
    <span className="opaque-text ellipsized" style={labelStyle}>
      {label}
    </span>
  </div>
);

export default LabeledStat;
