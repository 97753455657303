export const BASE_PATH_ADMIN_CMS = "/admin-cms";
export const BASE_PATH = "/cms";
export const SITES_PATH = "/sites/filter";
export const SESSION_PATH = "/sessions";
export const SESSION_ROUTE_PATH = "/session/coordinates";
export const SITE_USERS_PATH = "/site-users";
export const PEN_PATH = "/pens/coordinates";
export const DEAD_ANIMALS_PATH = "/dead-animals-list";
export const SITE_SYNCS_PATH = "/sync-history";
export const PENDING_REGIMENS_PATH = "/pending-regimens-list";
export const VALIDATE_KML_FILE_PATH = "/pens/coordinates/validate";
export const UPLOAD_KML_FILE_PATH = "/pens/coordinates";
export const SESSION_DETAILS_PATH = "/session-details";
