import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

type SessionDetailsContextValue = {
  selectedPen?: number;
  setSelectedPen?: Dispatch<SetStateAction<number>>;
  currentPens?: Array<number>;
  setCurrentPens?: Dispatch<SetStateAction<Array<number>>>;
};

const SessionDetailsContext = createContext<SessionDetailsContextValue>({});

const initialValue: SessionDetailsContextValue = {};

const SessionDetailsProvider = ({
  children,
  initialValues = initialValue,
}: {
  children: ReactNode;
  initialValues?: SessionDetailsContextValue;
}): ReactElement => {
  const [selectedPen, setSelectedPen] = useState<number>(
    initialValues?.selectedPen
  );
  const [currentPens, setCurrentPens] = useState<Array<number>>([]);

  const value = useMemo<SessionDetailsContextValue>(
    () => ({
      selectedPen,
      setSelectedPen,
      currentPens,
      setCurrentPens,
    }),
    [currentPens, selectedPen]
  );

  return (
    <SessionDetailsContext.Provider value={value}>
      {children}
    </SessionDetailsContext.Provider>
  );
};

export default SessionDetailsProvider;

export const useSessionDetails = (): SessionDetailsContextValue => {
  const sessionDetailsContext = useContext(SessionDetailsContext);
  return sessionDetailsContext;
};
