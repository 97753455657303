import { Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import React from "react";

const PenCoordinateCell = ({
  coordinates,
}: {
  coordinates: Coordinate[];
}): React.ReactElement => (
  <div>
    {coordinates.map((coordinate, index) => (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: "10px", color: "rgba(0, 0, 0, 0.4)" }}>
          {index + 1}
        </div>
        <div>
          {coordinate.latitude}, {coordinate.longitude}
        </div>
      </div>
    ))}
  </div>
);

const columns: ColumnsType<PenAndCoordinateElement> = [
  {
    title: "Pen",
    dataIndex: "pen_name",
    width: 93,
  },
  {
    title: "Coordinates",
    dataIndex: "coordinates",
    render: (value: Coordinate[]) => <PenCoordinateCell coordinates={value} />,
  },
];

const PenCoordinatesTable = ({
  tableData,
  loading = false,
}: {
  tableData: PenAndCoordinateElement[];
  loading?: boolean;
}): React.ReactElement => (
  <div className="pen-coordinate-table-container">
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      sticky={true}
      className={"upload-pen-coordinate-table"}
      rowKey={(record) => record.pen_name}
      data-testid={"pen-coordinates-list-table"}
      loading={loading}
    />
  </div>
);

export default PenCoordinatesTable;
