import React, { Component } from "react";
import axios from "axios";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import eventEmitter from "utils/eventEmitter";
import UserRoleSelect from "components/common/UserRoleselect";
import errorMessage from "utils/errorMessage";

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.ee = eventEmitter.EventEmitter;
  }

  state = {
    data: [],
    currentPage: 1,
    currentRoleSlug: "all_users",
    roleTypes: window.AvailableRoles.roleTypes,
    searchTerm: "",
    sortField: "name",
    sortType: "asc",
    total: 1,
    loading: true,
  };

  componentDidMount = () => {
    this.getTheData();

    this.ee.on("event", () => {
      const searchTerm = window.SearchTerm;
      this.setState({ searchTerm }, this.getTheData);
    });
  };

  changeFilterRole = (slug) =>
    this.setState({ currentRoleSlug: slug }, this.getTheData);

  getTheData = (page = 1) => {
    let theSlug = null;
    if (this.state.currentRoleSlug !== "all_users") {
      theSlug = this.state.currentRoleSlug;
    }

    const url = "/cms/users/filter";
    const params = {
      name: this.state.searchTerm !== "" ? this.state.searchTerm : null,
      page: page,
      role: theSlug,
      sortBy: this.state.sortField,
      sortType: this.state.sortType,
    };

    this.setState({ loading: true }, () => {
      axios
        .get(url, {
          params,
        })
        .then(
          (response) => {
            const data = response.data.data;
            const currentPage = response.data.current_page;
            const total = response.data.total;
            this.setState({ data, currentPage, total, loading: false });
          },
          (error) => {
            this.setState({ loading: false }, () => errorMessage(error));
          }
        );
    });
  };

  toggleSortType = () => {
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" }, this.getTheData);
    } else {
      this.setState({ sortType: "asc" }, this.getTheData);
    }
  };

  toggleNameSort = () => {
    if (this.state.sortField === "name") {
      this.toggleSortType();
    } else {
      const sortField = "name";
      const sortType = "asc";
      this.setState({ sortField, sortType }, this.getTheData);
    }
  };

  toggleStatusSort = () => {
    if (this.state.sortField === "status") {
      this.toggleSortType();
    } else {
      const sortField = "status";
      const sortType = "asc";
      this.setState({ sortField, sortType }, this.getTheData);
    }
  };

  render = () => {
    const columns = [
      {
        title: (
          <span
            className={`order-sorter ${
              this.state.sortField === "name" ? this.state.sortType : ""
            }`}
            onClick={this.toggleNameSort}
          >
            Name{" "}
            <div className="icon-container">
              <CaretUpOutlined /> <CaretDownOutlined />
            </div>
          </span>
        ),
        dataIndex: "list_display_name",
      },
      {
        title: "Phone Number",
        dataIndex: "phone_number",
      },
      {
        title: "Registration Status",
        dataIndex: "is_verified",
      },
      {
        title: (
          <span
            className={`order-sorter ${
              this.state.sortField === "status" ? this.state.sortType : ""
            }`}
            onClick={this.toggleStatusSort}
          >
            Active Status{" "}
            <div className="icon-container">
              <CaretUpOutlined />
              <CaretDownOutlined />
            </div>
          </span>
        ),
        dataIndex: "is_active",
      },
      {
        title: "Role",
        dataIndex: "role_name",
      },
      {
        dataIndex: "edit",
        render: (text, row) => (
          <span>
            <a href={"/cms/users/" + row.id}>Edit</a>
          </span>
        ),
      },
    ];

    const pagination = {
      onChange: this.getTheData,
      current: this.state.currentPage,
      total: this.state.total,
      pageSize: 12,
      hideOnSinglePage: true,
      showSizeChanger: false,
    };

    return (
      <div className={"list-page"}>
        <div className={"row list-page-top-bar"}>
          <div style={{ width: "80%" }}>
            <span className="font-bold filter-label">Select Role:</span>
            <UserRoleSelect
              currentRoleSlug={this.state.currentRoleSlug}
              roles={this.state.roleTypes}
              onChange={this.changeFilterRole}
            />
          </div>

          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button type="primary" className={"small-button next-to-top-nav"}>
              <a href="/cms/users/create-tk-admin">Add New Admin</a>
            </Button>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={this.state.data}
          className={"custom-table"}
          rowKey={"id"}
          pagination={pagination}
          loading={this.state.loading}
        />
      </div>
    );
  };
}

export default UsersList;
