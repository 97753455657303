import { rest } from "msw";
import { moduleFixture } from "__fixtures__/modules";

const siteControllerHandlers = [
  rest.get(`*/cms/sites/:siteId/module`, (_req, res, ctx) => {
    return res(ctx.json({ data: moduleFixture }));
  }),
];

export default siteControllerHandlers;
