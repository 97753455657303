export const sampleSessionDetails: SessionDetails[] = [
  {
    pen: 11111,
    pen_id: 11111,
    treatments: 4,
    deads: 5,
    time: "10:37",
  },
  {
    pen: 2,
    pen_id: 2,
    treatments: 1,
    deads: 2,
    time: "5:37",
  },
  {
    pen: 3,
    pen_id: 3,
    treatments: 2,
    deads: 7,
    time: "1:34",
  },
  {
    pen: 4,
    pen_id: 4,
    treatments: 8,
    deads: 4,
    time: "3:33",
  },
  {
    pen: 5,
    pen_id: 5,
    treatments: 10,
    deads: 11,
    time: "6:37",
  },
  {
    pen: 6,
    pen_id: 6,
    treatments: 7,
    deads: 7,
    time: "2:30",
  },
  {
    pen: 7,
    pen_id: 7,
    treatments: 4,
    deads: 4,
    time: "4:44",
  },
  {
    pen: 8,
    pen_id: 8,
    treatments: 8,
    deads: 4,
    time: "1:04",
  },
  {
    pen: 9,
    pen_id: 9,
    treatments: 6,
    deads: 5,
    time: "5:01",
  },
  {
    pen: 10,
    pen_id: 10,
    treatments: 3,
    deads: 43,
    time: "0:34",
  },
];

export const sampleSessionsList: SessionListElement[] = [
  {
    id: "68",
    site_user_name: "John Smith",
    total_time: "5:40",
    date: "05/14/17",
  },
  {
    id: "72",
    site_user_name: "Jane Doe",
    total_time: "2:40",
    date: "05/12/17",
  },
];

export const sampleMetadata: PaginationMetadata = {
  total: 2,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  next_page_url: null,
  prev_page_url: null,
  from: 1,
  to: 2,
};
