import { Feature } from "geojson";
import { PenCoordinatesAndCenterpoints } from "types/common/geoLocationData";

export const mockReturnPenAndCenterpointData: PenCoordinatesAndCenterpoints = {
  data: [
    {
      pen_id: 7,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 7, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.79016768932343, 46.88342331606478],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.79085969924927, 46.88319232664621],
              [-96.78948640823364, 46.88317766058414],
              [-96.78947567939758, 46.88366897148026],
              [-96.79084897041321, 46.88365430554849],
              [-96.79085969924927, 46.88319232664621],
            ],
          ],
        },
      },
    },
    {
      pen_id: 10,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 10, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.78979218006134, 46.88415202641623],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.79010868072508, 46.88461491560868],
              [-96.79012477397919, 46.88369097037036],
              [-96.78945422172546, 46.88369830333171],
              [-96.78948104381561, 46.88460391635421],
              [-96.79010868072508, 46.88461491560868],
            ],
          ],
        },
      },
    },
    {
      pen_id: 5,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 5, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.78999736905098, 46.8827340090814],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.79045736789703, 46.88242968610317],
              [-96.78977608680725, 46.88231602240165],
              [-96.78951323032379, 46.88309333064955],
              [-96.79024279117584, 46.88309699717121],
              [-96.79045736789703, 46.88242968610317],
            ],
          ],
        },
      },
    },
    {
      pen_id: 3,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 3, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.79023742675781, 46.88199336181726],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.7904680967331, 46.88237102099698],
              [-96.79067194461821, 46.88172936720998],
              [-96.79002285003662, 46.88160470215532],
              [-96.7897868156433, 46.88226835690676],
              [-96.7904680967331, 46.88237102099698],
            ],
          ],
        },
      },
    },
    {
      pen_id: 11,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 11, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.79052174091339, 46.8841529429577],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.79087579250334, 46.88462224844376],
              [-96.79087042808533, 46.88368363740798],
              [-96.79017305374146, 46.88369097037036],
              [-96.79016768932343, 46.88461491560868],
              [-96.79087579250334, 46.88462224844376],
            ],
          ],
        },
      },
    },
    {
      pen_id: 1,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 1, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.78859215974808, 46.88255178076737],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.78942739963531, 46.88310066369261],
              [-96.78970903158188, 46.88229768952401],
              [-96.78825795650482, 46.88211069381427],
              [-96.78778856992722, 46.88215652615637],
              [-96.78777784109116, 46.88309333064955],
              [-96.78942739963531, 46.88310066369261],
            ],
          ],
        },
      },
    },
    {
      pen_id: 6,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 6, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.79073765873909, 46.88279725730708],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.79098308086395, 46.88310433021375],
              [-96.791170835495, 46.88254334956389],
              [-96.7905056476593, 46.88243701923694],
              [-96.7902910709381, 46.88310433021375],
              [-96.79098308086395, 46.88310433021375],
            ],
          ],
        },
      },
    },
    {
      pen_id: 9,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 9, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.7883083820343, 46.88401654851542],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.78857445716858, 46.8845855842584],
              [-96.78858518600464, 46.88455625289205],
              [-96.78860664367676, 46.88317766058414],
              [-96.78785562515259, 46.88317766058414],
              [-96.78791999816895, 46.8845855842584],
              [-96.78857445716858, 46.8845855842584],
            ],
          ],
        },
      },
    },
    {
      pen_id: 4,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 4, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.789640635252, 46.88149286960356],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.78925037384033, 46.88163770175679],
              [-96.78989946842194, 46.8817550335088],
              [-96.79003357887268, 46.88134803789536],
              [-96.78937911987305, 46.88123070525328],
              [-96.78925037384033, 46.88163770175679],
            ],
          ],
        },
      },
    },
    {
      pen_id: 8,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 8, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.78902506828308, 46.88388895530821],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.7893898487091, 46.88317766058414],
              [-96.78866028785704, 46.88318499361567],
              [-96.788649559021, 46.88459291709747],
              [-96.78940057754517, 46.88460024993555],
              [-96.7893898487091, 46.88317766058414],
            ],
          ],
        },
      },
    },
    {
      pen_id: 2,
      centerpoint: {
        type: "Feature",
        properties: { pen_id: 2, display_label: "Pen" },
        geometry: {
          type: "Point",
          coordinates: [-96.78904384374619, 46.8817914371171],
        },
      },
      coordinates: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-96.78971439599991, 46.88225919046058],
              [-96.78989142179489, 46.8817861997123],
              [-96.78928524255753, 46.88168720112096],
              [-96.78914308547972, 46.88162486858088],
              [-96.78852081298828, 46.88151120317422],
              [-96.78844302892685, 46.8815918689715],
              [-96.7883089184761, 46.88207952779927],
              [-96.78971439599991, 46.88225919046058],
            ],
          ],
        },
      },
    },
  ],
};

export const mockReturnPenRiderRoute: Feature = {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: [
      [-96.78777806299503, 46.88388763515042],
      [-96.78777824371377, 46.88388643391599],
      [-96.7878101996185, 46.88392084054827],
      [-96.78780318548881, 46.88384763573574],
      [-96.78778494337693, 46.88378805941693],
      [-96.78778988100876, 46.88371466274843],
      [-96.78788596263882, 46.88363013471222],
      [-96.78795425580404, 46.88368440071278],
      [-96.78794253938818, 46.88375990601886],
      [-96.78803202075784, 46.88381340553352],
      [-96.78801041502065, 46.88384049363684],
      [-96.78798818289401, 46.88391589473081],
      [-96.78797553879282, 46.88394554228264],
      [-96.78800836582082, 46.88399949810145],
      [-96.78799274064966, 46.88404120309469],
      [-96.78805430650452, 46.88408504707631],
      [-96.78805610376426, 46.88408970028332],
      [-96.78813328077644, 46.88409443580671],
      [-96.78817499220285, 46.88407130488638],
      [-96.78821493584448, 46.88404134224602],
      [-96.78823334371182, 46.88402423432223],
      [-96.78827284771361, 46.88404368420205],
      [-96.78829820656543, 46.8840865617218],
      [-96.78825825622664, 46.88408971989272],
      [-96.78831729617173, 46.88419356224106],
      [-96.78821480595099, 46.8842180755382],
      [-96.78827250211283, 46.88427996535536],
      [-96.7883107302576, 46.88429708834111],
      [-96.78836317112557, 46.88429331513609],
      [-96.78836571645773, 46.88432115981472],
      [-96.78834396517482, 46.8843402097939],
      [-96.78842765125904, 46.88424564077532],
      [-96.78853495846168, 46.88425144922061],
      [-96.78857993376718, 46.88424872269258],
      [-96.78864299763681, 46.88427917709382],
      [-96.78864523933943, 46.88429528743444],
      [-96.7887094306656, 46.88433062765102],
      [-96.78873470153526, 46.88433601484558],
      [-96.78885535636549, 46.88429473365078],
      [-96.78890610392732, 46.88428735527851],
      [-96.7889218600664, 46.8842691441855],
      [-96.78884735880075, 46.88420404713927],
      [-96.78887971850043, 46.88415624533484],
      [-96.78883231312145, 46.88413765591018],
      [-96.78877244512857, 46.88409249443573],
      [-96.78881839165646, 46.88406557490059],
      [-96.78870080585789, 46.88402211051384],
      [-96.78863480763079, 46.88405570714897],
      [-96.78862288183804, 46.88408961997157],
      [-96.78863795312222, 46.88412123640092],
      [-96.78863144221494, 46.88416793480184],
      [-96.78850914677689, 46.88417905492028],
      [-96.78855174048554, 46.88432854173948],
      [-96.78858983200294, 46.88439064011414],
      [-96.78859538707327, 46.88439704365114],
      [-96.78858011737927, 46.8844104865547],
      [-96.78869608201938, 46.88450909549927],
      [-96.78879167454113, 46.88451865590348],
      [-96.78883375100504, 46.88451318889068],
      [-96.7888507131395, 46.88456531259329],
      [-96.78887587659302, 46.88456688874704],
      [-96.78890700581896, 46.88455985304989],
      [-96.78893397185085, 46.88458624321647],
      [-96.78899931130721, 46.88457030258528],
      [-96.78904990133375, 46.88458722514527],
      [-96.78921433506773, 46.884487785554],
      [-96.78920404212622, 46.88445406087429],
      [-96.78922090014945, 46.88443608195839],
      [-96.78921072499627, 46.88437664473554],
      [-96.78914215982641, 46.88438527950121],
      [-96.78911129206267, 46.88438293152527],
      [-96.78912377787925, 46.88434773667992],
      [-96.78908655561303, 46.88427431302571],
      [-96.78918826953101, 46.88427963989251],
      [-96.78925652609132, 46.8843160923694],
      [-96.78935943868667, 46.8843557690801],
      [-96.78939557199767, 46.8843895847721],
      [-96.78941895770657, 46.88437630065204],
      [-96.78939371003595, 46.88441747773023],
      [-96.78939346692594, 46.88445137690096],
      [-96.789539697106, 46.88439590807313],
      [-96.78957121013804, 46.88436222383225],
      [-96.78960345670626, 46.88430893586927],
      [-96.78964801369764, 46.88431119209599],
      [-96.78954451071905, 46.88430235280996],
      [-96.78954799410265, 46.88428008915361],
      [-96.78956505811924, 46.88423690278839],
      [-96.78948720347324, 46.8842541576663],
      [-96.78942302313818, 46.88431919939401],
      [-96.78937700574987, 46.88422959556855],
      [-96.78950876048017, 46.88416631811616],
      [-96.7894327602673, 46.88410161509802],
      [-96.78948091345637, 46.88406240501987],
      [-96.78952778695233, 46.88404069402531],
      [-96.78945861303912, 46.88401313730903],
      [-96.78934807188627, 46.8839676355269],
      [-96.78927742778276, 46.884007323321],
      [-96.78926425117992, 46.88404076368443],
      [-96.7891770768078, 46.88401614354894],
      [-96.78908090292722, 46.88400286099689],
      [-96.78898739784748, 46.88399208585344],
      [-96.7889637231633, 46.88398519327649],
      [-96.7889838781123, 46.88405387259725],
      [-96.78900583007477, 46.88409113386684],
      [-96.7890196526232, 46.88415232426251],
      [-96.78909239865548, 46.88387673336066],
      [-96.78911801377265, 46.8838519711875],
      [-96.7891520112565, 46.88381609435696],
      [-96.78907561483325, 46.88375777051758],
      [-96.78902958555524, 46.88379140118458],
      [-96.7888514180114, 46.88387490293409],
      [-96.78869132709241, 46.883860167587],
      [-96.78863988083019, 46.88384229678534],
      [-96.78860505031713, 46.88381281161597],
      [-96.7885617153184, 46.88380967583378],
      [-96.78849405844707, 46.88390425254274],
      [-96.78854120320229, 46.88396568097768],
      [-96.7886856223077, 46.8839008769671],
      [-96.7883667459955, 46.88392385893982],
      [-96.78826692666466, 46.88389288798356],
      [-96.78821101864096, 46.88384369903301],
      [-96.78821273293065, 46.88382998314546],
      [-96.78818409347264, 46.88379496188438],
      [-96.78814223324613, 46.88385502695814],
      [-96.78812798049101, 46.88387927070564],
      [-96.7883040304667, 46.88382897995445],
      [-96.78830597805562, 46.88373370466735],
      [-96.78825254122424, 46.88372627837963],
      [-96.78839235287808, 46.88375531991068],
      [-96.78848166749373, 46.88367872717378],
      [-96.78846004440624, 46.88362705171211],
      [-96.78838414989794, 46.88359642064932],
      [-96.78854278103081, 46.88357377203378],
      [-96.788473962542, 46.88349435681469],
      [-96.7884223417234, 46.88346528316065],
      [-96.7883901918837, 46.88341088669988],
      [-96.7883181679806, 46.88340092490319],
      [-96.78827726598972, 46.88343393836477],
      [-96.78820162139006, 46.88344792440811],
      [-96.78810972955617, 46.88341121068606],
      [-96.78807165772452, 46.88336260967286],
      [-96.78802889868236, 46.88330091814934],
      [-96.7878850336134, 46.88337930148849],
      [-96.78789173981303, 46.8834484203785],
      [-96.78799369299091, 46.88345334784797],
      [-96.78796995860435, 46.88349836457236],
      [-96.7880620688817, 46.88350933033828],
      [-96.78812881513512, 46.88355781500799],
      [-96.78819250672969, 46.88352732369052],
      [-96.78815022665101, 46.88360953821465],
      [-96.78799683436115, 46.88359607376566],
      [-96.78799887476995, 46.88358151204895],
      [-96.78778668852908, 46.88356026788083],
      [-96.7882913649697, 46.88331847236986],
      [-96.78837544302874, 46.8833510461792],
      [-96.7884027826401, 46.88335304215283],
      [-96.78842955423454, 46.88335148161619],
      [-96.78855222440912, 46.88330208733198],
      [-96.78862754140927, 46.8833147709875],
      [-96.7887140103653, 46.88328687261483],
      [-96.78872219985232, 46.88330501928836],
      [-96.78870301655101, 46.8832145910152],
      [-96.78865755858268, 46.88315262003749],
      [-96.78863153191033, 46.88314677378589],
      [-96.78854979111952, 46.88314631667485],
      [-96.78849688993704, 46.88315028205739],
      [-96.78844855734191, 46.8831496493788],
      [-96.78840080046699, 46.88314383664016],
      [-96.78839986690815, 46.88316614993703],
      [-96.78837147778223, 46.88310675867967],
      [-96.78831392120998, 46.88307024852165],
      [-96.78830684017888, 46.88314513948742],
      [-96.78825563787441, 46.88325586636513],
      [-96.78808873530741, 46.88313899313827],
      [-96.78815993636736, 46.88302099503655],
      [-96.78815740736846, 46.88299903308103],
      [-96.78804758349018, 46.88296064830099],
      [-96.7879577036035, 46.88297576591813],
      [-96.787934530394, 46.88295148896512],
      [-96.78797099627566, 46.88300335549731],
      [-96.78793773061312, 46.8830529779025],
      [-96.78784381610868, 46.88301558535616],
      [-96.78781498446222, 46.88305563522723],
      [-96.7878983196676, 46.8831242600716],
      [-96.78778720941806, 46.88290468394658],
      [-96.787868832094, 46.88281377050533],
      [-96.78787980730537, 46.88281123994533],
      [-96.78789187592228, 46.88272609033989],
      [-96.78790372371252, 46.88272003971094],
      [-96.7877802089324, 46.88265596431651],
      [-96.78781667555306, 46.88258684832336],
      [-96.78794560060405, 46.8825354072002],
      [-96.78796991888989, 46.88258303716203],
      [-96.7880381612672, 46.88263957480129],
      [-96.78807987141137, 46.88263924435348],
      [-96.78810539003709, 46.8826349403134],
      [-96.78819540752477, 46.88258461288762],
      [-96.78819760889856, 46.88251777247328],
      [-96.78826708904494, 46.88253342791294],
      [-96.78829260236196, 46.88245608394018],
      [-96.78819742964446, 46.88244281703283],
      [-96.78811842799942, 46.8823968413963],
      [-96.78807785203642, 46.8824144358547],
      [-96.7879716993399, 46.88241429528434],
      [-96.7878402281283, 46.88244025839482],
      [-96.78779470966688, 46.88235687532162],
      [-96.78801666613576, 46.88225778226965],
      [-96.78802378037548, 46.88220595851383],
      [-96.78804795959374, 46.88214398749356],
      [-96.7881339454179, 46.8820314151037],
      [-96.78820823154282, 46.88199390965247],
      [-96.78806344909579, 46.88197084735453],
      [-96.78806332522868, 46.88191328616118],
      [-96.78796290967735, 46.88193269130794],
      [-96.7879190044743, 46.88193293114399],
      [-96.7878373387619, 46.88190289418957],
      [-96.78794252065369, 46.88185294338485],
      [-96.78801383990864, 46.881848521364],
      [-96.78807858710337, 46.88182515872342],
      [-96.7880763244112, 46.88181342256368],
      [-96.78807336494386, 46.88172396357894],
      [-96.78804757351669, 46.88171794654536],
      [-96.78800581188683, 46.8816885432634],
      [-96.78795116167731, 46.8816426461874],
      [-96.78782658016866, 46.88165340641057],
      [-96.7878509136997, 46.88158420947705],
      [-96.78789055859119, 46.88153097306844],
      [-96.78788198056112, 46.88149027943832],
      [-96.7878279006158, 46.88150154633102],
      [-96.78794036298909, 46.88135109182519],
      [-96.78797258766708, 46.88135096667502],
      [-96.78809132526489, 46.88138030788517],
      [-96.78812029950245, 46.88134880993825],
      [-96.78810309113634, 46.88126274590259],
      [-96.78837111704026, 46.88123930706907],
      [-96.78841673590696, 46.88124327176667],
      [-96.78859329061196, 46.88126854930604],
      [-96.78869245970982, 46.8812776422695],
      [-96.78872729666428, 46.88130570102178],
      [-96.78874977750968, 46.88136882097701],
      [-96.78876274308772, 46.8814343104118],
      [-96.78861946076223, 46.88144507270538],
      [-96.78848794254885, 46.88142982427643],
      [-96.78850688821134, 46.88136073459056],
      [-96.78831188641033, 46.88140102654698],
      [-96.78828734486088, 46.88141398486182],
      [-96.78821124847332, 46.88151000576466],
      [-96.78819596785102, 46.88158169963897],
      [-96.788239790069, 46.88159865387162],
      [-96.7881869433796, 46.88163911872385],
      [-96.78831756775044, 46.88153459561009],
      [-96.78831869476684, 46.88152290502327],
      [-96.78847048197053, 46.88152446170527],
      [-96.78865336543572, 46.88162256533723],
      [-96.78864224839378, 46.88163641404143],
      [-96.7886596029775, 46.88164241249574],
      [-96.7887018635863, 46.88158389523638],
      [-96.7888605061385, 46.88158898275553],
      [-96.7889334664342, 46.88152238632273],
      [-96.78892337789377, 46.88144725490626],
      [-96.7889173140764, 46.88142981757454],
      [-96.788963780064, 46.88140747119486],
      [-96.78897079917705, 46.88144699655963],
      [-96.78909106541975, 46.88146987204879],
      [-96.78914445010416, 46.88148106191874],
      [-96.78918142966292, 46.881469701076],
      [-96.78917678233852, 46.88144917883287],
      [-96.7891252759033, 46.88138288585926],
      [-96.78912273526899, 46.88135749678368],
      [-96.78912081513157, 46.88127022708959],
      [-96.78906488337891, 46.88127459686672],
      [-96.78903255323563, 46.88125702531654],
      [-96.78896537142607, 46.88126231455477],
      [-96.78884139848613, 46.88130746656285],
      [-96.78878549345087, 46.88123644893924],
      [-96.78917226615832, 46.88123117874995],
      [-96.78936684787433, 46.8814576711214],
      [-96.78938577303296, 46.88148273855498],
      [-96.78949842815997, 46.88148269222894],
      [-96.78955998408038, 46.88140403319413],
      [-96.78965019046439, 46.88143531985468],
      [-96.78969873116444, 46.88140839600775],
      [-96.78966369535281, 46.88132867369387],
      [-96.78979148796803, 46.88135273699438],
      [-96.78990627259456, 46.88134856204287],
      [-96.78993407961705, 46.8813802312333],
      [-96.78998610019558, 46.88139039837693],
      [-96.79001501484731, 46.88138317420599],
      [-96.78997047092957, 46.88135048444169],
      [-96.79007438577291, 46.88130466945844],
      [-96.79013408017566, 46.88127655899205],
      [-96.7902561509416, 46.88128704481537],
      [-96.79022050901766, 46.88136563721533],
      [-96.7900868226989, 46.88139637400885],
      [-96.79004234002132, 46.88147498837244],
      [-96.79020729634077, 46.88147881555421],
      [-96.79018979141746, 46.8815393926384],
      [-96.79019290875296, 46.88154562223102],
      [-96.79018360872774, 46.88155691591156],
      [-96.7902927486423, 46.88162704381642],
      [-96.79028168149863, 46.88165145485642],
      [-96.79033597859667, 46.8817660618525],
      [-96.79041995089399, 46.88174765312964],
      [-96.79042105860809, 46.88179954512853],
      [-96.79039560810303, 46.88181531517675],
      [-96.79032258117707, 46.88186530878901],
      [-96.79020897015793, 46.88186096103517],
      [-96.79013079079725, 46.88185241711099],
      [-96.79010490196069, 46.88186214199223],
      [-96.79014891689468, 46.88191878092636],
      [-96.79015445939262, 46.88197929419106],
      [-96.79016676613448, 46.8820198232002],
      [-96.7901900213134, 46.8820476054005],
      [-96.79031344537319, 46.88208419456733],
      [-96.79033542404261, 46.88213170672207],
      [-96.79047611255815, 46.88218715087479],
      [-96.79032592437876, 46.88227349926564],
      [-96.79042143553173, 46.8823214653937],
      [-96.7903915650799, 46.88237303386421],
      [-96.7904019450154, 46.88241253050353],
      [-96.79038296022993, 46.88246960181675],
      [-96.79035137871693, 46.88247690656377],
      [-96.79036419772461, 46.88251605883605],
      [-96.79030837976984, 46.88254988753783],
      [-96.79024467875827, 46.88255566743408],
      [-96.79025439522123, 46.88245598889527],
      [-96.79031114025067, 46.88242966128322],
      [-96.7901961903822, 46.88240264084106],
      [-96.79011767514532, 46.88243414629085],
      [-96.79014467808491, 46.88245811326588],
      [-96.79004264490924, 46.8823486939083],
      [-96.7901459413575, 46.88231758614664],
      [-96.79021250632736, 46.88232607707295],
      [-96.78991370456542, 46.88240677125072],
      [-96.7897915990409, 46.8824829583099],
      [-96.78983860344664, 46.88252653688648],
      [-96.78970955615122, 46.88246411544535],
      [-96.78968546633305, 46.88249743539539],
      [-96.78962469975987, 46.8824831971741],
      [-96.78954327467058, 46.88247350052671],
      [-96.78956654762426, 46.88256583530738],
      [-96.7894905233311, 46.88262732735553],
      [-96.78952881827546, 46.88268135330902],
      [-96.78961339719636, 46.88275966125239],
      [-96.78969113964706, 46.88274025466616],
      [-96.78947564864698, 46.88278379347569],
      [-96.78943805592137, 46.88276989047523],
      [-96.78943103775758, 46.88275713535693],
      [-96.78936466145375, 46.88273768778229],
      [-96.78922757766053, 46.88275632200414],
      [-96.78920408550812, 46.88277395766035],
      [-96.78918854032669, 46.88276491169192],
      [-96.789122891234, 46.8827738714491],
      [-96.78911571090018, 46.88273244927152],
      [-96.78898586365753, 46.88272091541203],
      [-96.78895667410814, 46.88279244923571],
      [-96.78896373333127, 46.88286372911297],
      [-96.78912788379483, 46.88286958231962],
      [-96.78914612042684, 46.882882133362],
      [-96.78924362698004, 46.88291991410984],
      [-96.78927316791895, 46.88287143742385],
      [-96.78925530802849, 46.88281223453556],
      [-96.78936342913804, 46.88289542100669],
      [-96.78937345795885, 46.8828902967467],
      [-96.78936905504823, 46.88296021499439],
      [-96.7893181654485, 46.88294963768995],
      [-96.78924863376459, 46.88299991896231],
      [-96.78920538650767, 46.88302844256125],
      [-96.78920302777357, 46.88305215789516],
      [-96.78918045300274, 46.88299837290856],
      [-96.7891701689285, 46.88295776018433],
      [-96.78938191004983, 46.88306878404863],
      [-96.78947522109726, 46.88313491824661],
      [-96.78942168720776, 46.88316918250596],
      [-96.78942196217882, 46.88319494401528],
      [-96.7894924312255, 46.88319213320478],
      [-96.7894760811669, 46.88325694912721],
      [-96.78947808219125, 46.88326242245184],
      [-96.78943904917539, 46.88325692884877],
      [-96.78955290306328, 46.88331752991458],
      [-96.78958159788993, 46.88334015938217],
      [-96.78963428770285, 46.88339364728805],
      [-96.7897589656887, 46.88343497932191],
      [-96.78984974149382, 46.88338174715902],
      [-96.78988285055726, 46.88334034620673],
      [-96.78986197185206, 46.88329223303885],
      [-96.78982624695949, 46.88328411533325],
      [-96.78986029604506, 46.88324348652186],
      [-96.789781304774, 46.88323716850553],
      [-96.78976869535424, 46.88324541778955],
      [-96.78975887936797, 46.88333031610993],
      [-96.78992781867439, 46.88340356018759],
      [-96.78992093245155, 46.88343864660258],
      [-96.79001066938281, 46.88337514307116],
      [-96.78999922698036, 46.88330969053918],
      [-96.79006085383598, 46.88330629372641],
      [-96.79012548035438, 46.88326240676194],
      [-96.79010350037899, 46.88318676217638],
      [-96.79015016646467, 46.88317797336251],
      [-96.7902365596937, 46.88320751502872],
      [-96.79031132457493, 46.88324058775227],
      [-96.79032345503501, 46.88325923648223],
      [-96.79033172219549, 46.88329510156874],
      [-96.79027569819658, 46.88329208392378],
      [-96.7901791382215, 46.88336503301064],
      [-96.79027872123736, 46.88343360248315],
      [-96.79037125202335, 46.88348378686745],
      [-96.79042100277458, 46.88350238536379],
      [-96.79043998862932, 46.88347984324831],
      [-96.79044606921968, 46.88354840146809],
      [-96.79040724888107, 46.88359638589243],
      [-96.79033411254485, 46.88359858164662],
      [-96.79051556846376, 46.88364111506482],
      [-96.7905409805932, 46.88364413302094],
      [-96.7905706932962, 46.88359787163736],
      [-96.79060089218844, 46.88361408957664],
      [-96.79063297322978, 46.88361571479664],
      [-96.79060587761316, 46.88351288168006],
      [-96.79062927861494, 46.88350651116668],
      [-96.79060452892143, 46.88348470853224],
      [-96.79072995369565, 46.88344302282357],
      [-96.79080589071059, 46.88343790458405],
      [-96.79084739387109, 46.88343656714628],
      [-96.7909216463455, 46.88339505174342],
      [-96.79093073750953, 46.8833752164695],
      [-96.79102587687564, 46.88345552690595],
      [-96.79113147995008, 46.88344022858724],
      [-96.79113473099088, 46.88345300046868],
      [-96.79109823944101, 46.88334968724259],
      [-96.79107972326734, 46.88327522138942],
      [-96.79100011966351, 46.88326175280451],
      [-96.7909664219837, 46.88327292178294],
      [-96.79109971562237, 46.88321052013798],
      [-96.79107942689622, 46.88309095173433],
      [-96.7911608053652, 46.8830557634599],
      [-96.79095856624174, 46.88307540853177],
      [-96.79085760107117, 46.88309602613047],
      [-96.7908328027603, 46.88308911073769],
      [-96.79074404789135, 46.88307100219463],
      [-96.79068936057843, 46.8830320194077],
      [-96.79081137469771, 46.88299814119001],
      [-96.79085267602817, 46.88300138849999],
      [-96.79074318958828, 46.88294523592408],
      [-96.79086105520744, 46.88285659388099],
      [-96.79087972743167, 46.88276799159605],
      [-96.79076305844663, 46.88275215689142],
      [-96.7907335490906, 46.88270423203595],
      [-96.79074772265174, 46.88267749730211],
      [-96.79074560816073, 46.88264012879087],
      [-96.79071406558116, 46.88263645744964],
      [-96.79077421097276, 46.88262134679183],
      [-96.79087545865997, 46.88260542462902],
      [-96.7908601663224, 46.88256094389496],
      [-96.79086769211304, 46.88251530738847],
      [-96.79095563194534, 46.88245998266201],
      [-96.79103907030719, 46.88245902917524],
      [-96.79112354619372, 46.88252033954097],
      [-96.79108159309455, 46.88255538160612],
      [-96.79101311167263, 46.88260319040974],
      [-96.79089375255379, 46.88266315333499],
      [-96.79099088571115, 46.88272215083225],
      [-96.79103215369884, 46.88279488606114],
      [-96.79108893221184, 46.88277568307454],
      [-96.79115266199686, 46.88273808487078],
      [-96.79062126121045, 46.88274154754296],
      [-96.79043393310327, 46.88275362492657],
      [-96.79041053263956, 46.88269733409965],
      [-96.7904529463262, 46.88266651402662],
      [-96.79040545695064, 46.88265050710967],
      [-96.79032280345535, 46.88274554530383],
      [-96.79034465798497, 46.8827959180585],
      [-96.79025543486622, 46.88281039302021],
      [-96.7902083673763, 46.88283871907138],
      [-96.79017622252434, 46.88280433999654],
      [-96.79021194274937, 46.88273321927173],
      [-96.7902110515276, 46.88272304138278],
      [-96.79017390457815, 46.88269630841355],
      [-96.79014404756474, 46.88271315744832],
      [-96.78999526795668, 46.88262751286122],
      [-96.79004150338106, 46.88284406131224],
      [-96.78995548373052, 46.88290984349677],
      [-96.78986008385225, 46.8829649510388],
      [-96.78984677482856, 46.88300246354262],
      [-96.78980399912577, 46.88307941481972],
      [-96.7896845311495, 46.88305153715094],
      [-96.78970218052, 46.88301045435538],
      [-96.78964726260875, 46.88312706432442],
      [-96.789586204523, 46.8832347839139],
      [-96.78935353722677, 46.8833460269944],
      [-96.78935485645424, 46.88340944007142],
      [-96.78937544063677, 46.88344551259073],
      [-96.78943475801039, 46.88350571413539],
      [-96.78945295750201, 46.88354757502855],
      [-96.78944117207134, 46.8835958822567],
      [-96.78938300725677, 46.88366011348287],
      [-96.78937340712874, 46.88365726941483],
      [-96.78938698581446, 46.88367099495206],
      [-96.78936683517799, 46.88373841245323],
      [-96.7893033735152, 46.88372562441337],
      [-96.78922063997351, 46.88372008580434],
      [-96.78926709876086, 46.8837880660106],
      [-96.78940147492771, 46.88381851903187],
      [-96.78946862529519, 46.88389715047489],
      [-96.78955988581431, 46.88381076689591],
      [-96.78967354910029, 46.88379981144174],
      [-96.78967788534983, 46.88380634229711],
      [-96.78980082387092, 46.88381993612666],
      [-96.78988571606139, 46.88372218227548],
      [-96.78995865799635, 46.88373701325088],
      [-96.79005581150825, 46.88372277385403],
      [-96.79013260247643, 46.88369624936585],
      [-96.7901956216959, 46.8837315510619],
      [-96.79030821242154, 46.88376086279104],
      [-96.79035953611896, 46.88375557538515],
      [-96.79037396666655, 46.88375605117378],
      [-96.79044728215088, 46.88372660243736],
      [-96.79026928353156, 46.88385461380308],
      [-96.79023506714886, 46.883866878949],
      [-96.79014414438365, 46.88393094472141],
      [-96.79006176023893, 46.88390156259517],
      [-96.78995120511807, 46.88388979226053],
      [-96.78983038910258, 46.88396315202091],
      [-96.78973335208364, 46.88396885702429],
      [-96.78972661655969, 46.88401897384292],
      [-96.78972862697397, 46.88403817958787],
      [-96.78964694326817, 46.88412285277402],
      [-96.7899086144135, 46.88411567545887],
      [-96.78992461460253, 46.8841128861462],
      [-96.78999302138044, 46.88411279672825],
      [-96.79001129936574, 46.88410763819871],
      [-96.78998502718372, 46.8841441654205],
      [-96.7899218876369, 46.88416240543419],
      [-96.78991870434479, 46.88420715837137],
      [-96.79002239905819, 46.88421701190945],
      [-96.79005414556718, 46.88426476616258],
      [-96.79013763801579, 46.88424838642978],
      [-96.79017415615726, 46.88424725857585],
      [-96.79021463637064, 46.88430731147888],
      [-96.79016951786716, 46.88437894186865],
      [-96.79008087289264, 46.88439537195921],
      [-96.79004519022396, 46.88442103516427],
      [-96.79004676671366, 46.88448291468355],
      [-96.78999304023601, 46.88448455494543],
      [-96.79008929715393, 46.88452464336876],
      [-96.79004930499254, 46.88458110564218],
      [-96.7900380795772, 46.88461904691115],
      [-96.78998330981149, 46.88460271520834],
      [-96.7898260170362, 46.88459353133936],
      [-96.78976967832968, 46.88453012243812],
      [-96.78982759995046, 46.88451431200156],
      [-96.78969395065604, 46.88451465793484],
      [-96.78967306884384, 46.88444968651552],
      [-96.78971469513459, 46.88438735575244],
      [-96.7895645178804, 46.8844595290522],
      [-96.78945754787748, 46.88460554030436],
      [-96.78896515729998, 46.88443040392917],
      [-96.78883397725308, 46.88442659590584],
      [-96.78849987696874, 46.88455176842561],
      [-96.7884113498504, 46.88461644346579],
      [-96.78823724029073, 46.88448929772495],
      [-96.78823331970291, 46.88447730670181],
      [-96.78807965752056, 46.88452008310949],
      [-96.78803877001678, 46.88451393452824],
      [-96.78801100108521, 46.88451927822635],
      [-96.78795537710948, 46.88454347822158],
      [-96.7880337260288, 46.88457696002589],
      [-96.7878978077828, 46.88461762527611],
      [-96.78797422128059, 46.88444216948317],
      [-96.78792990599635, 46.88436899617302],
      [-96.78781674044993, 46.8843234726551],
      [-96.78777861208782, 46.88439684453148],
      [-96.78790334009732, 46.88416385263982],
      [-96.78787992413747, 46.88405340096941],
      [-96.78778944889261, 46.88405337630893],
      [-96.78877246665233, 46.8836760146609],
      [-96.78878387126844, 46.88366664244017],
      [-96.78888421798072, 46.88365237275674],
      [-96.78900105779529, 46.88360353819978],
      [-96.78913473984151, 46.88361071083741],
      [-96.78915566744298, 46.88362472229042],
      [-96.78923501315502, 46.88361797925183],
      [-96.7892838800096, 46.88358510132356],
      [-96.78926765663225, 46.8835027247297],
      [-96.78924353396631, 46.88346051671892],
      [-96.7892882805532, 46.88339752028328],
      [-96.78910404405299, 46.88345182599046],
      [-96.78904619857954, 46.88346160896376],
      [-96.7890549765417, 46.88348175489539],
      [-96.78901017649139, 46.88348054283342],
      [-96.78900640975087, 46.88346873627659],
      [-96.78888847391939, 46.8834906445115],
      [-96.78874024454674, 46.8835115601745],
      [-96.78868009971292, 46.88346709102964],
      [-96.78867867405717, 46.88345630115902],
      [-96.78862461182636, 46.88343959945618],
      [-96.78859242609704, 46.88342273946187],
      [-96.78852754071904, 46.88323719363526],
      [-96.78869963790086, 46.88311221483033],
      [-96.78876634156103, 46.88306566053229],
      [-96.78879597148469, 46.88308499981895],
      [-96.78887493611832, 46.88312361602725],
      [-96.78883710894505, 46.88300648418986],
      [-96.78886703133735, 46.88300019047439],
      [-96.78884066320995, 46.88297431900944],
      [-96.7888563553142, 46.8829632276447],
      [-96.7888025286797, 46.88297649688074],
      [-96.7887479858456, 46.88294921533645],
      [-96.78872537812163, 46.88298146360348],
      [-96.78868577117188, 46.88296799132656],
      [-96.78867054972199, 46.88302178226848],
      [-96.78860093589535, 46.88296470248191],
      [-96.78852852508928, 46.8829803686741],
      [-96.7885123279691, 46.88296921605733],
      [-96.7884885235296, 46.88302217667057],
      [-96.78842296996979, 46.88302200935557],
      [-96.78835046021845, 46.88294950407371],
      [-96.78827921147905, 46.88296023401155],
      [-96.7882637896838, 46.88295445299607],
      [-96.78827755692996, 46.88290144571253],
      [-96.78832230270925, 46.88285742927406],
      [-96.78843002877744, 46.88277282520815],
      [-96.78849865996736, 46.88279444992341],
      [-96.78851481767708, 46.88273578169841],
      [-96.78846591133443, 46.88270109558404],
      [-96.78850492861125, 46.88265164543453],
      [-96.788506331687, 46.88259000856116],
      [-96.78844825664726, 46.88249383096049],
      [-96.78841425986982, 46.88247076590445],
      [-96.78844238202647, 46.88241798694138],
      [-96.78847335264847, 46.8823916548394],
      [-96.78854891152285, 46.88238473813864],
      [-96.78856764695999, 46.88240970832517],
      [-96.78861587901298, 46.88232475369995],
      [-96.78871795304632, 46.88230923570707],
      [-96.78870216245944, 46.88221247965846],
      [-96.78870150162255, 46.88216078170558],
      [-96.78866665143292, 46.88216563576737],
      [-96.7886037660521, 46.88217268580452],
      [-96.7885809306192, 46.8820459782395],
      [-96.78863595063373, 46.88199948555829],
      [-96.78867931465035, 46.88202137040502],
      [-96.78879332924713, 46.8819317239518],
      [-96.78883698794758, 46.88194118986116],
      [-96.78889765366027, 46.88200491138688],
      [-96.78888627673265, 46.88207270145394],
      [-96.78905952351994, 46.88211598552775],
      [-96.78908962781536, 46.8821205058059],
      [-96.78906422423726, 46.88218768625267],
      [-96.78910003238315, 46.88221186823106],
      [-96.78911512983262, 46.88225154466426],
      [-96.78917096013544, 46.88229746846927],
      [-96.78923995997535, 46.88226491739641],
      [-96.78925786317292, 46.88231155568747],
      [-96.7892942865886, 46.88229976288958],
      [-96.78933916298217, 46.88235293247082],
      [-96.78928426333172, 46.8824188645507],
      [-96.78920356278714, 46.88243478376917],
      [-96.78913737305064, 46.88239201487568],
      [-96.78909026994747, 46.88249822646347],
      [-96.78904645830306, 46.88250977843011],
      [-96.7891482430601, 46.88253953266091],
      [-96.78921224713783, 46.88253773896292],
      [-96.78904302848215, 46.88261025182125],
      [-96.78883105012599, 46.88260877836746],
      [-96.78881735367908, 46.88255327191498],
      [-96.78874688429134, 46.88255488973442],
      [-96.78874508154105, 46.88255145527647],
      [-96.78869443903412, 46.88264206978752],
      [-96.78882366940825, 46.88269007346035],
      [-96.78875913593492, 46.88278156196019],
      [-96.78873298326775, 46.88281289221304],
      [-96.78873095233726, 46.88283131598714],
      [-96.78867000353638, 46.88282814497152],
      [-96.78863575341097, 46.88279953387307],
      [-96.78863566662463, 46.88274934029208],
      [-96.78834618216327, 46.88265379123953],
      [-96.78833881573699, 46.88266015540408],
      [-96.7881117908558, 46.88271777889178],
      [-96.78814479607907, 46.8827809737729],
      [-96.78829429290862, 46.88238441360466],
      [-96.78816208962382, 46.8823339406345],
      [-96.78810117606066, 46.88233115049113],
      [-96.78809786853863, 46.88227181526026],
      [-96.78819595959366, 46.88228044317526],
      [-96.78833874547018, 46.88222554173005],
      [-96.78832543801377, 46.88214005497367],
      [-96.78832233080566, 46.88206640492239],
      [-96.7884143733171, 46.88200086788234],
      [-96.78839962379195, 46.88193491629877],
      [-96.78839565343682, 46.8819149977762],
      [-96.78834262289539, 46.88188796219813],
      [-96.78833044102934, 46.88185557912178],
      [-96.78834276464167, 46.88181713698822],
      [-96.78834234599354, 46.8817735831214],
      [-96.78830896406583, 46.88175355397695],
      [-96.78828152812115, 46.88177740131733],
      [-96.78836860318513, 46.88172726694534],
      [-96.78841811809441, 46.88172118330233],
      [-96.78843683453627, 46.88169840978588],
      [-96.78839397982712, 46.8817625481159],
      [-96.78844751175257, 46.88184315842378],
      [-96.78852042318063, 46.88185358814265],
      [-96.78853004800057, 46.8818408032975],
      [-96.78856463497253, 46.88185435726196],
      [-96.78862395391121, 46.88183574684813],
      [-96.78870364584346, 46.88180948234661],
      [-96.7887495864994, 46.88175426607418],
      [-96.78870128552617, 46.88173897073303],
      [-96.78859846364537, 46.8817341011887],
      [-96.78852263452993, 46.88179138264038],
      [-96.7885330386839, 46.88180558979027],
      [-96.7885154117751, 46.88189679471164],
      [-96.78846540105877, 46.88193017734876],
      [-96.7883313361463, 46.88193231157871],
      [-96.78845950286019, 46.8820714803454],
      [-96.78847208973275, 46.88231670233324],
      [-96.78869179722447, 46.88245300233725],
      [-96.78890677686996, 46.88247603865648],
      [-96.789007678441, 46.88229788587428],
      [-96.78897264949381, 46.88222496473875],
      [-96.78922961555915, 46.88216254495792],
      [-96.78921868752218, 46.88213593401864],
      [-96.78927124677648, 46.88215122671252],
      [-96.78930277450458, 46.8820436427944],
      [-96.78930474563374, 46.88199550473324],
      [-96.7892389587686, 46.88198345244256],
      [-96.78922955614507, 46.88200980854421],
      [-96.78924326070386, 46.88190746980923],
      [-96.78927134378041, 46.8819105652586],
      [-96.78927568190097, 46.88190643624562],
      [-96.78930778303355, 46.88189180166101],
      [-96.78931062893486, 46.88186902535869],
      [-96.78935926971855, 46.88186745742506],
      [-96.7893602455446, 46.88186298404539],
      [-96.78935660971263, 46.88189746394496],
      [-96.78937256896845, 46.88195427711116],
      [-96.7894478894823, 46.88194914921954],
      [-96.78949993273129, 46.88196072782336],
      [-96.78948097417921, 46.88187443045535],
      [-96.78958398061474, 46.8818331350967],
      [-96.78957825312922, 46.88182459396779],
      [-96.78961538824615, 46.88184217164089],
      [-96.78953797112385, 46.88180157683232],
      [-96.78942429523367, 46.88173425538827],
      [-96.78941775953197, 46.88170077282066],
      [-96.7894437313718, 46.88164812589053],
      [-96.7894716594502, 46.88158878258217],
      [-96.78954779603366, 46.88164160715236],
      [-96.7896898091388, 46.88163406096556],
      [-96.78965093287799, 46.88157093661882],
      [-96.78979815852826, 46.88151198953678],
      [-96.78993932252037, 46.88160996053803],
      [-96.78998513243931, 46.88172525508196],
      [-96.7900360750977, 46.88180865974417],
      [-96.78998542053087, 46.88193423858778],
      [-96.79003084002456, 46.88198795217438],
      [-96.78989809879756, 46.88197360370527],
      [-96.78986944842899, 46.88197101083322],
      [-96.78983858617352, 46.88192322372781],
      [-96.7898546053928, 46.88190807361557],
      [-96.78978060980943, 46.88183360064989],
      [-96.78984477252916, 46.88174610108821],
      [-96.78968675584049, 46.88193714036895],
      [-96.78972073950032, 46.88198810823697],
      [-96.78982277405899, 46.88202819031436],
      [-96.78984437388687, 46.88205260895088],
      [-96.78991954520569, 46.88208022590747],
      [-96.78987235499368, 46.88212614757301],
      [-96.7900183329534, 46.88211375604962],
      [-96.7897786376221, 46.88220861118898],
      [-96.78971814848092, 46.88220319108101],
      [-96.78971008123263, 46.88222720351847],
      [-96.78967193376349, 46.88221339578206],
      [-96.78958125996465, 46.88226701917744],
      [-96.78972640677092, 46.88231832491808],
      [-96.78969792754643, 46.88235739449868],
      [-96.78945597603847, 46.88237916375299],
      [-96.78947572172396, 46.88213900424283],
      [-96.78945793902845, 46.88212172482867],
      [-96.78948876071722, 46.88209529543542],
      [-96.78957478119302, 46.88203454617258],
      [-96.78966233438148, 46.88211808748726],
      [-96.78920275096316, 46.88188615861287],
      [-96.78909188733317, 46.88177770975812],
      [-96.7891677243264, 46.88172780005217],
      [-96.78909496629151, 46.88169091943752],
      [-96.7890334381183, 46.88165987558741],
      [-96.78900249045444, 46.88170396934259],
      [-96.78897057735487, 46.88170271224618],
      [-96.78893206860904, 46.88179126570557],
      [-96.7889741544791, 46.88180642552012],
      [-96.78894027288912, 46.88183906846638],
      [-96.78881675524065, 46.88181251319177],
      [-96.7887956959873, 46.88184554635381],
      [-96.7888349386947, 46.88175636615193],
      [-96.78907161834601, 46.88159571815603],
      [-96.78913649877087, 46.8815243939314],
      [-96.78926970494237, 46.88163235168341],
      [-96.78927812968806, 46.88162795026509],
      [-96.78925272347065, 46.8816446648412],
      [-96.7890377587124, 46.88195877883975],
      [-96.78799086746555, 46.88202017301856],
      [-96.78785726355098, 46.8820619585585],
      [-96.78785799252655, 46.88208130473973],
      [-96.78779173466278, 46.88213760825507],
      [-96.78779620492614, 46.88172495122165],
      [-96.78783254435012, 46.88131182814259],
      [-96.78780438022508, 46.88130394149692],
      [-96.79043260668976, 46.88127471469538],
      [-96.79061521373373, 46.88126821444411],
      [-96.79067769511403, 46.88130171468535],
      [-96.79065432230641, 46.88134220081885],
      [-96.79071483446168, 46.88133860430573],
      [-96.79084682802267, 46.88134096926143],
      [-96.79100462032096, 46.88138372208443],
      [-96.79106699692996, 46.88135712367164],
      [-96.7910692313228, 46.88133539769986],
      [-96.7911175924721, 46.88136594489467],
      [-96.79103620619156, 46.88127470730957],
      [-96.79098759403524, 46.88124819745318],
      [-96.79099344119398, 46.88144480300852],
      [-96.79094183768397, 46.88151049907473],
      [-96.79090088263159, 46.88158445100968],
      [-96.79083308877432, 46.88157113321033],
      [-96.79081471327552, 46.88152440248425],
      [-96.79076060853457, 46.88154059199073],
      [-96.79068327183678, 46.88149339944483],
      [-96.79069507502084, 46.88145899185956],
      [-96.79063497665125, 46.88141135019776],
      [-96.79058425304255, 46.88135790999542],
      [-96.79053130364123, 46.88149040239852],
      [-96.79047674243817, 46.88148656457852],
      [-96.79042273936216, 46.88153580000498],
      [-96.79064285344474, 46.88151623637893],
      [-96.79062223881353, 46.88160261458733],
      [-96.79067058530848, 46.88168805064183],
      [-96.79071125157844, 46.88169118343956],
      [-96.79078933981567, 46.88169639109122],
      [-96.79084205271367, 46.88172312948411],
      [-96.79091747384123, 46.88164553216128],
      [-96.7910854003727, 46.88155346795723],
      [-96.79097074197853, 46.88180065392282],
      [-96.79111123203975, 46.88183032460387],
      [-96.79114682372337, 46.88181599333435],
      [-96.7911392980623, 46.88187505434442],
      [-96.79113400392939, 46.88189945946662],
      [-96.7910668599032, 46.88189046116175],
      [-96.79096585864362, 46.88193795506006],
      [-96.79096714814582, 46.8819453536797],
      [-96.79091016618771, 46.88196549411149],
      [-96.79082474185442, 46.88198646302546],
      [-96.79081388106674, 46.88199837319047],
      [-96.7907888305565, 46.88197581885391],
      [-96.79073712153999, 46.88207188901023],
      [-96.79064690113421, 46.88201065320922],
      [-96.79054714606902, 46.88205182849852],
      [-96.7904721195058, 46.88200026274355],
      [-96.79041126530954, 46.88196051136346],
      [-96.7905726161085, 46.881947139181],
      [-96.79062339288429, 46.88187387008593],
      [-96.79060258128175, 46.8817956390463],
      [-96.79052981961651, 46.88175329365841],
      [-96.79072023059727, 46.88180761301395],
      [-96.79080067865574, 46.88181606226127],
      [-96.7908348595852, 46.88186650502507],
      [-96.79100403069263, 46.88199816434119],
      [-96.7910624786417, 46.88204268405923],
      [-96.79107665257955, 46.88203631512068],
      [-96.7909478643256, 46.88210442370277],
      [-96.7908770570829, 46.88209233353659],
      [-96.79084166408234, 46.88212905567025],
      [-96.79082362268576, 46.88218066812064],
      [-96.79076449627497, 46.88217811889487],
      [-96.79076840419931, 46.88220073046676],
      [-96.7908253226186, 46.88224838557863],
      [-96.7908374171445, 46.88225812307306],
      [-96.79103053293689, 46.88228030165165],
      [-96.79107230729277, 46.88232021600837],
      [-96.7910398003915, 46.88235952871995],
      [-96.79106325443443, 46.88222991736411],
      [-96.79114776645321, 46.8822083774547],
      [-96.7908057499727, 46.88239100948794],
      [-96.79068281604988, 46.88241724412352],
      [-96.79059018590411, 46.88240000934705],
      [-96.79054077456725, 46.88248731828759],
      [-96.79053484251757, 46.8824987243191],
      [-96.79052673132237, 46.88251273935921],
      [-96.79055214440174, 46.88254073818074],
      [-96.79070802509057, 46.88250804367042],
      [-96.79070467176618, 46.88249069043271],
      [-96.79073617577991, 46.88247231760891],
      [-96.79054263686989, 46.8828823841522],
      [-96.79047483118134, 46.8828667393657],
      [-96.79048641078693, 46.88293242998422],
      [-96.79051884998246, 46.88303167935919],
      [-96.7903640777115, 46.88303056482432],
      [-96.79026821024064, 46.88307846860201],
      [-96.79026110536229, 46.88310302631645],
      [-96.79037545294754, 46.88314768080748],
      [-96.79056992986388, 46.8832173982961],
      [-96.79063945353451, 46.88319404284391],
      [-96.79064278510268, 46.8832374924197],
      [-96.79070431626523, 46.8832158061606],
      [-96.79081491673685, 46.88322769951181],
      [-96.79077232668318, 46.88335582415107],
      [-96.79052307767573, 46.88335200049872],
      [-96.79052499425715, 46.88340445832704],
      [-96.79043691659497, 46.88339048675501],
      [-96.79009472796622, 46.8836153058973],
      [-96.79003694100213, 46.88378954201662],
      [-96.78977680111404, 46.88367769193137],
      [-96.78975142131176, 46.88365518528815],
      [-96.78969573485327, 46.88352470729416],
      [-96.78948818041367, 46.88348096224553],
      [-96.7894526852098, 46.88372917953969],
      [-96.78988715020489, 46.88404279644341],
      [-96.79017581414216, 46.88406286371652],
      [-96.79023921599337, 46.88408099096741],
      [-96.79022915563613, 46.88399632982084],
      [-96.79017590220678, 46.88400428597047],
      [-96.79038191630296, 46.88411230940449],
      [-96.79036759059947, 46.88416393416888],
      [-96.79038987237905, 46.88422458131496],
      [-96.79052533972695, 46.88412375959086],
      [-96.79057812083605, 46.88406459218958],
      [-96.79062331181974, 46.88401949780466],
      [-96.79068882801675, 46.88408332384221],
      [-96.79076911805896, 46.88404194620849],
      [-96.79082231831528, 46.88400435862188],
      [-96.79073090064703, 46.88394479099292],
      [-96.79064009585802, 46.88393730109568],
      [-96.79045274937727, 46.88395725651652],
      [-96.79071006742039, 46.88418210474607],
      [-96.79084371255735, 46.8841564363127],
      [-96.79089450653726, 46.88416427941856],
      [-96.79099097311477, 46.88415991419085],
      [-96.79108721420789, 46.88416345483997],
      [-96.79108205403605, 46.88412919535921],
      [-96.79103527715067, 46.88410060762351],
      [-96.79106823130691, 46.88397547624914],
      [-96.79092700143913, 46.88392786929104],
      [-96.7910085596045, 46.88384853672028],
      [-96.79095018345987, 46.8838128701807],
      [-96.79091074099428, 46.88378019380311],
      [-96.79087262790556, 46.88378751711415],
      [-96.79083117086576, 46.88375094900758],
      [-96.79075534833369, 46.88375181249246],
      [-96.79099154671776, 46.88368708925328],
      [-96.79103631122044, 46.88366073414668],
      [-96.79106746456281, 46.88367805860149],
      [-96.79108104199992, 46.88372338026824],
      [-96.7911568128565, 46.88376560764851],
      [-96.79113401131784, 46.88378127066445],
      [-96.79116669340947, 46.8838684558241],
      [-96.79109683263339, 46.88362538730836],
      [-96.79093364280558, 46.88351528422725],
      [-96.79086867968785, 46.8842364038124],
      [-96.790846444963, 46.884273211215],
      [-96.79078851405438, 46.88435223507779],
      [-96.79068449152172, 46.88437927847013],
      [-96.79060103169427, 46.88432038948113],
      [-96.7905647787573, 46.8843861354752],
      [-96.79062434978496, 46.88446806392465],
      [-96.79051138988024, 46.88449041240278],
      [-96.79039517555793, 46.88440822204506],
      [-96.79035505095246, 46.88440218969108],
      [-96.79019978415674, 46.88450365287912],
      [-96.78999407594422, 46.88436368350432],
      [-96.79059708239836, 46.88459437582467],
      [-96.79068722379232, 46.88459925009951],
      [-96.79072467747606, 46.88458485520055],
      [-96.79075586957502, 46.88447047819902],
      [-96.7907814690411, 46.88446964656655],
      [-96.79087682069049, 46.88444505375544],
      [-96.79092790340279, 46.88441763788987],
      [-96.79097534475721, 46.88436826241633],
      [-96.79099478663458, 46.88433410352709],
      [-96.79107684115397, 46.88439836582815],
      [-96.79116995875928, 46.88436518710185],
      [-96.79115529005409, 46.88426888013355],
      [-96.7910822847966, 46.88423766954755],
      [-96.79094585792448, 46.88448087120463],
      [-96.79115165980723, 46.88454105265172],
      [-96.78994205970633, 46.88324388005674],
      [-96.78996177113345, 46.88322114877956],
      [-96.78993351805092, 46.88318825529276],
      [-96.7899554440158, 46.88313833181617],
      [-96.79002151195533, 46.88312430852399],
      [-96.79001566422409, 46.88310931785491],
      [-96.79033172503846, 46.88291131999132],
      [-96.79036313189394, 46.88287086337867],
      [-96.78949082836247, 46.88300782200317],
      [-96.78946664027124, 46.88285063021649],
      [-96.78913955912293, 46.88314340595631],
      [-96.7891043774333, 46.88316130139328],
      [-96.78915880808424, 46.88318143243362],
      [-96.78905978302141, 46.88313167526348],
      [-96.78884302648456, 46.88324905335608],
      [-96.78894137894115, 46.8833619386542],
      [-96.78898633982973, 46.88337211201815],
      [-96.78901951775482, 46.88334236266301],
    ],
  },
};
