import React, { Component } from "react";
import { Modal, Button, Checkbox } from "antd";
import { formatNumberToPrecision } from "utils/numberFormatting";
import { fillEmptyWithHyphen } from "utils/stringFormatting";

const additionalColumnOptions = [
  { id: 1, title: "ID", dataIndex: "id" },
  { id: 2, title: "Site User ID", dataIndex: "site_user_id" },
  { id: 3, title: "Type", dataIndex: "type" },
  { id: 4, title: "Personnel", dataIndex: "site_user_name" },
  {
    id: 5,
    title: "Regimen",
    dataIndex: "regimen_name",
    render: fillEmptyWithHyphen,
  },
  {
    id: 6,
    title: "Origin Code",
    dataIndex: "origin_code",
    render: fillEmptyWithHyphen,
  },
  {
    id: 7,
    title: "Special Pen Code",
    dataIndex: "special_pen_code",
    render: fillEmptyWithHyphen,
  },
  {
    id: 8,
    title: "From Pen Code",
    dataIndex: "from_pen_code",
    render: fillEmptyWithHyphen,
  },
  {
    id: 9,
    title: "To Pen Code",
    dataIndex: "to_pen_code",
    render: fillEmptyWithHyphen,
  },
  {
    id: 10,
    title: "Implant Status Code",
    dataIndex: "implant_status_code",
    render: fillEmptyWithHyphen,
  },
  {
    id: 11,
    title: "EID",
    dataIndex: "eid",
    render: fillEmptyWithHyphen,
  },
  {
    id: 12,
    title: "Alt Tag",
    dataIndex: "alt_tag",
    render: fillEmptyWithHyphen,
  },
  {
    id: 13,
    title: "Description",
    dataIndex: "description",
    render: fillEmptyWithHyphen,
  },
  {
    id: 14,
    title: "Weight",
    dataIndex: "weight",
    render: (weight) => formatNumberToPrecision(weight),
  },
  {
    id: 15,
    title: "Temperature",
    dataIndex: "temperature",
    render: (temp) => formatNumberToPrecision(temp),
  },
  {
    id: 16,
    title: "Lung Score",
    dataIndex: "lung_score",
    render: (lungScore) => formatNumberToPrecision(lungScore),
  },
  {
    id: 17,
    title: "Severity",
    dataIndex: "severity",
    render: fillEmptyWithHyphen,
  },
  {
    id: 18,
    title: "Comments",
    dataIndex: "comments",
    render: fillEmptyWithHyphen,
  },
  { id: 19, title: "Multi Day", dataIndex: "multi_day" },
  { id: 20, title: "Scheduled", dataIndex: "scheduled" },
  {
    id: 21,
    title: "Load",
    dataIndex: "load",
    render: fillEmptyWithHyphen,
  },
  { id: 22, title: "Pulls", dataIndex: "pulls" },
  {
    id: 23,
    title: "Buyer",
    dataIndex: "buyer",
    render: fillEmptyWithHyphen,
  },
  {
    id: 24,
    title: "Class",
    dataIndex: "class",
    render: fillEmptyWithHyphen,
  },
  {
    id: 25,
    title: "Weight Gained",
    dataIndex: "weight_gained",
    render: (weightGained) => formatNumberToPrecision(weightGained),
  },
  {
    id: 26,
    title: "Risk",
    dataIndex: "risk",
    render: fillEmptyWithHyphen,
  },
  {
    id: 27,
    title: "Site Response",
    dataIndex: "site_response",
    render: fillEmptyWithHyphen,
  },
  {
    id: 28,
    title: "Deleted At",
    dataIndex: "deleted_at",
    render: fillEmptyWithHyphen,
  },
  { id: 29, title: "Created At", dataIndex: "created_at" },
  { id: 30, title: "Updated At", dataIndex: "updated_at" },
];

class ColumnSelector extends Component {
  state = { columnSettingsVisible: false };

  showColumnModal = () => {
    this.setState({
      columnSettingsVisible: true,
    });
  };

  handleOkColumnModal = () => {
    this.props.handleOkColumnModal();
    this.setState({
      columnSettingsVisible: false,
    });
  };

  handleCancelColumnModal = () => {
    this.props.handleCancelColumnModal();
    this.setState({
      columnSettingsVisible: false,
    });
  };

  render() {
    return (
      <div>
        <u
          onClick={this.showColumnModal}
          style={{
            cursor: "pointer",
            color: "#00C9B0",
            fontWeight: "bold",
            marginLeft: "15px",
            marginTop: "15px",
          }}
        >
          Edit Table Columns
        </u>
        <Modal
          width="320px"
          title="Column Settings"
          visible={this.state.columnSettingsVisible}
          onCancel={this.handleCancelColumnModal}
          footer={[
            <Button key="Cancel" onClick={this.handleCancelColumnModal}>
              Cancel
            </Button>,
            <Button
              key="Apply"
              type="primary"
              onClick={this.handleOkColumnModal}
            >
              Save
            </Button>,
          ]}
        >
          <div
            style={{
              width: "284px",
              height: "50vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {additionalColumnOptions.map((column, index) => {
              const isChecked =
                this.props.columnSelectorModal.findIndex(
                  (element) => element.dataIndex === column.dataIndex
                ) !== -1;
              return (
                <div
                  key={index}
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  <Checkbox
                    onChange={(e) =>
                      this.props.addOrRemoveColumn(column, e.target.checked)
                    }
                    defaultChecked={isChecked}
                    checked={isChecked}
                  >
                    {column.title}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </Modal>
      </div>
    );
  }
}
export default ColumnSelector;
