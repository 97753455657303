import React, { Component } from "react";
import axios from "axios";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Table } from "antd";
import errorMessage from "utils/errorMessage";
import "url-search-params-polyfill";

class SiteUserList extends Component {
  state = {
    currentPage: 1,
    total: 0,
    siteUsers: [],
    sortType: "asc",
    sortField: "description",
    loading: true,
  };

  componentDidMount = () => {
    this.getTheData();
  };

  getTheData = (page = 1) => {
    const url = this.buildRequestURL(page);
    axios.get(url).then(
      (response) => {
        const siteUsers = response.data.data;
        const currentPage = response.data.current_page;
        const total = response.data.total;
        this.setState({ siteUsers, currentPage, total, loading: false });
      },
      (error) => {
        errorMessage(error);
        this.setState({ loading: false });
      }
    );
  };

  buildRequestURL = (page) => {
    const params = new URLSearchParams(window.location.search);
    const siteAdminQueryParameters = params ? "&" + params : "";
    return (
      "/admin-cms/users-list?page=" +
      page +
      "&sortBy=" +
      this.state.sortField +
      "&order=" +
      this.state.sortType +
      "&site=" +
      this.props.site.id +
      siteAdminQueryParameters
    );
  };

  toggleSortType = () => {
    if (this.state.sortType === "asc") {
      this.setState({ sortType: "desc" }, this.getTheData);
    } else {
      this.setState({ sortType: "asc" }, this.getTheData);
    }
  };

  render = () => {
    const urlParams = window.location.search ? window.location.search : "";
    const columns = [
      {
        title: (
          <span
            className={`order-sorter ${this.state.sortType || ""}`}
            onClick={this.toggleSortType}
          >
            User{" "}
            <div className="icon-container">
              <CaretUpOutlined /> <CaretDownOutlined />
            </div>
          </span>
        ),
        dataIndex: "description",
      },
      {
        title: "Phone Number",
        dataIndex: "phone",
      },
      {
        title: "Last Login",
        dataIndex: "last_login",
      },
      {
        title: "Specs",
        dataIndex: "specs",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "",
        dataIndex: "id",
        render: (id) => (
          <a href={`/admin-cms/site-user/${id}${urlParams}`}>Edit</a>
        ),
      },
    ];

    const pagination = {
      onChange: this.getTheData,
      current: this.state.currentPage,
      total: this.state.total,
      pageSize: 12,
      hideOnSinglePage: true,
      showSizeChanger: false,
    };

    return (
      <Table
        columns={columns}
        dataSource={this.state.siteUsers}
        loading={this.state.loading}
        className={"custom-table"}
        rowKey={"id"}
        pagination={pagination}
      />
    );
  };
}

export default SiteUserList;
