const coordinates = [
  {
    latitude: 46.8772,
    longitude: -96.7898,
  },
  {
    latitude: 47.8772,
    longitude: -97.7898,
  },
  {
    latitude: 48.8772,
    longitude: -98.7898,
  },
  {
    latitude: 49.8772,
    longitude: -99.7898,
  },
  {
    latitude: 46.8772,
    longitude: -96.7898,
  },
];

export const penCoordinates: Array<PenAndCoordinateElement> = [
  {
    pen_name: "Pen 1",
    coordinates: coordinates,
  },
  {
    pen_name: "Pen 2",
    coordinates: coordinates,
  },
  {
    pen_name: "Pen 3",
    coordinates: coordinates,
  },
  {
    pen_name: "Pen 4",
    coordinates: coordinates,
  },
];
