import React from "react";
import { Form } from "antd";
import ActiveSelect from "components/common/ActiveSelect";

const FormItem = Form.Item;

export default function ActiveFacilityToggle(props) {
  return (
    <div className="row" key={props.site.id}>
      <div className="col-xs-6">
        <FormItem label={"Facility"} colon={false}>
          <span style={{ fontWeight: 500 }}> {props.site.name}</span>
        </FormItem>
      </div>
      <div className="col-xs-6">
        <FormItem label={"Status"} colon={false}>
          <ActiveSelect
            onChange={(status) => props.toggleFacility(props.site.id, status)}
            isActive={Boolean(props.site.is_active)}
          />
        </FormItem>
      </div>
    </div>
  );
}
