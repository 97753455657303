import React, { Component } from "react";
import axios from "axios";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";

import debounce from "lodash/debounce";
import errorMessage from "utils/errorMessage";

class SiteAdminHeader extends Component {
  state = {
    disableSync: false,
  };

  forceSync = debounce(() => {
    const { site } = this.props;
    axios.put(`/cms/sites/${site.id}/force-sync`).then(
      () => {
        this.setState({ disableSync: false }, () =>
          notification.success({
            message: "Success",
            description: `${site.name} Forced Sync Successfully. The site will start the sync within the minute.`,
          })
        );
      },
      (error) =>
        this.setState({ disableSync: false }, () => errorMessage(error))
    );
  }, 1000);

  handleForceSync = () => {
    this.setState({ disableSync: true }, this.forceSync);
  };

  render = () => {
    const { site } = this.props;

    return (
      <div
        className={"row details-page-top-bar"}
        style={{ marginLeft: 10, marginRight: 10, paddingTop: "4px" }}
      >
        <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
          <span className={"font-bold"}>Territory:</span>&nbsp;
          <span className={"truncate-text"}>{site.territory || ""}</span>
        </div>

        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {site.is_active ? (
            <span className={"margin-right-20"}>
              <CheckCircleOutlined /> Site Active
            </span>
          ) : (
            <span className={"margin-right-20 text-muted"}>
              Site Deactivated
            </span>
          )}
          <span className={"margin-right-20"}>
            {site.last_sync_date ? (
              <React.Fragment>
                <CheckCircleOutlined />
                &nbsp;Last sync at {site.last_sync_date} CST
              </React.Fragment>
            ) : (
              "Site has not yet synced"
            )}
          </span>

          {site.is_active && (
            <Button
              type="primary"
              className="small-button"
              disabled={this.state.disableSync}
              onClick={this.handleForceSync}
            >
              Force Sync
            </Button>
          )}
        </div>
      </div>
    );
  };
}

export default SiteAdminHeader;
