import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Menu, Dropdown } from "antd";
import axios from "axios";
import errorMessage from "utils/errorMessage";

export default function ProfileDropdown(props) {
  const logout = () =>
    axios.post("/logout").then(
      () => (window.location = "/login"),
      (error) => errorMessage(error)
    );

  const menu = (
    <Menu>
      <Menu.Item key="edit-link">
        <a href="/profile">Edit Profile</a>
      </Menu.Item>
      <Menu.Item key="logout-btn">
        <span onClick={logout}>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={["click"]}>
        <a className="ant-dropdown-link">
          {props.userName}
          <DownOutlined style={{ marginLeft: "5px" }} />
        </a>
      </Dropdown>
    </div>
  );
}
