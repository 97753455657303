import axios from "axios";

import { ApiResponse, BaseService } from "services/BaseService";
import {
  BASE_PATH,
  UPLOAD_KML_FILE_PATH,
  VALIDATE_KML_FILE_PATH,
} from "services/constants";
import { penCoordinates } from "__fixtures__/penCoordinates";

export class KmlUploadService extends BaseService {
  public static validateKmlFile(
    siteId: number,
    kmlFile: File
  ): ApiResponse<PenAndCoordinateElement[]> {
    if (BaseService.getUseMock()) {
      return BaseService.mockData({
        data: penCoordinates,
      });
    } else {
      return axios.post(
        `${BASE_PATH}/sites/${siteId}${VALIDATE_KML_FILE_PATH}`,
        this.transformFile(kmlFile)
      );
    }
  }

  public static uploadKmlFile(
    siteId: number,
    kmlFile: File
  ): ApiResponse<void> {
    return axios.post(
      `${BASE_PATH}/sites/${siteId}${UPLOAD_KML_FILE_PATH}`,
      this.transformFile(kmlFile)
    );
  }

  private static transformFile(kmlFile: File): FormData {
    const formattedFile = new FormData();
    formattedFile.append("pen_coords_file", kmlFile);
    return formattedFile;
  }
}
