import { notification } from "antd";
import get from "lodash/get";

export default function errorMessage(error) {
  if (!error) {
    // eslint-disable-next-line no-console
    console.warn("You must return a valid error object to parse");
  }
  const { errorData, statusCode, validationMessagesWithKey } =
    parseError(error);

  if (!statusCode) {
    // eslint-disable-next-line no-console
    console.warn(
      `There was an issue parsing the data given. Status Code of ${statusCode} is invalid.`
    );
  }
  if (statusCode === 422) {
    validationMessagesWithKey.forEach((error) =>
      notification.error({ message: "Issue with Form", description: error })
    );
  } else if (statusCode === 401 || statusCode === 403) {
    notification.error({
      message: "Issue from Server",
      description: "You are not authorized to make this request.",
    });
  } else {
    const message = get(errorData, "message");
    notification.error({
      message: "Issue from Server",
      description: message
        ? message
        : "There was an issue on the server. Please try again.",
    });
  }
}

export const parseError = (error) => {
  const errorData = get(error, "response.data.error");
  const statusCode = get(error, "response.status");
  const validationMsgs = get(errorData, "validation_messages");
  const validationMessages = validationMsgs
    ? Object.values(validationMsgs)
    : null;
  const validationMessagesWithKey = validationMsgs
    ? Object.entries(validationMsgs).map(([key, value]) => `${key}:${value}`)
    : null;
  return {
    errorData,
    statusCode,
    validationMessagesWithKey,
    validationMessages,
  };
};
