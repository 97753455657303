import { BASE_PATH_ADMIN_CMS } from "services/constants";
import { AxiosPromise } from "axios";

export type ApiResponse<T> = AxiosPromise<BaseApiResponse<T>>;
export type PaginatedApiResponse<T> = AxiosPromise<
  BaseApiResponse<T> & { metadata: PaginationMetadata }
>;
export type LegacyPaginatedApiResponse<T> = AxiosPromise<
  BaseApiResponse<T> & PaginationMetadata
>;

export class BaseService {
  protected static buildRequestURL(
    searchParams: Record<string, string | number | undefined>,
    path: `/${string}`,
    basePath?: string
  ): string {
    const params = new URLSearchParams();
    Object.entries(searchParams).forEach(
      ([key, value]) =>
        value !== undefined && value !== "" && params.set(key, `${value}`)
    );
    return `${basePath ?? BASE_PATH_ADMIN_CMS}${path}?${params.toString()}`;
  }

  protected static mockData<T>(data: BaseApiResponse<T>): ApiResponse<T> {
    return new Promise((resolve) => {
      resolve({
        data,
        status: 200,
        statusText: "OK",
        config: {
          headers: undefined,
        },
        headers: {},
      });
    });
  }

  protected static paginatedMockData<T>(
    data: BaseApiResponse<T> & { metadata: PaginationMetadata }
  ): PaginatedApiResponse<T> {
    return new Promise((resolve) => {
      resolve({
        data,
        status: 200,
        statusText: "OK",
        config: {
          headers: undefined,
        },
        headers: {},
      });
    });
  }

  public static getUseMock(): boolean {
    let useMock = false;
    try {
      useMock = process.env.RETURN_MOCK_API_RESPONSES === "true";
    } catch (error) {
      console.error("Unable to find RETURN_MOCK_API_RESPONSES env variable");
    }
    return useMock;
  }
}
