import React, { Component } from "react";

import { Form, Input, Button, Select, Divider } from "antd";
import errorMessage from "utils/errorMessage";

import axios from "axios";
import FormHeading from "components/common/FormHeading";

const Option = Select.Option;
const FormItem = Form.Item;

class RegistrationForm extends Component {
  state = {
    user: this.props.user,
  };

  formRef = React.createRef();

  handleSubmit = () => {
    const { token } = this.props;
    const form = this.formRef.current;

    form.validateFields().then((values) => {
      values.invitation_token = token;

      axios.post(`/cms/register-admin`, values).then(
        function () {
          window.location = "/home";
        },
        function (error) {
          errorMessage(error);
        }
      );
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"]);
    }
    callback();
  };

  render = () => {
    const { user } = this.state;
    const userTerritories = user.territories
      ? user.territories.map((t) => t.id.toString())
      : [];

    return (
      <Form
        ref={this.formRef}
        className="ahi-form"
        hideRequiredMark={true}
        autoComplete={"off"}
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone_number: user.phone_number,
          territories: userTerritories,
        }}
      >
        <FormHeading headingText={"Registration"} />
        <Divider />
        <div className="row">
          <div className="col-xs-6">
            <FormItem
              label={"First Name"}
              colon={false}
              name="first_name"
              rules={[{ required: true, message: "Please input a first name" }]}
            >
              <Input placeholder="First Name" autoComplete={"new_first_name"} />
            </FormItem>
          </div>
          <div className="col-xs-6">
            <FormItem
              label={"Last Name"}
              colon={false}
              name="last_name"
              rules={[{ required: true, message: "Please input a last name" }]}
            >
              <Input placeholder="Last Name" autoComplete={"new_last_name"} />
            </FormItem>
          </div>
        </div>

        <FormItem
          label={"Email Address"}
          colon={false}
          name="email"
          rules={[{ required: true, message: "Please input an email address" }]}
        >
          <Input
            type="email"
            placeholder="Email Address"
            autoComplete={"registration_email"}
          />
        </FormItem>

        <FormItem
          label={"Phone Number"}
          colon={false}
          name="phone_number"
          rules={[{ required: false, message: "Please input a phone number" }]}
        >
          <Input type="phone" placeholder="Phone Number" />
        </FormItem>

        <FormItem
          label={"PASSWORD"}
          colon={false}
          name="password"
          rules={[
            { required: true, message: "Please input a password" },
            { validator: this.validateToNextPassword },
          ]}
        >
          <Input
            type="password"
            placeholder="Password"
            autoComplete="new-password"
          />
        </FormItem>

        <FormItem
          label={"CONFIRM PASSWORD"}
          colon={false}
          name="confirm_password"
          rules={[
            { required: true, message: "Please confirm your password" },
            { validator: this.compareToFirstPassword },
          ]}
        >
          <Input
            type="password"
            placeholder="Confirm Password"
            autoComplete="confirm-password"
          />
        </FormItem>

        {user.role_type_slug === "turn_key_admin" && (
          <FormItem
            label={"Facility Territories"}
            colon={false}
            name="territories"
            rules={[{ required: true, message: "Territories" }]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add Territories"
            >
              {this.props.territories.map((territory) => (
                <Option
                  key={territory.id}
                  value={territory.id.toString()}
                  name={territory.name}
                >
                  {territory.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        )}

        <Divider />

        <FormItem className={"ahi-form-bottom-button-wrap"}>
          <Button type="primary" onClick={this.handleSubmit} htmlType="submit">
            Save
          </Button>
        </FormItem>
      </Form>
    );
  };
}

export default RegistrationForm;
