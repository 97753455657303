import axios from "axios";
import { Moment } from "moment";

import {
  ApiResponse,
  BaseService,
  LegacyPaginatedApiResponse,
  PaginatedApiResponse,
} from "services/BaseService";
import {
  BASE_PATH,
  DEAD_ANIMALS_PATH,
  PENDING_REGIMENS_PATH,
  PEN_PATH,
  SESSION_PATH,
  SITES_PATH,
  SITE_SYNCS_PATH,
  SITE_USERS_PATH,
} from "services/constants";
import { PenCoordinatesAndCenterpoint } from "types/common/geoLocationData";
import { mockReturnPenAndCenterpointData } from "__fixtures__/googleMaps";
import { sampleMetadata, sampleSessionsList } from "__fixtures__/sessions";
import { siteUserFilterOptions } from "__fixtures__/siteUsers";

export class SiteService extends BaseService {
  public static getSiteList({
    page,
    moduleTypes,
    name,
    order,
    sortBy,
    syncStatus,
    territory,
    userTerritory,
  }: {
    page: number;
    moduleTypes: string;
    name: string;
    order: string;
    sortBy: string;
    syncStatus: string;
    territory: string;
    userTerritory: number;
  }): ApiResponse<PenCoordinatesAndCenterpoint[]> {
    if (BaseService.getUseMock()) {
      return BaseService.mockData({
        data: mockReturnPenAndCenterpointData.data,
      });
    } else {
      return axios.get(
        BaseService.buildRequestURL(
          {
            page,
            moduleTypes,
            name,
            order,
            sortBy,
            syncStatus,
            territory,
            userTerritory,
          },
          SITES_PATH,
          BASE_PATH
        )
      );
    }
  }

  public static getPenDetails(
    siteId: number,
    siteAdminId?: number
  ): ApiResponse<PenCoordinatesAndCenterpoint[]> {
    if (BaseService.getUseMock()) {
      return BaseService.mockData({
        data: mockReturnPenAndCenterpointData.data,
      });
    } else {
      return axios.get(
        BaseService.buildRequestURL(
          {
            site_id: siteId,
            site_admin_id: siteAdminId,
          },
          PEN_PATH
        )
      );
    }
  }

  public static getSessionList({
    page,
    pageSize = 12,
    siteId,
    siteUser,
    dateStart,
    dateEnd,
    siteAdminId,
  }: {
    page: number;
    pageSize: number;
    siteId: number;
    siteUser: number;
    dateStart?: Moment;
    dateEnd?: Moment;
    siteAdminId?: number;
  }): PaginatedApiResponse<SessionListElement[]> {
    if (BaseService.getUseMock()) {
      return BaseService.paginatedMockData({
        data: sampleSessionsList,
        metadata: sampleMetadata,
      });
    } else {
      return axios.get(
        BaseService.buildRequestURL(
          {
            page,
            page_size: pageSize,
            site_id: siteId,
            site_user_id: siteUser ?? undefined,
            from_date: dateStart?.format("YYYY-MM-DD"),
            to_date: dateEnd?.format("YYYY-MM-DD"),
            site_admin_id: siteAdminId,
          },
          SESSION_PATH
        )
      );
    }
  }

  public static getSiteUserFilterOptions(
    siteId: number | undefined,
    siteAdminId?: number
  ): ApiResponse<SiteUserFilter[]> {
    if (BaseService.getUseMock()) {
      return BaseService.mockData({ data: siteUserFilterOptions });
    } else {
      return axios.get(
        BaseService.buildRequestURL(
          {
            site_id: siteId,
            site_admin_id: siteAdminId,
          },
          SITE_USERS_PATH
        )
      );
    }
  }

  public static getPendingRegimenList({
    page,
    pageSize,
    site,
    siteAdminId,
    tag,
    pen,
    lot,
    diagnosis,
    siteStatus,
    dateStart,
    dateEnd,
  }: {
    page: number;
    pageSize: number;
    site: number;
    siteAdminId?: number;
    tag?: string;
    pen?: string;
    lot?: string;
    diagnosis?: string;
    siteStatus?: string;
    dateStart?: string;
    dateEnd?: string;
  }): LegacyPaginatedApiResponse<PendingRegimen> {
    return axios.get(
      BaseService.buildRequestURL(
        {
          page,
          pageSize,
          site,
          siteAdminId,
          tag,
          pen,
          lot,
          diagnosis,
          siteStatus,
          dateStart,
          dateEnd,
        },
        PENDING_REGIMENS_PATH
      )
    );
  }

  public static getDeadAnimalsList({
    page,
    sortBy,
    order,
    site,
    siteAdminId,
    filterStatusBy,
    dateStart,
    dateEnd,
  }: {
    page: number;
    sortBy: string;
    order: string;
    site: number;
    siteAdminId?: number;
    filterStatusBy: string;
    dateStart?: string;
    dateEnd?: string;
  }): LegacyPaginatedApiResponse<DeadAnimal> {
    return axios.get(
      BaseService.buildRequestURL(
        {
          page,
          sortBy,
          order,
          site,
          siteAdminId,
          filterStatusBy,
          dateStart,
          dateEnd,
        },
        DEAD_ANIMALS_PATH
      )
    );
  }

  public static getSiteSyncList({
    siteId,
    page,
    sortBy,
    order,
    filterBy,
    dateStart,
    dateEnd,
  }: {
    siteId: number;
    page: number;
    sortBy: string;
    order: string;
    filterBy: string;
    dateStart?: string;
    dateEnd?: string;
  }): LegacyPaginatedApiResponse<SiteSync> {
    return axios.get(
      BaseService.buildRequestURL(
        {
          page,
          sortBy,
          order,
          filterBy,
          dateStart,
          dateEnd,
        },
        `/sites/${siteId}${SITE_SYNCS_PATH}`,
        BASE_PATH
      )
    );
  }

  public static getSiteModule({
    siteId,
    moduleId,
  }: {
    siteId: number;
    moduleId: number;
  }): ApiResponse<SiteModule> {
    return axios.get(
      BaseService.buildRequestURL(
        { moduleId },
        `/sites/${siteId}/module`,
        BASE_PATH
      )
    );
  }

  public static updateSiteModule({
    siteId,
    module,
  }: {
    siteId: number;
    module: SiteModule;
  }): ApiResponse<SiteModule> {
    return axios.post(
      BaseService.buildRequestURL({}, `/sites/${siteId}/module`, BASE_PATH),
      {
        id: module.id,
        is_active: module.is_active,
        features: module.features,
      }
    );
  }
}
