import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Select } from "antd";

const Option = Select.Option;

export default function SiteSelectModal(props) {
  return (
    <Modal
      visible={props.visible}
      title="Select a Facility"
      onCancel={props.handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={props.handleOk}>
          Choose
        </Button>,
      ]}
    >
      <Select
        onSelect={props.handleSelect}
        showSearch
        style={{ width: "100%", margin: "auto" }}
        placeholder="Select a Facility"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {props.sites.map((item) => (
          <Option key={item.id}>{item.name}</Option>
        ))}
      </Select>
    </Modal>
  );
}

SiteSelectModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
};
