import React, { Component } from "react";
import axios from "axios";
import { Button, Table } from "antd";
import errorMessage from "utils/errorMessage";

class SiteAdminList extends Component {
  state = {
    data: [],
    currentPage: 1,
    sortField: "name",
    sortType: "asc",
    total: 1,
  };

  componentDidMount = () => {
    this.getTheData();
  };

  getTheData = (page = 1) => {
    const { site } = this.props;
    const url = `/cms/sites/${site.id}/admins?page=${page}`;

    axios.get(url).then(
      (response) => {
        const data = response.data.data;
        const currentPage = response.data.current_page;
        const total = response.data.total;
        this.setState({ data, currentPage, total });
      },
      (error) => errorMessage(error)
    );
  };

  getFullNamePlural = (fullName) => {
    return fullName && fullName[fullName.length - 1] !== "s"
      ? `${fullName}'s`
      : `${fullName}'`;
  };

  render = () => {
    const { site } = this.props;
    const columns = [
      {
        title: "Facility Manager",
        dataIndex: "full_name",
      },
      {
        dataIndex: "created_at",
        render: (text, row) => <span>{row.created_at} CST</span>,
      },
      {
        dataIndex: "is_active",
      },
      {
        dataIndex: "login_as",
        className: "login-col",
        render: (text, row) => (
          <span>
            {row.is_verified === "Verified" ? (
              row.is_active === "Active" ? (
                <a
                  href={`/admin-cms/facilities/${site.id}?siteAdminId=${row.id}`}
                >
                  View {this.getFullNamePlural(row.full_name)} Dashboard
                </a>
              ) : (
                <span className={"text-muted"}>
                  Must be active to view dashboard
                </span>
              )
            ) : (
              "Pending Registration"
            )}
          </span>
        ),
      },
      {
        title: (
          <Button
            type={"default"}
            onClick={() =>
              (window.location = `/cms/facilities/${site.id}/create-admin`)
            }
            className={"form-button small-button"}
          >
            Add User
          </Button>
        ),
        align: "right",
        dataIndex: "edit",
        render: (text, row) => (
          <span>
            <a href={`${site.id}/users/${row.id}`}>Edit</a>
          </span>
        ),
      },
    ];

    const pagination = {
      onChange: this.getTheData,
      current: this.state.currentPage,
      total: this.state.total,
      pageSize: 5,
      hideOnSinglePage: true,
      showSizeChanger: false,
    };

    return (
      <div className={"list-page admin-list"}>
        <Table
          columns={columns}
          dataSource={this.state.data}
          className={"custom-table"}
          rowKey={"id"}
          pagination={pagination}
        />
      </div>
    );
  };
}

export default SiteAdminList;
