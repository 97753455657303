import React, { Component } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import eventEmitter from "utils/eventEmitter";
import debounce from "lodash/debounce";

class TKAdminSearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
    };

    this.updateSearchTerm = this.updateSearchTerm.bind(this);

    this.ee = eventEmitter.EventEmitter;
  }

  emitEvent = debounce(() => {
    this.ee.emit("event");
  }, 200);

  updateSearchTerm(e) {
    this.setState(
      {
        inputValue: e.target.value,
      },
      () => {
        window.SearchTerm = this.state.inputValue;
        this.emitEvent();
      }
    );
  }

  render() {
    const placeholderText =
      this.props.routeName === "site-managers-list"
        ? "Search by users name"
        : "Search by facility name or facility manager";

    return (
      <div>
        <Input
          onChange={this.updateSearchTerm}
          prefix={
            <SearchOutlined style={{ color: "rgba(164,164,164,0.85)" }} />
          }
          placeholder={placeholderText}
          value={this.state.inputValue}
        />
      </div>
    );
  }
}

export default TKAdminSearchBar;
