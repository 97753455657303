import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import errorMessage from "utils/errorMessage";
import debounce from "lodash/debounce";

const siteEditURL = (siteId) => {
  return `/cms/facilities/${siteId}/manage`;
};

function useEffectSkipFirst(fn) {
  const isFirst = useRef(true);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }

    fn();
  }, [fn]);
}

const ForceSyncButton = ({ isActive, disabled, onClick }) => {
  return (
    isActive === "Active" && (
      <Button
        type="default"
        className="form-button small-button margin-right-10"
        disabled={disabled}
        onClick={onClick}
      >
        <span>Force Sync</span>
      </Button>
    )
  );
};

const ManageFacilityButton = ({ url }) => {
  return (
    <a href={url}>
      <Button type="primary" className={"small-button"}>
        Manage Facility
      </Button>
    </a>
  );
};

export default function FacilityDetailsHeader({ site }) {
  const [disableSync, setDisableSync] = useState(false);

  useEffectSkipFirst(() => {
    if (disableSync) {
      forceSync();
    } else {
      handleSuccessfulForceSync();
    }
  }, [disableSync]);

  const forceSync = debounce(() => {
    axios.put(`/cms/sites/${site.id}/force-sync`).then(
      () => {
        setDisableSync(false);
      },
      (error) => errorMessage(error)
    );
  }, 1000);

  const handleSuccessfulForceSync = () =>
    notification.success({
      message: "Success",
      description: `${site.name} Forced Sync Successfully. The site will start the sync within the minute unless currently syncing.`,
    });

  const handleForceSync = () => {
    setDisableSync(true);
  };

  return (
    <div
      className={"row details-page-top-bar"}
      style={{ marginLeft: 10, marginRight: 10, paddingTop: "4px" }}
    >
      <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
        <span className={"font-bold"}>Territory:</span>&nbsp;
        <span className={"truncate-text"}>{site.territory || ""}</span>
      </div>

      <div
        style={{
          width: "60%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {site.is_active === "Active" ? (
          <span className={"margin-right-20"}>
            <CheckCircleOutlined /> Site Active
          </span>
        ) : (
          <span className={"margin-right-20 text-muted"}>Site Deactivated</span>
        )}
        {site.last_sync_date ? (
          <div className={"margin-right-20"}>
            {site.last_Sync_status === "Failed" ? (
              <span>
                <CloseCircleOutlined style={{ marginRight: "3px" }} />
                Last sync failed
              </span>
            ) : (
              <span>
                <CheckCircleOutlined style={{ marginRight: "3px" }} />
                Last sync at {site.last_sync_date} CST
              </span>
            )}
          </div>
        ) : (
          <span className={"margin-right-20"}>Site has not yet synced</span>
        )}
        <div className={"sm-margin-top-5"}>
          <ForceSyncButton
            disabled={disableSync}
            onClick={handleForceSync}
            isActive={site.is_active}
          />
          <ManageFacilityButton url={siteEditURL(site.id)} />
        </div>
      </div>
    </div>
  );
}

FacilityDetailsHeader.propTypes = {
  site: PropTypes.object,
};
