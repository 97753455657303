import React, { Component } from "react";
import axios from "axios";
import { SettingFilled } from "@ant-design/icons";
import { Modal, notification, Button } from "antd";
import errorMessage from "utils/errorMessage";
import newBanner from "img/new_banner.svg";
import includes from "lodash/includes";

import ModuleSettingsModal from "./ModuleSettingsModal/ModuleSettingsModal";
import ActiveSelect from "./ActiveSelect";

const confirm = Modal.confirm;

class ModuleDetail extends Component {
  state = {
    module: this.props.module,
    settingsVisible: false,
    settingsTitle: this.props.module.module_name,
    newModules: ["movements", "truck_system"],
  };

  updateState = (data, callback) => () =>
    this.setState((state) => ({ ...state, ...data }), callback);

  getNewModuleFlag = () =>
    this.state.module.module_slug &&
    includes(this.state.newModules, this.state.module.module_slug);

  getActiveText = () =>
    this.state.module.is_active ? "Activated" : "Deactivated";

  changeModuleStatus = () => {
    const { site } = this.props;
    const { module } = this.state;
    axios
      .put(`/cms/sites/${site.id}/module-status-change`, {
        id: module.id,
        is_active: !module.is_active,
      })
      .then(
        (response) => this.successfulSave(response.data),
        (error) => errorMessage(error)
      );
  };

  successfulSave = (module) => {
    this.updateState({ module: module }, () =>
      notification.success({
        message: "Success",
        description: "Module Updated Successfully",
      })
    )();
  };

  showDeactivateConfirmation = () => {
    const { site } = this.props;
    const { module } = this.state;
    if (module.is_active === 1) {
      confirm({
        title: <span>DEACTIVATE MODULE</span>,
        content: `Are you sure you want to deactivate ${module.module_name} for ${site.name}?`,
        onOk: this.changeModuleStatus,
        okText: "Deactivate",
        okType: "danger",
        cancelType: "default",
        className: "no-icon-modal",
        width: 530,
      });
    } else {
      this.changeModuleStatus();
    }
  };

  onModalSuccess = (module) => {
    this.setState({ module, settingsVisible: false }, () =>
      notification.success({
        message: "Success",
        description: "Module Updated Successfully",
      })
    );
  };

  onModalClose = () => {
    this.setState({ settingsVisible: false });
  };

  render = () => (
    <div
      className={`module-detail ${
        this.state.module.is_active ? "active" : ""
      } ${this.state.module.module_slug}`}
      style={{ position: "relative" }}
    >
      {this.getNewModuleFlag() && (
        <img
          src={newBanner}
          alt={"New Module Banner"}
          className="new-module-banner"
          style={{ position: "absolute", right: "-2px", top: "-4px" }}
        />
      )}
      <div style={{ display: "flex", minHeight: 27 }}>
        <div style={{ width: "75%" }}>{this.state.module.module_name}</div>
        {this.state.module.features &&
          this.state.module.features.length > 0 && (
            <div
              style={{ textAlign: "right", width: "25%", marginTop: "-5px" }}
            >
              <Button
                type="link"
                onClick={this.updateState({ settingsVisible: true })}
                style={{ color: "white" }}
              >
                <SettingFilled />
              </Button>
              {this.state.settingsVisible && (
                <ModuleSettingsModal
                  site={this.props.site}
                  moduleId={this.state.module.id}
                  modalTitle={`${this.state.module.module_name} Settings`}
                  isOpen={this.state.settingsVisible}
                  onConfirm={this.onModalSuccess}
                  onCancel={this.onModalClose}
                  moduleActivation={this.props.showAction}
                />
              )}
            </div>
          )}
      </div>

      {this.props.showAction ? (
        <ActiveSelect
          onChange={this.showDeactivateConfirmation}
          isActive={Boolean(this.state.module.is_active)}
          bordered={false}
        />
      ) : (
        <div style={{ marginTop: "10px" }}>{this.getActiveText()}</div>
      )}
      <div className={!this.props.showAction ? "padding-top-10" : ""}>
        {this.state.module.created_at} CST
      </div>
    </div>
  );
}

export default ModuleDetail;
