import React, { Component } from "react";

import { Form, Input, Select, Divider, Button, Tabs } from "antd";
import errorMessage from "utils/errorMessage";
import axios from "axios";
import debounce from "lodash/debounce";

const FormItem = Form.Item;
const Option = Select.Option;
const { TabPane } = Tabs;

class AddUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [],
      selectedUsersList: [],
      buttonIsLoading: false,
      selectedUser: [],
      tab: null,
    };
  }

  formRef = React.createRef();

  componentDidMount = () => {
    this.getUsers();
  };

  getUsers = () => {
    const site = this.props.site;
    const url = "/cms/users/facility-users/" + site.id;
    axios.get(url).then((response) => {
      this.setState({ userList: response.data });
    });
  };

  debouncedFormExistingUser = debounce((values) => {
    const site = this.props.site;
    axios
      .post("/cms/users/add-facility-admin", {
        users_to_update: values,
        site: site ? site.id : null,
      })
      .then(
        () => {
          localStorage.setItem(
            "success-notification",
            "Existing Users were added successfully"
          );
          this.handleUserRouting();
        },
        (error) => {
          errorMessage(error);
          this.setState({ buttonIsLoading: false });
        }
      );
  }, 200);

  debouncedFormSubmission = debounce((values) => {
    const site = this.props.site;

    axios
      .post("/cms/users/create", {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        territories: values.territories,
        role: this.props.userRole,
        site: site ? site.id : null,
      })
      .then(
        () => {
          localStorage.setItem(
            "success-notification",
            values.first_name +
              " " +
              values.last_name +
              " was created successfully"
          );
          this.handleUserRouting();
        },
        (error) => {
          errorMessage(error);
          this.setState({ buttonIsLoading: false });
        }
      );
  }, 200);

  handleSubmitAddNewUser = () => {
    this.setState({ buttonIsLoading: true });

    const form = this.formRef.current;
    form
      .validateFields()
      .then((values) => this.debouncedFormSubmission(values))
      .finally(() => this.setState({ buttonIsLoading: false }));
  };

  handleSubmitAddExistingUser = () => {
    this.setState({ buttonIsLoading: true });

    let usersToUpdate = this.state.selectedUsersList.map(
      (usersList) => usersList.id
    );

    if (usersToUpdate) {
      this.debouncedFormExistingUser(usersToUpdate);
    } else {
      this.setState({ buttonIsLoading: false });
    }
  };

  updateSelect = (val) => {
    let filteredArray = this.state.userList.filter((item) => item.id === val);
    this.setState({ selectedUser: filteredArray });
  };

  handleRemoveUser = (e) => {
    let filteredArray = this.state.selectedUsersList.filter(
      (item) => item.id !== e.target.value
    );
    let removedUser = this.state.selectedUsersList.filter(
      (item) => item.id === e.target.value
    );
    this.setState({
      selectedUsersList: filteredArray,
      userList: [...this.state.userList, ...removedUser],
    });
  };

  handleAddUser = () => {
    let selectedId = this.state.selectedUser.map((usersList) => usersList.id);
    let filteredArray = this.state.userList.filter(
      (item) => item.id !== selectedId
    );
    this.state.selectedUser &&
      this.setState({
        selectedUsersList: [
          ...this.state.selectedUsersList,
          ...this.state.selectedUser,
        ],
        userList: filteredArray,
        selectedUser: [],
      });
  };

  handleUserRouting = () => {
    const { userRole, site } = this.props;
    if (userRole === "turn_key_admin") {
      window.location = `/cms/users`;
    } else {
      if (site) {
        window.location = `/cms/facilities/${site.id}`;
      } else {
        window.location = "/cms/facilities";
      }
    }
  };

  render = () => {
    return (
      <div
        className="add-user-form"
        style={{
          backgroundColor: "#fff",
          marginTop: "28px",
          padding: "5%",
          border: "1px solid rgba(37, 37, 37, 0.2)",
          borderRadius: "6px",
        }}
      >
        <div className={"leftTabs"}>
          <Tabs className="left" type="card" style={{ alignContent: "left" }}>
            <TabPane tab="Add New User" key="1">
              <Form
                ref={this.formRef}
                className="ahi-form"
                style={{
                  marginTop: "0px",
                  border: "0px",
                  padding: "0%",
                  paddingTop: "3%",
                }}
                hideRequiredMark={true}
              >
                <div style={{ padding: "5px" }}>
                  <div className="row">
                    <div className="col-xs-6">
                      <FormItem
                        label={"First Name"}
                        colon={false}
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input a first name",
                          },
                        ]}
                      >
                        <Input type="text" placeholder="First Name" />
                      </FormItem>
                    </div>
                    <div className="col-xs-6">
                      <FormItem
                        label={"Last Name"}
                        colon={false}
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input a last name",
                          },
                        ]}
                      >
                        <Input type="text" placeholder="Last Name" />
                      </FormItem>
                    </div>
                  </div>
                  <FormItem
                    label={"Email Address"}
                    colon={false}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input an email address",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Email Address" />
                  </FormItem>

                  <p className="email-invitation-text">
                    An email invitation will be sent upon saving.
                  </p>
                  <Divider />

                  <FormItem className={"ahi-form-bottom-button-wrap"}>
                    <Button
                      className="form-button"
                      onClick={this.handleUserRouting}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.handleSubmitAddNewUser}
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </FormItem>
                </div>
              </Form>
            </TabPane>
            <TabPane tab="Add Existing User" key="2">
              <Form
                className="ahi-form"
                style={{
                  marginTop: "0px",
                  border: "0px",
                  padding: "0%",
                  paddingTop: "3%",
                }}
                hideRequiredMark={true}
              >
                <div style={{ padding: "5px" }}>
                  {this.state.userList && this.state.userList.length > 0 ? (
                    <React.Fragment>
                      <FormItem label={"Existing Users"} colon={false}>
                        <Select
                          style={{ width: "60%" }}
                          onSelect={this.updateSelect}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          placeholder="Select a person"
                        >
                          {this.state.userList
                            .sort((a, b) =>
                              a.full_name < b.full_name
                                ? -1
                                : a.full_name > b.full_name
                                ? 1
                                : 0
                            )
                            .map((usersList) => (
                              <Option key={usersList.id} value={usersList.id}>
                                {usersList.full_name}
                              </Option>
                            ))}
                        </Select>
                        <Button
                          style={{ marginLeft: "8px" }}
                          onClick={this.handleAddUser}
                        >
                          Add User
                        </Button>
                      </FormItem>

                      <div>
                        {this.state.selectedUsersList.map((usersList) => (
                          <div
                            style={{ paddingTop: "15px", textAlign: "left" }}
                            key={usersList.id}
                          >
                            {usersList.full_name}
                            <Button
                              onClick={this.handleRemoveUser.bind(this)}
                              value={usersList.id}
                              className={usersList.full_name}
                              size={"small"}
                              style={{ float: "right" }}
                              type="danger"
                            >
                              Remove
                            </Button>
                          </div>
                        ))}
                      </div>

                      <FormItem
                        style={{ paddingTop: "20px" }}
                        className={"ahi-form-bottom-button-wrap"}
                      >
                        <Button
                          className="form-button"
                          onClick={this.handleUserRouting}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={this.state.selectedUsersList.length < 1}
                          onClick={this.handleSubmitAddExistingUser}
                          type="primary"
                          htmlType="submit"
                          loading={this.state.buttonIsLoading}
                        >
                          Save
                        </Button>
                      </FormItem>
                    </React.Fragment>
                  ) : (
                    <p className="email-invitation-text">
                      {`There are no existing users to assign to this facility. Please create a new one to proceed.`}
                    </p>
                  )}
                </div>
              </Form>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  };
}

export default AddUserForm;
