import { CloseOutlined } from "@ant-design/icons";
import { Button, Divider, Upload, UploadProps } from "antd";
import { AxiosError } from "axios";
import PenCoordinatesTable from "components/PenCoordinatesTable/PenCoordinatesTable";
import { useSite } from "contexts/SiteDetailsContext";
import React, { ReactElement, useMemo, useState } from "react";
import { KmlUploadService } from "services/KmlUploadService";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import errorMessage, { parseError } from "utils/errorMessage";

const UploadKMLFile = (): ReactElement => {
  const [geocoordinateJSONObject, setGeocoordinateJSONObject] =
    useState<PenAndCoordinateElement[]>(null);
  const [error, setError] = useState<string>();
  const [geocoordinateKMLFile, setGeocoordinateKMLFile] = useState<File>(null);
  const [loadingCoordinates, setLoadingCoordinates] = useState(false);
  const disableSubmit = useMemo(
    () => !geocoordinateKMLFile || !geocoordinateJSONObject || !!error,
    [error, geocoordinateJSONObject, geocoordinateKMLFile]
  );

  const { site } = useSite();

  const validateKmlFile = ({ onSuccess, onError }: RcCustomRequestOptions) => {
    KmlUploadService.validateKmlFile(site.id, geocoordinateKMLFile)
      .then(
        (response) => {
          setGeocoordinateJSONObject(response.data.data);
          onSuccess(response.data);
        },
        (error: AxiosError) => {
          const { validationMessages } = parseError(error);
          setError(
            validationMessages.join("; ") ?? "Failed to submit the file"
          );
          onError(error);
        }
      )
      .finally(() => setLoadingCoordinates(false));
  };

  const uploadKMLFile = () => {
    KmlUploadService.uploadKmlFile(site.id, geocoordinateKMLFile).then(
      onSuccessfulKMLFileUpload,
      (error: AxiosError) => {
        errorMessage(error);
      }
    );
  };

  const onSuccessfulKMLFileUpload = () => {
    localStorage.setItem(
      "success-notification",
      "Facility pens updated successfully"
    );
    window.location.assign(`/cms/facilities/${site.id}`);
  };

  const resetForm = () => {
    setGeocoordinateJSONObject(null);
    setGeocoordinateKMLFile(null);
    setError(null);
  };

  const beforeUpload = (kmlFile: File) => {
    setGeocoordinateJSONObject(null);
    setError(null);
    setLoadingCoordinates(true);
    setGeocoordinateKMLFile(kmlFile);
  };

  const uploadProps: UploadProps = {
    beforeUpload,
    customRequest: validateKmlFile,
    accept: ".kml",
    multiple: false,
    showUploadList: false,
  };

  return (
    <div className="ahi-form upload-kml-form">
      <div className="top-section">
        <Upload {...uploadProps}>
          <Button
            className="black-and-white-button upload-button"
            disabled={!site.last_successful_sync_date}
          >
            Upload KML File
          </Button>
        </Upload>
        {!geocoordinateKMLFile ? (
          <span className="upload-file-text">No File Attached</span>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className="upload-file-text">
              {geocoordinateKMLFile?.name}
            </span>
            <Button
              onClick={resetForm}
              className="close-button-icon clear-button"
              icon={<CloseOutlined />}
            />
          </div>
        )}
      </div>
      {(geocoordinateJSONObject || loadingCoordinates) && !error ? (
        <PenCoordinatesTable
          tableData={geocoordinateJSONObject}
          loading={loadingCoordinates}
        />
      ) : null}
      <ErrorDisplay error={error} lastSync={site.last_successful_sync_date} />
      <Divider />
      <div className="ahi-form-bottom-button-wrap">
        <Button
          className="form-button"
          type="default"
          onClick={() => window.location.assign(`/cms/facilities/${site.id}`)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={disableSubmit}
          onClick={uploadKMLFile}
          style={{
            marginLeft: "8px",
          }}
          className={disableSubmit ? "disabled-button" : ""}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const ErrorDisplay = ({
  error,
  lastSync,
}: {
  error: string;
  lastSync: string;
}) => {
  if (!error && lastSync) return <></>;

  return (
    <div className="error-block">
      <span className="error-text">
        {!lastSync
          ? "Please ensure that pens already exist at the facility."
          : error}
      </span>
    </div>
  );
};

export default UploadKMLFile;
