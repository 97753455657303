import React, { useState } from "react";
import { Modal, Button, DatePicker } from "antd";
import moment, { Moment } from "moment";
const { RangePicker } = DatePicker;

const DateSelectorModal = ({
  submit,
  modalVisible,
  showModal,
  dateStart = undefined,
  dateEnd = undefined,
  disabledDates,
}: {
  submit: (dateStartModal: Moment, dateEndModal: Moment) => void;
  modalVisible: boolean;
  showModal: (visible?: boolean) => void;
  dateStart?: Moment;
  dateEnd?: Moment;
  disabledDates?: (dates: moment.Moment) => boolean;
}): React.ReactElement => {
  const [dateStartModal, setDateStartModal] = useState<Moment>(dateStart);
  const [dateEndModal, setDateEndModal] = useState<Moment>(dateEnd);

  const handleOkDateModal = (): void => {
    submit(dateStartModal, dateEndModal);
    showModal(false);
  };

  const handleCancelDateModal = (): void => {
    setDateStartModal(dateStart);
    setDateEndModal(dateEnd);
    showModal(false);
  };

  const updateDate = (
    value: [moment.Moment | undefined, moment.Moment | undefined] | null
  ): void => {
    const [start, end] = value ?? [];
    setDateStartModal(start ?? undefined);
    setDateEndModal(end ?? undefined);
  };

  return (
    <Modal
      data-testid="date-selector-modal"
      title="Select Range Filter"
      open={modalVisible}
      onCancel={handleCancelDateModal}
      footer={[
        <Button
          data-testid="cancel-button"
          key="Cancel"
          onClick={handleCancelDateModal}
        >
          Cancel
        </Button>,
        <Button
          data-testid="submit-button"
          key="Apply"
          type="primary"
          onClick={handleOkDateModal}
        >
          Submit
        </Button>,
      ]}
    >
      <RangePicker
        onChange={updateDate}
        style={{ margin: "auto", width: "100%", padding: "10px" }}
        format="YYYY-MM-DD"
        placeholder={["Start Time", "End Time"]}
        disabledDate={disabledDates}
        value={
          dateStartModal && dateEndModal
            ? [
                moment(dateStartModal, "YYYY-MM-DD"),
                moment(dateEndModal, "YYYY-MM-DD"),
              ]
            : null
        }
      />
    </Modal>
  );
};

export default DateSelectorModal;
