import React, { Component } from "react";
import { Table, Input, Button } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import errorMessage from "utils/errorMessage";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import DateSelectorModal from "components/DateSelectorModal/DateSelectorModal";
import OnOutsideClick from "react-outclick";
import { CalendarOutlined } from "@ant-design/icons";
import { fillEmptyWithHyphen } from "utils/stringFormatting";
import { SiteService } from "services/SiteService";
import { hasBothDates } from "utils/dateUtils";

const statusFilterOptions = [
  { label: "Not Received", value: "not_received" },
  { label: "Cancelled By User", value: "cancelled_by_user" },
  { label: "Deleted By Client", value: "deleted_by_client" },
  { label: "Deleted At Site", value: "deleted_at_site" },
  { label: "Updated By Client", value: "updated_by_client" },
  { label: "Posted", value: "posted" },
  { label: "Pending At Site", value: "pending_at_site" },
  { label: "Failed At Site", value: "failed_at_site" },
];

const defaultPageSize = 12;
const defaultStartPage = 1;
const pageOptions = [6, 12, 18];

class PendingRegimenList extends Component {
  state = {
    total: 0,
    treatedAnimals: [],
    loading: true,
    dateStart: undefined,
    dateEnd: undefined,
    dateSelectorVisible: false,
    searchTag: "",
    searchTagModal: "",
    searchPen: "",
    searchPenModal: "",
    searchLot: "",
    searchLotModal: "",
    searchDiagnosis: "",
    searchDiagnosisModal: "",
    siteStatus: null,
  };

  componentDidMount() {
    this.getTheData();
  }

  getTheData = (page = defaultStartPage, pageSize = defaultPageSize) => {
    SiteService.getPendingRegimenList({
      page,
      pageSize,
      site: this.props.site.id,
      siteAdminId,
      tag: this.state.searchTag,
      pen: this.state.searchPen,
      lot: this.state.searchLot,
      diagnosis: this.state.searchDiagnosis,
      siteStatus: this.state.siteStatus ?? undefined,
      dateStart: hasBothDates(this.state.dateStart, this.state.dateEnd)
        ? this.state.dateStart
        : undefined,
      dateEnd: hasBothDates(this.state.dateStart, this.state.dateEnd)
        ? this.state.dateEnd
        : undefined,
    }).then(
      (response) => {
        const treatedAnimals = response.data.data;
        const currentPage = response.data.current_page;
        const total = response.data.total;

        this.setState({
          treatedAnimals,
          currentPage,
          total,
          loading: false,
        });
      },
      (error) => {
        errorMessage(error);
        this.setState({ loading: false });
      }
    );
  };

  setSiteStatus = (filter, confirm) => {
    this.setState({ siteStatus: filter }, this.getTheData);
    confirm();
  };

  handleSearch = (value, dataIndex) => {
    let propName;
    switch (dataIndex) {
      case "Tag":
        propName = "searchTag";
        break;
      case "Pen":
        propName = "searchPen";
        break;
      case "Lot":
        propName = "searchLot";
        break;
      default:
        propName = "searchDiagnosis";
    }
    this.setState({ [propName]: value }, this.getTheData);
  };

  handleReset = (dataIndex) => {
    switch (dataIndex) {
      case "Tag":
        this.setState({ searchTagModal: "", searchTag: "" }, this.getTheData);
        break;
      case "Pen":
        this.setState({ searchPenModal: "", searchPen: "" }, this.getTheData);
        break;
      case "Lot":
        this.setState({ searchLotModal: "", searchLot: "" }, this.getTheData);
        break;
      default:
        this.setState(
          { searchDiagnosisModal: "", searchDiagnosis: "" },
          this.getTheData
        );
    }
  };

  setModalState = (dataIndex, value) => {
    switch (dataIndex) {
      case "Tag":
        this.setState({ searchTagModal: value });
        return "searchTagModal";
      case "Pen":
        this.setState({ searchPenModal: value });
        return "searchPenModal";
      case "Lot":
        this.setState({ searchLotModal: value });
        return "searchLotModal";
      default:
        this.setState({ searchDiagnosisModal: value });
        return "searchDiagnosisModal";
    }
  };

  handleModalReset = (dataIndex) => {
    switch (dataIndex) {
      case "Tag":
        this.setState({ searchTagModal: this.state.searchTag });
        return "searchTagModal";
      case "Pen":
        this.setState({ searchPenModal: this.state.searchPen });
        return "searchPenModal";
      case "Lot":
        this.setState({ searchLotModal: this.state.searchLot });
        return "searchLotModal";
      default:
        this.setState({ searchDiagnosisModal: this.state.searchDiagnosis });
        return "searchDiagnosisModal";
    }
  };

  showModal = (dateSelectorVisible) => {
    this.setState({
      dateSelectorVisible,
    });
  };

  updateDate = (dateStart, dateEnd) => {
    this.setState(
      {
        dateStart:
          dateStart && dateEnd ? dateStart.format("YYYY-MM-DD") : undefined,
        dateEnd:
          dateEnd && dateStart ? dateEnd.format("YYYY-MM-DD") : undefined,
      },
      this.getTheData
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ confirm }) => (
      <OnOutsideClick
        onOutsideClick={() => {
          this.handleModalReset(dataIndex);
        }}
      >
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={this.state[`search${dataIndex}Modal`]}
            onChange={(e) => {
              this.setModalState(
                dataIndex,
                e.target.value ? e.target.value : ""
              );
            }}
            onPressEnter={() => {
              this.handleSearch(
                this.state[`search${dataIndex}Modal`],
                dataIndex
              );
              confirm({ closeDropdown: true });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              this.handleSearch(
                this.state[`search${dataIndex}Modal`],
                dataIndex
              );
              confirm({ closeDropdown: true });
            }}
            size="small"
            icon={<SearchOutlined />}
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              this.handleReset(dataIndex);
              confirm({ closeDropdown: true });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </OnOutsideClick>
    ),
    filterIcon: () => (
      <SearchOutlined
        style={{
          color: this.state[`search${dataIndex}`] !== "" ? "#00c9b0" : "#aaa",
        }}
      />
    ),
  });

  render() {
    const columns = [
      {
        title: "Tag",
        dataIndex: "tag",
        key: "tag",
        ...this.getColumnSearchProps("Tag"),
        render: fillEmptyWithHyphen,
      },
      {
        title: (
          <div>
            Date
            <div
              onClick={() => this.setState({ dateSelectorVisible: true })}
              className="icon-calendar"
            >
              <CalendarOutlined
                style={{
                  color:
                    this.state.dateStart && this.state.dateEnd
                      ? "#00C9B0"
                      : "inherit",
                }}
              />
            </div>
          </div>
        ),
        dataIndex: "date",
        render: fillEmptyWithHyphen,
      },
      {
        title: "Pen",
        dataIndex: "pen",
        key: "pen",
        ...this.getColumnSearchProps("Pen"),
        render: fillEmptyWithHyphen,
      },
      {
        title: "Lot",
        dataIndex: "lot",
        key: "lot",
        ...this.getColumnSearchProps("Lot"),
        render: fillEmptyWithHyphen,
      },
      {
        title: "Diagnosis",
        dataIndex: "diagnosis_description",
        key: "diagnosis_description",
        ...this.getColumnSearchProps("Diagnosis"),
        render: fillEmptyWithHyphen,
      },
      {
        title: "Status",
        dataIndex: "status",
        filterIcon: (
          <FilterOutlined
            style={{
              color: this.state.siteStatus ? "#108ee9" : "#aaa",
            }}
          />
        ),
        filterDropdown: ({ confirm }) => (
          <FilterDropdown
            onSetSelectedFilter={(filter) => {
              this.setSiteStatus(filter, confirm);
            }}
            selectedFilter={this.state.siteStatus}
            options={statusFilterOptions}
          />
        ),
      },
      ...this.props.additionalDisplayColumns,
    ];

    const pagination = {
      onChange: this.getTheData,
      total: this.state.total,
      showSizeChanger: true,
      defaultPageSize: defaultPageSize,
      pageSizeOptions: pageOptions,
    };

    return (
      <>
        <Table
          columns={columns}
          dataSource={this.state.treatedAnimals}
          loading={this.state.loading}
          className={"custom-table"}
          rowKey={"id"}
          pagination={pagination}
          scroll={{ x: "auto" }}
        />
        {this.state.dateSelectorVisible && (
          <DateSelectorModal
            submit={this.updateDate}
            modalVisible={this.state.dateSelectorVisible}
            showModal={this.showModal}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
          />
        )}
      </>
    );
  }
}

export default PendingRegimenList;
