import React, { Component } from "react";
import { Form, Input, Button, Select, Divider, Switch } from "antd";
import errorMessage from "utils/errorMessage";
import axios from "axios";
import FormHeading from "components/common/FormHeading";
const Option = Select.Option;
const FormItem = Form.Item;

class EditProfile extends Component {
  constructor(props) {
    super(props);

    const userData = window.EditUserData;

    this.state = {
      user: userData.user,
      roleSlug: userData.roleSlug,
      roleDisplayName: userData.roleDisplayName,
      territoryList: userData.territories,
      userTerritories: userData.usersTerritories,
      changePassword: false,
    };
  }

  formRef = React.createRef();

  handleSubmit = (values) => {
    const route =
      this.state.roleSlug === "turn_key_admin"
        ? "/cms/users/" + this.state.user.id
        : "/admin-cms/" + this.state.user.id;
    axios.put(route, values).then(
      () => {
        localStorage.setItem(
          "success-notification",
          "Your profile was edited successfully"
        );
        this.navigateBack();
      },
      (error) => errorMessage(error)
    );
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you entered are inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"]);
    }
    callback();
  };

  toggleChangePassword = (isChecked) => {
    this.setState({ changePassword: isChecked });
  };

  navigateBack = () => {
    if (document.referrer && window.location.href !== document.referrer) {
      window.location = document.referrer;
    } else {
      window.location = "/cms/users";
    }
  };

  render = () => {
    const displayPasswords = this.state.changePassword
      ? "display-passwords"
      : "hide-passwords";
    const userTerritories = this.state.userTerritories
      ? this.state.userTerritories.map((t) => t.id.toString())
      : [];

    return (
      <Form
        ref={this.formRef}
        onFinish={this.handleSubmit}
        className="ahi-form"
        hideRequiredMark={true}
        initialValues={{
          first_name: this.state.user.first_name,
          last_name: this.state.user.last_name,
          email: this.state.user.email,
          phone: this.state.user.phone_number,
          territories: userTerritories,
          switch: this.state.changePassword,
        }}
      >
        <FormHeading headingText={"Edit Your Profile"} />
        <Divider />
        <div className="row">
          <div className="col-xs-6">
            <FormItem
              label={"First Name"}
              colon={false}
              rules={[{ required: true, message: "Please input a first name" }]}
              name="first_name"
            >
              <Input placeholder="First Name" />
            </FormItem>
          </div>
          <div className="col-xs-6">
            <FormItem
              label={"Last Name"}
              colon={false}
              rules={[{ required: true, message: "Please input a last name" }]}
              name="last_name"
            >
              <Input placeholder="Last Name" />
            </FormItem>
          </div>
        </div>

        <FormItem
          label={"Email Address"}
          colon={false}
          rules={[{ required: true, message: "Please input an email address" }]}
          name="email"
        >
          <Input type="email" placeholder="Email Address" />
        </FormItem>

        <FormItem
          label={"Phone Number"}
          colon={false}
          rules={[{ required: true }]}
          name="phone"
        >
          <Input type="phone" placeholder="Phone Number" />
        </FormItem>

        <FormItem
          label={"Facility Territories"}
          colon={false}
          rules={[
            {
              required: false,
              message: "Please select a territory",
              type: "array",
            },
          ]}
          name="territories"
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Add Territories"
          >
            {this.state.territoryList.map((territory) => (
              <Option
                key={territory.id}
                value={territory.id.toString()}
                name={territory.name}
              >
                {territory.name}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem label="Change Password" valuePropName="checked" name="switch">
          <Switch onChange={this.toggleChangePassword} />
        </FormItem>

        <div className={"row " + displayPasswords}>
          <div className="col-xs-6">
            <FormItem
              label={"New Password"}
              colon={false}
              rules={[
                {
                  required: false,
                  message: "Please input your password",
                },
                {
                  validator: this.validateToNextPassword,
                },
              ]}
              name="password"
            >
              <Input type="password" placeholder={"Password"} />
            </FormItem>
          </div>
          <div className="col-xs-6">
            <FormItem
              label={"Confirm New Password"}
              colon={false}
              rules={[
                {
                  required: false,
                  message: "Please confirm your password",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ]}
              name="confirm"
            >
              <Input
                type="password"
                onBlur={this.handleConfirmBlur}
                placeholder={"Password"}
              />
            </FormItem>
          </div>
        </div>

        <Divider />

        <FormItem className={"ahi-form-bottom-button-wrap"}>
          <Button className="form-button" onClick={this.navigateBack}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </FormItem>
      </Form>
    );
  };
}

export default EditProfile;
