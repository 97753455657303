import React, { Component } from "react";
import { Button, Divider } from "antd";
import FormHeading from "components/common/FormHeading";
import SiteSelectModal from "components/common/SiteSelectModal";

/*global sites */

class SiteSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newFacility: "",
      sites: sites,
      selectedSite: null,
      modalVisible: false,
      loading: false,
    };
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleOk = () => {
    window.location = `/admin-cms/facilities/${this.state.selectedSite}`;
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSelect = (value) => {
    this.setState({ selectedSite: value });
  };

  render = () => (
    <div className="ahi-form">
      <FormHeading headingText={"Select a Facility"} />
      <Divider style={{ top: "20%" }} />
      <ul
        className={"list"}
        style={{
          padding: 0,
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          paddingTop: "10px",
        }}
      >
        {this.state.sites.slice(0, 5).map((item) => (
          <div
            key={item.id}
            style={{
              position: "relative",
              textAlign: "left",
              marginBottom: "30px",
            }}
          >
            <a
              style={{ color: "#00C9B0" }}
              href={`/admin-cms/facilities/${item.id}`}
            >
              {item.name}
            </a>
          </div>
        ))}
      </ul>
      {this.state.sites.length > 5 && (
        <Button style={{ fontWeight: "900" }} onClick={this.showModal}>
          MORE
        </Button>
      )}
      <SiteSelectModal
        sites={this.state.sites}
        visible={this.state.modalVisible}
        handleSelect={this.handleSelect}
        handleCancel={this.handleCancel}
        handleOk={this.handleOk}
      />
    </div>
  );
}

export default SiteSelect;
