import React from "react";
import { Select } from "antd";

const FilterDropdown = ({
  onSetSelectedFilter,
  selectedFilter,
  options,
}: {
  onSetSelectedFilter: (filter: string | number) => void;
  selectedFilter: string | number;
  options: FilterOption[];
}): React.ReactElement => (
  <div className="custom-filter-dropdown">
    <div style={{ padding: "15px" }}>
      <Select
        style={{ width: "100%", minWidth: "175px" }}
        onSelect={onSetSelectedFilter}
        value={selectedFilter}
        defaultValue={null}
      >
        <Select.Option key={"reset"} value={null}>
          None
        </Select.Option>
        {options?.map((filter: FilterOption) => (
          <Select.Option
            data-testid={filter.label}
            key={filter.value}
            value={filter.value}
          >
            {filter.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  </div>
);

export default FilterDropdown;
