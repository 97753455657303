import React from "react";
import { Divider } from "antd";

import FacilityDetailsHeader from "components/common/FacilityDetailsHeader";
import ModuleDetail from "components/common/ModuleDetail";
import SiteAdminList from "components/common/SiteAdminList";
import SiteSyncList from "components/common/SiteSyncList";

export default function SiteDetails(props) {
  return (
    <div className={"detail-page"}>
      <FacilityDetailsHeader site={props.site} />
      <Divider />
      {props.site.notes && (
        <div className={"row note-well"}>
          <span>Notes:</span>
          <span>{props.site.notes}</span>
        </div>
      )}

      <div className={"row start-md module-list"}>
        {props.siteModules ? (
          props.siteModules.map((module) => (
            <ModuleDetail
              module={module}
              site={props.site}
              showAction={true}
              key={module.id}
            />
          ))
        ) : (
          <span>No Active Modules Found for this Site</span>
        )}
      </div>

      <SiteAdminList site={props.site} />
      <SiteSyncList site={props.site} />
    </div>
  );
}
