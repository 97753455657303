import React, { Component } from "react";
import turnkeyLogo from "img/logo_turnkey.svg";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Button, Checkbox, notification, Space } from "antd";

const REMEMBER_CHECKBOX_TRUE = "on";
const VALIDATION_WARNING_LINE_HEIGHT = "40px";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    const oldFormData = window.OldFormData;
    const csrfToken = window.Laravel.csrfToken;

    this.state = {
      csrfToken: csrfToken,
      oldFormData: oldFormData,
      rememberChecked: oldFormData.old_remember,
    };
  }

  componentDidMount() {
    if (this.state.oldFormData && this.state.oldFormData.other_messages) {
      notification.error({
        message: "Login Error",
        description: this.state.oldFormData.other_messages,
      });
    }
  }

  render() {
    return (
      <div className={"turnkey-authentication"}>
        <div className={"row center-xs"}>
          <div className={"col-xs-6"}>
            <img
              src={turnkeyLogo}
              alt={"Turnkey Logo"}
              className="turnkey-logo"
            />
          </div>
        </div>
        <form method={"POST"} action={"/login"} className="login-form">
          <Input type="hidden" name="_token" value={this.state.csrfToken} />
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <div>
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
                name="email"
                defaultValue={this.state.oldFormData.old_email ?? ""}
              />

              {this.state.oldFormData.email_errors ? (
                <p
                  className="warning"
                  style={{ lineHeight: VALIDATION_WARNING_LINE_HEIGHT }}
                >
                  {this.state.oldFormData.email_errors}
                </p>
              ) : (
                ""
              )}
            </div>
            <div>
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
                name="password"
              />

              {this.state.oldFormData.password_errors ? (
                <p
                  className="warning"
                  style={{ lineHeight: VALIDATION_WARNING_LINE_HEIGHT }}
                >
                  {this.state.oldFormData.password_errors}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className={"row"}>
              <div className={"col-xs-6"}>
                <Input
                  type="hidden"
                  name="remember"
                  value={this.state.rememberChecked}
                />
                <Checkbox
                  checked={
                    this.state.rememberChecked === REMEMBER_CHECKBOX_TRUE
                  }
                  onChange={(e) => {
                    this.setState({
                      rememberChecked: e.target.checked
                        ? REMEMBER_CHECKBOX_TRUE
                        : undefined,
                    });
                  }}
                >
                  Remember me
                </Checkbox>
              </div>
              <div className={"col-md-6"}>
                <a className="login-form-forgot" href="/password/reset">
                  Forgot password?
                </a>
              </div>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button full-width-button"
            >
              Login
            </Button>
          </Space>
        </form>
      </div>
    );
  }
}

export default LoginForm;
