import * as React from "react";
import { Switch } from "antd";

const ActiveSwitch = ({
  onChange,
  isActive,
}: {
  onChange: () => void;
  isActive: boolean;
}): React.ReactElement => (
  <Switch
    onChange={onChange}
    className={isActive ? "active" : "inactive"}
    defaultChecked={isActive}
    checked={isActive}
  />
);

export default ActiveSwitch;
