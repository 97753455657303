import React, { useState, useMemo, useEffect } from "react";
import { Modal, Table, Button } from "antd";
import { AimOutlined, CloseOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { AlignType } from "rc-table/lib/interface";

import { useSite } from "contexts/SiteDetailsContext";
import GoogleMap from "components/GoogleMap/GoogleMap";
import SessionCarousel from "components/SessionCarousel/SessionCarousel";
import { useSessionList } from "contexts/SessionListContext";
import { useSessionDetails } from "contexts/SessionDetailsContext";
import { SessionService } from "services/SessionService";
import errorMessage from "utils/errorMessage";
import { useTableSort } from "hooks/useTableSort";

const DEFAULT_SESSION_DETAILS_PAGE_SETTINGS = {
  pageNumber: 1,
  pageSize: 10,
};
const DEFAULT_COLUMN = "pen";
const columnHeaders = [DEFAULT_COLUMN, "treatments", "deads", "time"];
const defaultColumnProps = {
  ellipsis: true,
  align: "right" as AlignType,
};

function MappedRouteModal({
  modalVisible,
  closeModal,
}: {
  modalVisible: boolean;
  closeModal: () => void;
  pens?: PenAndCoordinateElement[];
}): React.ReactElement {
  const {
    site: { id: siteId },
    siteAdminId,
  } = useSite();

  const [sessionDetails, setSessionDetails] = useState<SessionDetails[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(
    DEFAULT_SESSION_DETAILS_PAGE_SETTINGS.pageNumber
  );
  const [total, setTotal] = useState<number>();

  const { selectedPen, setSelectedPen, setCurrentPens } = useSessionDetails();
  const { currentSession, siteUserID } = useSessionList();
  const { sortButtons, sortColumn, sortType } = useTableSort({
    defaultColumn: DEFAULT_COLUMN,
    columnHeaders,
  });

  useEffect(() => {
    SessionService.getSessionDetails({
      siteId: siteId,
      sessionId: currentSession.sessionID,
      page: currentPage,
      pageSize: DEFAULT_SESSION_DETAILS_PAGE_SETTINGS.pageSize,
      siteAdminId: siteAdminId,
      sortBy: sortColumn,
      sortOrder: sortType,
    }).then(
      (response) => {
        setSessionDetails(response.data.data);
        setCurrentPage(response.data.metadata.current_page);
        setCurrentPens(response.data.data.map((item) => item.pen_id));
        setTotal(response.data.metadata.total);
        setLoading(false);
      },
      (error) => {
        errorMessage(error);
        setLoading(false);
      }
    );
  }, [
    currentSession,
    siteId,
    siteAdminId,
    siteUserID,
    currentPage,
    sortColumn,
    sortType,
    setCurrentPens,
  ]);

  const columns: ColumnsType<SessionDetails> = useMemo(
    () => [
      {
        title: sortButtons.find((sortButtonObj) => sortButtonObj.key === "pen")
          ?.component,
        dataIndex: "pen",
        key: "pen",
        width: "32%",
        render: (text: string, record: SessionDetails): React.ReactNode => {
          const rowIsClicked: boolean = selectedPen === record.pen_id;

          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <AimOutlined
                className="table-icon"
                style={{
                  visibility: rowIsClicked ? "visible" : "hidden",
                  color: rowIsClicked ? "rgba(0, 201, 176, 1)" : "inherit",
                  paddingTop: "4px",
                  paddingRight: "10px",
                }}
              />
              <span title={text}>{text}</span>
            </div>
          );
        },
        ...defaultColumnProps,
      },
      {
        title: sortButtons.find(
          (sortButtonObj) => sortButtonObj.key === "treatments"
        )?.component,
        dataIndex: "treatments",
        key: "treatments",
        width: "25%",
        ...defaultColumnProps,
      },
      {
        title: sortButtons.find(
          (sortButtonObj) => sortButtonObj.key === "deads"
        )?.component,
        dataIndex: "deads",
        key: "deads",
        width: "23%",
        ...defaultColumnProps,
      },
      {
        title: sortButtons.find((sortButtonObj) => sortButtonObj.key === "time")
          ?.component,
        dataIndex: "time",
        key: "time",
        width: "20%",
        ...defaultColumnProps,
      },
    ],
    [sortButtons, selectedPen]
  );

  const pagination = {
    onChange: setCurrentPage,
    current: currentPage,
    total: total,
    pageSize: 10,
    hideOnSinglePage: true,
    showSizeChanger: false,
    responsive: true,
    position: ["bottomCenter" as const],
    showLessItems: true,
  };

  return (
    <Modal
      data-testid="mapped-route-modal"
      open={modalVisible}
      footer={null}
      width={1134}
      closable={false}
      className="mapped-route-modal"
    >
      <div style={{ display: "flex", height: "680px" }}>
        <div style={{ margin: "24px" }}>
          <header
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              onClick={closeModal}
              icon={<CloseOutlined />}
              style={{ marginRight: "20px" }}
              type="text"
              aria-label="Close"
            />
            <h3 style={{ marginBottom: "0", fontSize: "16px" }}>
              Mapped Route
            </h3>
          </header>
          <SessionCarousel />
          <Table
            columns={columns}
            dataSource={sessionDetails}
            onRow={(
              record: SessionDetails
            ): React.HTMLAttributes<HTMLElement> => {
              return {
                onClick: (): void => {
                  setSelectedPen(
                    record.pen_id !== selectedPen ? record.pen_id : undefined
                  );
                },
              };
            }}
            loading={loading}
            className={"mapped-route-table"}
            rowKey={(record) => record.pen}
            pagination={pagination}
            data-testid={"mapped-route-table"}
            tableLayout={"fixed"}
          />
        </div>
        <GoogleMap />
      </div>
    </Modal>
  );
}

export default MappedRouteModal;
