import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { PenCoordinatesAndCenterpoint } from "types/common/geoLocationData";

type SitePensContextValue = {
  pens?: Array<PenCoordinatesAndCenterpoint>;
  setPens?: Dispatch<SetStateAction<Array<PenCoordinatesAndCenterpoint>>>;
};

const SitePensContext = createContext<SitePensContextValue>({});

const SitePensProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [pens, setPens] = useState<Array<PenCoordinatesAndCenterpoint>>();

  const value = useMemo<SitePensContextValue>(
    () => ({
      pens,
      setPens,
    }),
    [pens]
  );

  return (
    <SitePensContext.Provider value={value}>
      {children}
    </SitePensContext.Provider>
  );
};

export default SitePensProvider;

export const useSitePens = (): SitePensContextValue => {
  const sitePensContext = useContext(SitePensContext);
  return sitePensContext;
};
