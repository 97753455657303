export const moduleFixture: SiteModule = {
  id: 145,
  module_name: "Pen Rider",
  module_slug: "pen_rider",
  is_active: true,
  features: [
    {
      id: 49,
      slug: "post_treatment",
      is_active: true,
      display_name: "Post Treatment",
    },
    {
      id: 99,
      slug: "pending_treatment",
      is_active: true,
      display_name: "Pending Treatment",
    },
  ],
  created_at: "10/10/23 10:38 AM",
};
