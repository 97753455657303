import React from "react";
import leftArrow from "img/icons/ic_arrow.svg";

export default function SiteNavigationLink({
  currentRouteName,
  currentUserRole,
  dashboardUserName,
  site,
  siteCount,
}) {
  const pluralizedUsername = (username) => {
    if (username[username.length] === "s") return `${username}'`;
    else return `${username}'s`;
  };

  const disableNavigation = () =>
    siteCount > 0 &&
    currentRouteName === "site-admin-dashboard" &&
    currentUserRole === "site_admin";

  const navigationURL = () => {
    let navURL = site ? `/cms/facilities/${site.id}` : "";
    switch (currentRouteName) {
      case "site-detail":
        navURL = "/cms/facilities";
        break;
      case "site-user-edit":
        navURL = `/admin-cms/facilities/${site.id}${
          window.location.search || ""
        }`;
        break;
      case "site-admin-dashboard":
        if (currentUserRole === "site_admin") navURL = "/admin-cms/site-select";
        break;
      default:
        break;
    }
    return navURL;
  };

  const dashboardText = () => {
    const navigationRoutes = ["site-admin-dashboard", "site-user-edit"];
    return navigationRoutes.indexOf(currentRouteName) > -1
      ? `/ ${
          dashboardUserName ? pluralizedUsername(dashboardUserName) : ""
        } Dashboard`
      : "";
  };

  return (
    <span>
      {disableNavigation() ? (
        <span
          style={{ color: "white", fontSize: "15px" }}
          className="truncate-text"
        >
          {site.name} {dashboardText()} - {site.email}
        </span>
      ) : (
        <a
          className="truncate-text"
          href={navigationURL()}
          style={{ display: "inline-block", paddingRight: 10 }}
        >
          <img
            src={leftArrow}
            className="tab-icon"
            style={{ marginRight: 10 }}
          />
          <span style={{ color: "white" }}>
            {site.name} {dashboardText()} - {site.email}
          </span>
        </a>
      )}
    </span>
  );
}
