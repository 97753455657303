import React, { Component } from "react";
import { Form, Input, Button, Select, Divider } from "antd";
import concat from "lodash/concat";
import axios from "axios";
import errorMessage from "utils/errorMessage";
import FormHeading from "components/common/FormHeading";
const Option = Select.Option;
const FormItem = Form.Item;
/*global availableTerritories */

class CreateSiteForm extends Component {
  state = {
    availableTerritories: availableTerritories,
    tempTerritory: null,
  };

  formRef = React.createRef();

  handleSubmit = (e) => {
    e.preventDefault();
    const form = this.formRef.current;

    form.validateFields().then((values) => {
      axios.post("/cms/sites", values).then(
        (response) => {
          const site = response.data;

          localStorage.setItem(
            "success-notification",
            "Facility created successfully"
          );

          window.location = `/cms/facilities/${site.id}`;
        },
        (error) => errorMessage(error)
      );
    });
  };

  filterTerritories = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  handleTerritoriesChange = (value) => {
    const oldTerritories = this.state.availableTerritories.map((t) =>
      t.name.toLowerCase()
    );
    const territoryFound = oldTerritories.includes(value.toLowerCase());
    const tempTerritory =
      value && value !== "" && !territoryFound
        ? { key: "temp", name: value }
        : null;
    this.setState({ tempTerritory });
  };

  resetTempTerritory = () => {
    this.setState({ tempTerritory: null });
  };

  render = () => {
    const { availableTerritories, tempTerritory } = this.state;
    const territoryList = tempTerritory
      ? concat(availableTerritories, [tempTerritory])
      : availableTerritories;

    return (
      <Form ref={this.formRef} hideRequiredMark={true} className="ahi-form">
        <FormHeading headingText={"Add New Facility"} />
        <Divider />

        <FormItem
          label={"FACILITY NAME"}
          colon={false}
          name="name"
          rules={[{ required: true, message: "Please input a facility name" }]}
        >
          <Input
            type="text"
            autoComplete="facility name"
            placeholder="Facility Name"
          />
        </FormItem>

        <FormItem
          label={"FACILITY USERNAME"}
          colon={false}
          name="username"
          rules={[
            { required: true, message: "Please input a facility user name" },
          ]}
        >
          <Input
            type="text"
            autoComplete="facility-username"
            placeholder="Facility Username"
          />
        </FormItem>

        <FormItem
          label={"FACILITY PASSWORD"}
          colon={false}
          name="password"
          rules={[{ required: true, message: "Please input a password" }]}
        >
          <Input
            type="password"
            autoComplete="new-password"
            placeholder="Facility Password"
          />
        </FormItem>

        <FormItem
          label={"FACILITY TERRITORY"}
          style={{ width: "100%" }}
          colon={false}
          name="territory"
          rules={[{ required: false }]}
        >
          <Select
            showSearch={true}
            style={{ width: "100%" }}
            optionFilterProp="children"
            placeholder="Enter a Territory Name"
            filterOption={this.filterTerritories}
            onSearch={this.handleTerritoriesChange}
            onChange={this.resetTempTerritory}
          >
            {territoryList
              ? territoryList.map((territory) => (
                  <Option key={territory.name}>{territory.name}</Option>
                ))
              : ""}
          </Select>
        </FormItem>

        <FormItem style={{ width: "100%" }}>
          <span className={""}>Facility will gain access to Pen Rider</span>
        </FormItem>

        <Divider />
        <FormItem className={"ahi-form-bottom-button-wrap"}>
          <Button
            type="default"
            className="form-button"
            onClick={() => (window.location = "/cms/facilities")}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={this.handleSubmit}>
            Save
          </Button>
        </FormItem>
      </Form>
    );
  };
}

export default CreateSiteForm;
