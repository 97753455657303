import React, { Component } from "react";
import {
  CalendarOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  FilterOutlined,
} from "@ant-design/icons";
// Must keep DatePicker and RangePicker imports here because it broke
// DateSelectorModal component everywhere for some reason
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line unused-imports/no-unused-imports
import { Table, DatePicker } from "antd";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { RangePicker } = DatePicker;
import errorMessage from "utils/errorMessage";
import DateSelectorModal from "components/DateSelectorModal/DateSelectorModal";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { SiteService } from "services/SiteService";
import { hasBothDates } from "utils/dateUtils";

class SiteSyncList extends Component {
  state = {
    data: [],
    currentPage: 1,
    sortField: "updated_at",
    sortType: "desc",
    total: 1,
    dateStart: null,
    dateEnd: null,
    syncFilter: null,
    syncFilterOptions: [
      {
        label: "Successful",
        value: "finished_successfully",
      },
      {
        label: "Incomplete",
        value: "incomplete",
      },
    ],
    visible: false,
  };

  componentDidMount = () => {
    this.getTheData();
  };

  getTheData = (page = 1) => {
    SiteService.getSiteSyncList({
      siteId: this.props.site.id,
      page,
      sortBy: this.state.sortField,
      order: this.state.sortType,
      filterBy: this.state.syncFilter ?? undefined,
      dateStart: hasBothDates(this.state.dateStart, this.state.dateEnd)
        ? this.state.dateStart
        : undefined,
      dateEnd: hasBothDates(this.state.dateStart, this.state.dateEnd)
        ? this.state.dateEnd
        : undefined,
    }).then(
      (response) => {
        const data = response.data.data;
        const currentPage = response.data.current_page;
        const total = response.data.total;
        this.setState({ data, currentPage, total });
      },
      (error) => errorMessage(error)
    );
  };

  setSyncFilter = (filter, confirm) => {
    this.setState({ syncFilter: filter }, this.getTheData);
    confirm();
  };

  toggleSortType = () => {
    this.setState(
      { sortType: this.state.sortType === "asc" ? "desc" : "asc" },
      this.getTheData
    );
  };

  showModal = (visible) => this.setState({ visible });

  handleOk = () => {
    this.setState(
      {
        visible: false,
      },
      this.getTheData
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  updateDate = (dateStart, dateEnd) => {
    this.setState(
      {
        dateStart:
          dateStart && dateEnd ? dateStart.format("YYYY-MM-DD") : undefined,
        dateEnd:
          dateEnd && dateStart ? dateEnd.format("YYYY-MM-DD") : undefined,
      },
      this.getTheData
    );
  };

  toggleDateSort = () => {
    if (this.state.sortField === "updated_at") {
      this.toggleSortType();
    } else {
      const sortField = "updated_at";
      const sortType = "asc";
      this.setState({ sortField, sortType }, this.getTheData);
    }
  };

  render = () => {
    const columns = [
      {
        title: (
          <span
            className={`order-sorter ${
              this.state.sortField === "updated_at" ? this.state.sortType : ""
            }`}
          >
            <span>Sync History</span>
            <CalendarOutlined
              className="icon-calendar"
              style={{
                color:
                  this.state.dateStart && this.state.dateEnd
                    ? "#00C9B0"
                    : "inherit",
              }}
              onClick={() => this.showModal(true)}
            />
            <div onClick={this.toggleDateSort} className="icon-container">
              <CaretUpOutlined /> <CaretDownOutlined />
            </div>
          </span>
        ),
        dataIndex: "sync_date",
        className: "history-column",
        render: (text) => <span>{text} CST</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        className: "status-column",
        filterIcon: (
          <FilterOutlined
            style={{ color: this.state.syncFilter ? "#108ee9" : "#aaa" }}
          />
        ),
        filterDropdown: ({ confirm }) => (
          <FilterDropdown
            onSetSelectedFilter={(filter) => {
              this.setSyncFilter(filter, confirm);
            }}
            selectedFilter={this.state.syncFilter}
            options={this.state.syncFilterOptions}
          />
        ),
      },
      {
        title: "Location",
        dataIndex: "failure_location",
        className: "location-column",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        render: (text, row) =>
          row.notes.map((item, index) => <div key={index}>{item}</div>),
      },
    ];

    const pagination = {
      onChange: this.getTheData,
      current: this.state.currentPage,
      total: this.state.total,
      pageSize: 5,
      hideOnSinglePage: true,
      showSizeChanger: false,
    };

    return (
      <div className={"list-page sync-list"}>
        <Table
          columns={columns}
          dataSource={this.state.data}
          className={"custom-table"}
          rowKey={"id"}
          pagination={pagination}
          style={{ paddingBottom: 20 }}
        />
        {this.state.visible && (
          <DateSelectorModal
            submit={this.updateDate}
            modalVisible={this.state.visible}
            showModal={this.showModal}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
          />
        )}
      </div>
    );
  };
}

export default SiteSyncList;
