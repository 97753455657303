import React, { Component } from "react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

class PhotoSlideshow extends Component {
  state = { currentPhoto: 0 };

  updateImage = (index) => {
    this.setState({ currentPhoto: index });
  };

  next = () => {
    this.updateImage(this.state.currentPhoto + 1);
  };

  previous = () => {
    this.updateImage(this.state.currentPhoto - 1);
  };

  goTo = (index) => this.carousel.goTo(index);

  lastPhoto = () =>
    this.props.photos
      ? this.state.currentPhoto === this.props.photos.length - 1
      : true;

  firstPhoto = () => this.state.currentPhoto === 0;

  render = () => {
    const { photos } = this.props;
    const { currentPhoto } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={"display-image-lrg"}>
          <div id="image-wrapper" className={"shadow"}>
            <a
              href={photos[currentPhoto].full}
              target={"_blank"}
              rel="noreferrer"
            >
              <img key={"logo"} src={photos[currentPhoto].large} />
            </a>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {photos && photos.length > 1 && (
            <React.Fragment>
              <Button
                className={"carousel-button"}
                type="primary"
                icon={<LeftOutlined />}
                onClick={this.previous}
                disabled={this.firstPhoto()}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                }}
              >
                {currentPhoto + 1}/{photos.length}
              </div>

              <Button
                className={"carousel-button"}
                type="primary"
                icon={<RightOutlined />}
                onClick={this.next}
                disabled={this.lastPhoto()}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };
}

export default PhotoSlideshow;
