import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const Option = Select.Option;

export default function TerritorySelect(props) {
  return (
    <Select
      showSearch={true}
      optionFilterProp="children"
      placeholder="Enter a Territory Name"
      value={props.currentTerritoryId}
      onChange={props.onChange}
    >
      <Option key={"all_territories"}>All Territories</Option>
      <Option key={"user_territories"}>User Assigned Territories</Option>
      {props.availableTerritories &&
        props.availableTerritories.map((territory) => (
          <Option key={territory.id}>{territory.name}</Option>
        ))}
    </Select>
  );
}

TerritorySelect.propTypes = {
  currentTerritoryId: PropTypes.string,
  onChange: PropTypes.func,
  availableTerritories: PropTypes.array,
};
