import axios from "axios";
import { Feature } from "geojson";

import {
  ApiResponse,
  BaseService,
  PaginatedApiResponse,
} from "services/BaseService";
import { SESSION_ROUTE_PATH, SESSION_DETAILS_PATH } from "services/constants";
import { mockReturnPenRiderRoute } from "__fixtures__/googleMaps";
import { sampleMetadata, sampleSessionDetails } from "__fixtures__/sessions";

export class SessionService extends BaseService {
  public static getSessionCoordinates(
    siteId: number,
    sessionId: string,
    siteAdminId?: number
  ): ApiResponse<Feature> {
    if (BaseService.getUseMock()) {
      return BaseService.mockData({
        data: mockReturnPenRiderRoute,
      });
    } else {
      return axios.get(
        BaseService.buildRequestURL(
          {
            site_id: siteId,
            site_admin_id: siteAdminId,
            session_id: sessionId,
          },
          SESSION_ROUTE_PATH
        )
      );
    }
  }

  public static getSessionDetails({
    siteId,
    sessionId,
    page = 1,
    pageSize = 10,
    siteAdminId,
    sortBy,
    sortOrder,
  }: {
    siteId: number;
    sessionId: string;
    page?: number;
    pageSize?: number;
    siteAdminId?: number;
    sortBy: string;
    sortOrder: string;
  }): PaginatedApiResponse<SessionDetails[]> {
    if (BaseService.getUseMock()) {
      return BaseService.paginatedMockData({
        data: sampleSessionDetails,
        metadata: sampleMetadata,
      });
    } else {
      return axios.get(
        BaseService.buildRequestURL(
          {
            site_id: siteId,
            session_id: sessionId,
            site_admin_id: siteAdminId,
            page: page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_order: sortOrder,
          },
          SESSION_DETAILS_PATH
        )
      );
    }
  }
}
