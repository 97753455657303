import React, { Component, Fragment } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Form, Input, Button, Divider, Switch } from "antd";
import errorMessage from "utils/errorMessage";
import axios from "axios";
import ModuleFeatureSwitch from "components/common/ModuleFeatureSwitch";
import ActiveSelect from "components/common/ActiveSelect";
import findIndex from "lodash/findIndex";
import FormHeading from "components/common/FormHeading";
const FormItem = Form.Item;

class ManageSiteUser extends Component {
  state = {
    activeStatus: this.props.siteUser.status,
    modules: this.props.modules,
    phoneNumber: this.props.userPhone ? this.props.userPhone.phone : "",
  };

  updateState = (data, callback) =>
    this.setState((state) => ({ ...state, ...data }), callback);

  handleSubmit = (values) => {
    const { siteUser } = this.props;
    const { modules } = this.state;
    values.status = parseInt(values.status);
    const data = {
      phone_number: values.phone_number,
      status: values.status,
      send_code: values.send_code,
      modules,
    };
    axios.put("/admin-cms/site-user/" + siteUser.id, data).then(
      () => {
        localStorage.setItem(
          "success-notification",
          siteUser.description + " was edited successfully"
        );
        this.backNavigation();
      },
      (error) => errorMessage(error)
    );
  };

  backNavigation = () => {
    if (document.referrer && window.location.href !== document.referrer) {
      window.location = document.referrer;
    } else {
      const urlParams = window.location.search ? window.location.search : "";
      const newURL = `/admin-cms${urlParams}`;
      window.location.assign(newURL);
    }
  };

  toggleActiveStatusSelected = (activeStatus) =>
    this.updateState({ activeStatus: Number(activeStatus) });

  updateModule = (module) => () => {
    const { modules } = this.state;
    const index = findIndex(
      modules,
      (m) => m.module_slug === module.module_slug
    );
    this.updateState({
      modules: [
        ...modules.slice(0, index),
        { ...module, is_active: module.is_active ? 0 : 1 },
        ...modules.slice(index + 1),
      ],
    });
  };

  updateModuleFeature = (module) => (feature) => () => {
    const { modules } = this.state;
    const moduleIndex = findIndex(
      modules,
      (m) => m.module_slug === module.module_slug
    );
    const featureIndex = findIndex(
      module.features,
      (f) => f.slug === feature.slug
    );
    const newModule = {
      ...module,
      features: [
        ...module.features.slice(0, featureIndex),
        { ...feature, is_active: feature.is_active ? 0 : 1 },
        ...module.features.slice(featureIndex + 1),
      ],
    };
    this.updateState({
      modules: [
        ...modules.slice(0, moduleIndex),
        newModule,
        ...modules.slice(moduleIndex + 1),
      ],
    });
  };

  phoneNumberChange = (e) => this.updateState({ phoneNumber: e.target.value });

  render = () => {
    return (
      <Form
        onFinish={this.handleSubmit}
        className="ahi-form"
        hideRequiredMark={true}
        style={{ marginBottom: 25 }}
        initialValues={{
          phone_number: this.state.phoneNumber,
          status: this.props.siteUser.status,
          module_setting_status: this.state.modules.map((module) =>
            Boolean(module.is_active)
          ),
        }}
      >
        <FormHeading headingText={`Edit ${this.props.siteUser.description}`} />
        <Divider />

        <div className="row">
          <div className="col-xs-6">
            <FormItem
              label={"Mobile Phone"}
              colon={false}
              name="phone_number"
              rules={[
                { required: false, message: "Please input a phone number" },
              ]}
            >
              <Input
                type="phone"
                placeholder="Phone Number"
                onChange={this.phoneNumberChange}
                style={{ width: "70%" }}
              />
            </FormItem>
          </div>
        </div>

        <Divider />

        <div className="row">
          <div className="col-xs-6">
            <FormItem
              label={"Status"}
              colon={false}
              name="status"
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <ActiveSelect
                onChange={this.toggleActiveStatusSelected}
                isActive={Boolean(this.state.activeStatus)}
              />
            </FormItem>
          </div>
        </div>

        {this.state.modules.map((module, index) => (
          <Fragment key={module.id}>
            <div className="row">
              <div className="col-xs-6">
                <FormItem
                  label={module.module_name}
                  colon={false}
                  name={["module_setting_status", index]}
                >
                  <ActiveSelect
                    onChange={this.updateModule(module)}
                    isActive={Boolean(module.is_active)}
                  />
                </FormItem>
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-6">
                {module.features &&
                  module.features.map((feature) => (
                    <FormItem key={feature.id}>
                      <ModuleFeatureSwitch
                        feature={feature}
                        updateModuleFeature={this.updateModuleFeature(module)}
                      />
                    </FormItem>
                  ))}
              </div>
            </div>
          </Fragment>
        ))}

        {this.state.activeStatus === 1 && (
          <div className="row">
            <div className="col-xs-6">
              <FormItem
                label="Send In-App Code"
                name="send_code"
                valuePropName="checked"
              >
                <Switch
                  disabled={this.state.phoneNumber.length <= 0}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </FormItem>
            </div>
          </div>
        )}

        <Divider />

        <FormItem className={"ahi-form-bottom-button-wrap"}>
          <Button className="form-button" onClick={this.backNavigation}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </FormItem>
      </Form>
    );
  };
}

export default ManageSiteUser;
