import { Component } from "react";
import { notification } from "antd";

class NotificationHandler extends Component {
  componentDidMount = () => {
    const successMessage = localStorage.getItem("success-notification");
    if (successMessage) {
      notification["success"]({
        message: "Success",
        description: successMessage,
      });

      localStorage.clear();
    }
  };

  render() {
    return null;
  }
}

export default NotificationHandler;
