import React, { Component } from "react";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Button, Table } from "antd";

import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import ModuleTypeSelect from "components/common/ModuleTypeSelect";
import TerritorySelect from "components/common/TerritorySelect";
import { SiteService } from "services/SiteService";
import eventEmitter from "utils/eventEmitter";
import errorMessage from "utils/errorMessage";

class SitesList extends Component {
  constructor(props) {
    super(props);
    this.ee = eventEmitter.EventEmitter;
  }

  state = {
    data: [],
    currentPage: 1,
    currentTerritoryId: !this.props.userTerritories
      ? "all_territories"
      : "user_territories",
    searchTerm: "",
    sortField: "name",
    sortType: "asc",
    total: 1,
    syncFilter: "",
    syncFilterOptions: [
      {
        label: "Successful",
        value: "finished_successfully",
      },
      {
        label: "Failed",
        value: "failed",
      },
    ],
    moduleTypeFilters: [...this.props.moduleTypes.map((x) => x.id)],
    loading: true,
  };

  componentDidMount = () => {
    this.getTheData();

    this.ee.on("event", () => {
      const searchTerm = window.SearchTerm;

      this.setState({ searchTerm }, this.getTheData);
    });
  };

  changeFilterTerritory = (currentTerritoryId) => {
    this.setState({ currentTerritoryId }, this.getTheData);
  };

  getTheData = (page = 1) => {
    const {
      currentTerritoryId,
      moduleTypeFilters,
      searchTerm,
      sortField,
      sortType,
      syncFilter,
    } = this.state;
    const territoryId =
      this.state.currentTerritoryId !== "all_territories" &&
      this.state.currentTerritoryId !== "user_territories"
        ? this.state.currentTerritoryId
        : "";
    const userTerritory = currentTerritoryId === "user_territories" ? "1" : "0";

    this.setState({ loading: true }, () => {
      SiteService.getSiteList({
        page,
        moduleTypes: moduleTypeFilters.join(","),
        name: searchTerm,
        order: sortType,
        sortBy: sortField,
        syncStatus: syncFilter,
        territory: territoryId,
        userTerritory,
      }).then(
        (response) => {
          const data = response.data.data;
          const currentPage = response.data.current_page;
          const total = response.data.total;
          this.setState({ data, currentPage, total, loading: false });
        },
        (error) => {
          this.setState({ loading: false }, () => errorMessage(error));
        }
      );
    });
  };

  toggleSortType = () => {
    const sortType = this.state.sortType === "asc" ? "desc" : "asc";
    this.setState({ sortType }, this.getTheData);
  };

  toggleNameSort = () => {
    if (this.state.sortField === "name") {
      this.toggleSortType();
    } else {
      const sortField = "name";
      const sortType = "asc";
      this.setState({ sortField, sortType }, this.getTheData);
    }
  };

  toggleStatusSort = () => {
    if (this.state.sortField === "status") {
      this.toggleSortType();
    } else {
      const sortField = "status";
      const sortType = "asc";
      this.setState({ sortField, sortType }, this.getTheData);
    }
  };

  setSyncFilter = (syncFilter, confirm) => {
    this.setState({ syncFilter }, this.getTheData);
    confirm();
  };

  navigateToSiteDetails = (siteId) => {
    window.location = `facilities/${siteId}`;
  };

  handleModuleTypeChange = (moduleTypeFilters) => {
    this.setState({ moduleTypeFilters }, this.getTheData);
  };

  render = () => {
    const columns = [
      {
        title: (
          <span
            className={`order-sorter ${
              this.state.sortField === "name" ? this.state.sortType : ""
            }`}
            onClick={this.toggleNameSort}
          >
            Name{" "}
            <div className="icon-container">
              <CaretUpOutlined /> <CaretDownOutlined />
            </div>
          </span>
        ),
        dataIndex: "name",
      },
      {
        title: "Facility Manager",
        dataIndex: "manager",
      },
      {
        title: "Sync Status",
        dataIndex: "last_sync_status",
        filterIcon: (
          <FilterOutlined
            style={{ color: this.state.syncFilter !== "" ? "#108ee9" : "#aaa" }}
          />
        ),
        filterDropdown: ({ confirm }) => (
          <FilterDropdown
            onSetSelectedFilter={(filter) => {
              this.setSyncFilter(filter, confirm);
            }}
            selectedFilter={this.state.syncFilter}
            options={this.state.syncFilterOptions}
          />
        ),
      },
      {
        title: "Last Sync",
        dataIndex: "last_sync_date",
        render: (text) => text && text.length > 0 && <span>{text} CST</span>,
      },
      {
        title: (
          <span
            className={`order-sorter ${
              this.state.sortField === "status" ? this.state.sortType : ""
            }`}
            onClick={this.toggleStatusSort}
          >
            Active Status{" "}
            <div className="icon-container">
              <CaretUpOutlined />
              <CaretDownOutlined />
            </div>
          </span>
        ),
        dataIndex: "is_active",
      },
    ];

    const pagination = {
      onChange: this.getTheData,
      current: this.state.currentPage,
      total: this.state.total,
      pageSize: 12,
      hideOnSinglePage: true,
      showSizeChanger: false,
    };

    return (
      <div className={"list-page"}>
        <div className={"row list-page-top-bar"}>
          <div style={{ width: "80%" }}>
            <div style={{ width: "40%", display: "inline-block" }}>
              <span className="font-bold filter-label">Select Territory:</span>
              <TerritorySelect
                currentTerritoryId={this.state.currentTerritoryId}
                onChange={this.changeFilterTerritory}
                availableTerritories={this.props.availableTerritories}
              />
            </div>

            <div style={{ width: "40%", display: "inline-block" }}>
              <span className="font-bold filter-label">Active Modules:</span>
              <ModuleTypeSelect
                moduleTypeFilters={this.state.moduleTypeFilters}
                onChange={this.handleModuleTypeChange}
                moduleTypes={this.props.moduleTypes}
              />
            </div>
          </div>

          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button type="primary" className={"small-button next-to-top-nav"}>
              <a href="/cms/facilities/create">Add New Facility</a>
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          className={"facility-list custom-table"}
          dataSource={this.state.data}
          rowKey={"id"}
          pagination={pagination}
          onRow={(record) => ({
            onClick: () => {
              this.navigateToSiteDetails(record.id);
            },
          })}
          loading={this.state.loading}
        />
      </div>
    );
  };
}

export default SitesList;
