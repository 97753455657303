import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Wrapper as MapWrapper } from "@googlemaps/react-wrapper";
import { useSessionDetails } from "contexts/SessionDetailsContext";
import { Feature, Point } from "geojson";
import { useSitePens } from "contexts/SitePensContext";
import { SessionService } from "services/SessionService";
import { useSessionList } from "contexts/SessionListContext";
import { useSite } from "contexts/SiteDetailsContext";
import errorMessage from "utils/errorMessage";

/*global googleApiKey */
const BUTTON_ID = "google-maps-reset-zoom-button";

const DEFAULT_ZOOM_FOR_PENS = 16;

const penStyle = {
  strokeColor: "green",
  fillOpacity: 0,
  strokeOpacity: 1,
  strokeWeight: 3,
};

const penCenterpointStyle = (displayLabel: string) => ({
  icon: {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 0,
  },
  label: {
    fontWeight: "900",
    fontSize: "16px",
    text: displayLabel,
    color: "white",
    className: "text-with-black-border",
  },
});

const sessionRouteStyle = {
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
};

const MapPanel = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [centerpoints, setCenterpoints] = useState<Feature[]>();
  const [sessionRoute, setSessionRoute] = useState<Feature>();
  const [geoJsonFeatureCollection, setGeoJsonFeatureCollection] =
    useState<GeoJSON.GeoJSON>();
  const { selectedPen, setSelectedPen, currentPens } = useSessionDetails();
  const { pens } = useSitePens();
  const { currentSession } = useSessionList();
  const { site, siteAdminId } = useSite();

  const createResetZoomButton = useCallback((): HTMLElement => {
    const controlButton = document.createElement("button");
    controlButton.id = BUTTON_ID;
    controlButton.textContent = "Reset Zoom";

    controlButton.addEventListener("click", () => {
      setSelectedPen(undefined);
    });

    return controlButton;
  }, [setSelectedPen]);

  const zoomInToSelectedPen = useCallback(() => {
    if (map && selectedPen) {
      // zoom into specific pen when clicked on in table
      map.setZoom(DEFAULT_ZOOM_FOR_PENS);
      map.data.forEach((feature) => {
        const penId = feature.getProperty("pen_id");
        const type = feature.getGeometry().getType();

        if (type === "Point" && penId === selectedPen) {
          feature.getGeometry().forEachLatLng((latLong) => {
            map.panTo(latLong);
          });
        }
      });
    }
  }, [map, selectedPen]);

  // initialize map
  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(
        new window.google.maps.Map(mapRef.current, {
          mapTypeId: "satellite",
          tilt: 0,
        })
      );
    }
  }, [map, mapRef]);

  // api call will be made here to get pen-rider route
  useEffect(() => {
    if (currentSession?.sessionID && site?.id) {
      SessionService.getSessionCoordinates(
        site.id,
        currentSession.sessionID,
        siteAdminId
      ).then((response) => {
        setSessionRoute(response.data.data);
      }, errorMessage);
    }
  }, [currentSession?.sessionID, site?.id, siteAdminId]);

  // Add data to map feature collection for pen data and session route
  useEffect(() => {
    if (pens && sessionRoute) {
      const centerpointArray: Feature[] = pens.map(
        (penInfo) => penInfo.centerpoint
      );
      const penCoordinatesArray: Feature[] = pens.map(
        (penInfo) => penInfo.coordinates
      );

      setCenterpoints(centerpointArray);

      setGeoJsonFeatureCollection({
        type: "FeatureCollection",
        features: [...centerpointArray, ...penCoordinatesArray, sessionRoute],
      });
    }
  }, [pens, sessionRoute]);

  // load and style pens/pen rider routes onto google map
  useEffect(() => {
    // load pens/pen ride routes via geoJson after map is initialized
    if (mapRef.current && map) {
      map.data?.forEach((feature) => {
        map.data.remove(feature);
      });
      map.data?.addGeoJson(geoJsonFeatureCollection);
    }

    // style pens/pen rider routes
    map?.data.setStyle((feature) => {
      const penNumber = feature.getProperty("pen_id");
      const displayLabel = feature.getProperty("display_label");
      const type = feature.getGeometry().getType();
      if (type === "Polygon") {
        return penStyle;
      } else if (type === "Point" && !!penNumber) {
        return penCenterpointStyle(displayLabel);
      } else if (type === "LineString") {
        return sessionRouteStyle;
      }
    });
  }, [geoJsonFeatureCollection, map]);

  // render button to reset zoom or remove button on pen deselection
  useEffect(() => {
    if (map) {
      if (selectedPen) {
        zoomInToSelectedPen();

        if (
          map.controls[
            google.maps.ControlPosition.BOTTOM_CENTER
          ].getLength() === 0
        ) {
          map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
            createResetZoomButton()
          );
        }
      } else {
        map.controls[google.maps.ControlPosition.BOTTOM_CENTER].pop();
      }
    }
  }, [map, selectedPen, createResetZoomButton, zoomInToSelectedPen]);

  // Set to default zoom for facility based on pen centerpoints
  useEffect(() => {
    if (centerpoints && !selectedPen) {
      const bounds = new window.google.maps.LatLngBounds();

      centerpoints
        .filter(
          (centerpoint) =>
            currentPens.indexOf(centerpoint.properties?.pen_id) !== -1
        )
        .forEach((point) => {
          const pointGeometry = point.geometry as Point;
          const lat = pointGeometry.coordinates[1] as number;
          const long = pointGeometry.coordinates[0] as number;
          const myLatlng = new google.maps.LatLng(lat, long);

          bounds.extend(myLatlng);
        });
      map?.fitBounds(bounds);
    }
  }, [centerpoints, currentPens, map, selectedPen]);

  return (
    <div ref={mapRef} id="map" style={{ width: "100%", minHeight: "284px" }} />
  );
};

function GoogleMap(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Global defined in PHP blade template.
  const _googleApiKey = googleApiKey as string | undefined;

  return (
    <MapWrapper apiKey={_googleApiKey}>
      <MapPanel />
    </MapWrapper>
  );
}

export default GoogleMap;
