import LabeledStat from "components/LabeledStat";
import React, { ReactElement } from "react";

const SessionCarouselCard = ({
  session,
}: {
  session: SessionListElement;
}): ReactElement => {
  return (
    <div className="session-carousel-details">
      <div className="space-between-stats">
        <LabeledStat
          value={session?.site_user_name}
          label="User"
          containerStyle={{
            maxWidth: "144px",
          }}
        />
        <LabeledStat
          value={session?.date}
          label="Date"
          containerStyle={{
            maxWidth: "72px",
          }}
        />
        <LabeledStat
          value={session?.total_time}
          label="Total Time"
          containerStyle={{
            maxWidth: "72px",
          }}
        />
      </div>
    </div>
  );
};

export default SessionCarouselCard;
